import React, { useState } from "react";
import "./Footer.Style.css";
import { useDispatch } from "react-redux";
import { toastError } from "../../redux/ActionCreator/Toast.ActionCreator";
import { createFeedback } from "../../redux/ActionCreator/Feedback.ActionCreator";

function FooterComponent(props) {
    const [likes, setLikes] = useState("");
    const [dislikes, setDislikes] = useState("");
    const [suggetions, setSuggetions] = useState("");

    const dispatch = useDispatch();

    const sendFeedback = () => {
        if (likes.length === 0 && dislikes.length === 0 && suggetions.length === 0) {
            const payload = {
                type: "Error",
                message: "Fields should not be empty",
            };
            dispatch(toastError(payload));
        } else {
            const payload = {
                likes: likes,
                dislikes: dislikes,
                suggetions: suggetions,
            };
            dispatch(createFeedback(payload));
            setDislikes("");
            setLikes("");
            setSuggetions("");
        }
    };

    return (
        <div className="FooterComponent">
            <div className="footer-information">
                <div className="footer-title">EchoVault</div>
                <div className="footer-message">We're working towards improving your daily lives through innovation, hence your feedback is essential for us.</div>
                <div className="footer-copyright">
                    <p className="footer-policy-links"><a href={process.env.REACT_APP_BASE_URL+'privacy-policy'}>Privacy Policy</a></p>
                    <p className="footer-policy-links"><a href={process.env.REACT_APP_BASE_URL+'terms-condition'}>Terms of Service</a></p>
                    <p className="footer-policy-links">Copyright © 2023 EchoVault</p>
                </div>
            </div>
            <div className="footer-form">
                <div className="feedback-form">
                    <div className="form-group">
                        <label htmlFor="likes" className="field-label">
                            Likes
                        </label>
                        <textarea id="improvement" name="improvement" className="form-input" placeholder="What you liked..." value={likes} onChange={(e) => setLikes(e.target.value)}></textarea>
                    </div>
                    <div className="form-group">
                        <label htmlFor="dislike" className="field-label">
                            Dislike
                        </label>
                        <textarea id="dislike" name="dislike" className="form-input" placeholder="What you didn't like..." value={dislikes} onChange={(e) => setDislikes(e.target.value)}></textarea>
                    </div>
                    <div className="form-group">
                        <label htmlFor="newFeature" className="field-label">
                            New Feature
                        </label>
                        <textarea
                            id="newFeature"
                            name="newFeature"
                            className="form-input"
                            placeholder="Suggest a new feature..."
                            value={suggetions}
                            onChange={(e) => setSuggetions(e.target.value)}
                        ></textarea>
                    </div>
                    <button className="form-submit-button" onClick={sendFeedback}>
                        Send Feedback
                    </button>
                    <div className="footer-copyright-form-bottom">
                        <p className="footer-policy-links"><a href={process.env.REACT_APP_BASE_URL+'privacy-policy'}>Privacy Policy</a></p>
                        <p className="footer-policy-links"><a href={process.env.REACT_APP_BASE_URL+'terms-condition'}>Terms of Service</a></p>
                        <p className="footer-policy-links">Copyright © 2023 EchoVault</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FooterComponent;

import React, { useEffect, useRef } from "react";
import "./AudioAnimation.Style.css";

function AudioAnimationComponent(props) {
    const canvasRef = useRef();
    let canvasContext; // Change from const to let
    const canvasWidth = 800;
    const canvasHeight = 200;
    const gradientWidth = 1;
    const animationSpeed = 1;
    const gradientColor = "#4e657a";

    useEffect(() => {
        const canvas = canvasRef.current;
        canvasContext = canvas.getContext("2d");
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;

        canvasContext.clearRect(0, 0, canvasWidth, canvasHeight);

        let volume = getVolume();

        let gradientX = canvasWidth;
        const gradientHeight = (volume / 128) * canvasHeight;

        const y = (canvasHeight - gradientHeight) / 2;

        canvasContext.fillStyle = gradientColor;
        canvasContext.fillRect(gradientX, y, gradientWidth, gradientHeight);

        const animationFrame = requestAnimationFrame(animate);

        function animate() {
            canvasContext.clearRect(gradientX, y, gradientWidth, gradientHeight);
            gradientX -= animationSpeed;

            canvasContext.fillRect(gradientX, y, gradientWidth, gradientHeight);

            if (gradientX + gradientWidth > 0) {
                requestAnimationFrame(animate);
            }
        }

        return () => {
            cancelAnimationFrame(animationFrame);
        };
    }, [props.audioData]);

    function getVolume() {
        let normSamples = props.audioData.map((points) => points / 4);
        let volumeData = [...props.levelData];
        let sum = 0;
        for (let i = 0; i < props.audioData.length; i++) {
            sum += normSamples[i] * normSamples[i];
        }
        let volume = Math.sqrt(sum / normSamples.length);
        volume -= 10;
        if (volume < 0) volume = 0;
        const volumeMappings = [
            { max: 1, value: 1 },
            { max: 2, value: 4 },
            { max: 3, value: 5 },
            { max: 4, value: 7 },
            { max: 5, value: 4 },
            { max: 6, value: 3 },
            { max: 7, value: 2 },
            { max: 8, value: 2 },
            { max: 9, value: 6 },
            { max: 10, value: 8 },

            { max: 11, value: 3 },
            { max: 12, value: 5 },
            { max: 13, value: 8 },
            { max: 14, value: 7 },
            { max: 15, value: 10 },
            { max: 16, value: 5 },
            { max: 17, value: 4 },
            { max: 18, value: 3 },
            { max: 19, value: 2 },
            { max: 20, value: 13 },

            { max: 21, value: 7 },
            { max: 22, value: 17 },
            { max: 23, value: 7 },
            { max: 24, value: 15 },
            { max: 25, value: 21 },
            { max: 26, value: 27 },
            { max: 27, value: 17 },
            { max: 28, value: 14 },
            { max: 29, value: 21 },
            { max: 30, value: 12 },

            { max: 31, value: 20 },
            { max: 32, value: 30 },
            { max: 33, value: 35 },
            { max: 34, value: 40 },
            { max: 35, value: 25 },
            { max: 36, value: 30 },
            { max: 37, value: 15 },
            { max: 38, value: 15 },
            { max: 39, value: 18 },
            { max: 40, value: 30 },

            { max: 41, value: 20 },
            { max: 42, value: 25 },
            { max: 43, value: 30 },
            { max: 44, value: 45 },
            { max: 45, value: 20 },
            { max: 46, value: 15 },
            { max: 47, value: 30 },
            { max: 48, value: 20 },
            { max: 49, value: 10 },
            { max: 50, value: 8 },

            { max: 51, value: 10 },
            { max: 52, value: 20 },
            { max: 53, value: 10 },
            { max: 54, value: 30 },
            { max: 55, value: 10 },
            { max: 56, value: 45 },
            { max: 57, value: 55 },
            { max: 58, value: 60 },
            { max: 59, value: 20 },
            { max: 60, value: 10 },

            { max: 61, value: 50 },
            { max: 62, value: 60 },
            { max: 63, value: 40 },
            { max: Infinity, value: 60 },
        ];

        let volumeFiltered = 1;
        for (const mapping of volumeMappings) {
            if (volume <= mapping.max) {
                volumeFiltered = mapping.value === 1 ? 1 : mapping.value * 3;
                break;
            }
        }

        volumeData.push(volumeFiltered);
        props.setLevelData(volumeData);
        return volumeFiltered;
    }

    return <canvas className="recording-canvas" ref={canvasRef}></canvas>;
}

export default AudioAnimationComponent;

import React, { useEffect, useRef, useState } from "react";
import "./LoggedInHeader.Style.css";
import Recorder from "../Records/Recorder.Component";
import CreateFolderComponent from "../Folders/CreateFolder.Component";
import { FaSearch, FaBars } from "react-icons/fa";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addFolders } from "../../redux/ActionCreator/Folders.ActionCreator";
import { requestInfo } from "../../redux/ActionCreator/Auth.ActionCreator";
import { INFO_PATH } from "../../constants/ApiPaths";
import { toastError } from "../../redux/ActionCreator/Toast.ActionCreator";
import { Subscription_Feature_Switch } from "../../constants/FeatureSwitch";

function LoggedInHeaderComponent(props) {
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [search, setSearch] = useState("");
    const [prevSearch, setPrevSearch] = useState("");
    const [isMobile, setIsMobile] = useState(false);
    const token = useSelector((state) => state.Auth.token);
    const Info = useSelector((state) => state.Auth.Info);
    const folders = useSelector((state) => state.Folders.folders);
    const subscriptionType = useSelector((state) => state.Subscription.subscriptionType);
    const currentLocation = useLocation();

    const dispatch = useDispatch();

    const [showRecorder, setShowRecorder] = useState(false);
    const [showFolder, setShowFolder] = useState(false);

    const navigate = useNavigate();

    const dropdownRef = useRef(null);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownVisible(false);
        }
    };

    const handleClickSearch = (event) => {
        if (search === "" || search === prevSearch) {
            return;
        }
        setPrevSearch(search);
        navigate(`/record/search/${search}`);
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [isDropdownVisible]);

    const checkToken = () => {
        if (token === null) {
            return navigate("/auth");
        }
    };

    useEffect(() => {
        checkToken();
    }, [token]);

    useEffect(() => {
        if (!Info.name || Info.name === "0") {
            fetch(process.env.REACT_APP_API_BASE_URL + INFO_PATH, { credentials: "include" })
                .then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        
                    }
                })
                .catch((error) => {});
        }

        if (!token) return;

        checkToken();

        if (folders && folders.length) return;

        dispatch(addFolders());
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1000);
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const toggleShowRecorder = () => {
        if(!Subscription_Feature_Switch &&
            Info.totalrecords && Info.totalrecords >= 25)
        {
            const payload = {
                type: "Error",
                message: "Each individual is limited to a maximum of 25 records.",
            };
            dispatch(toastError(payload));
        }
        else if(Subscription_Feature_Switch && 
        subscriptionType === process.env.REACT_APP_BASIC_SUBSCRIPTION && 
        Info.totalrecords && Info.totalrecords >=50)
        {
            const payload = {
                type: "Error",
                message: "Reached to basic plan limit",
            };
            dispatch(toastError(payload));
            return navigate('/pricing');
        }
        else
        setShowRecorder(!showRecorder);
    }

    useEffect(() => {
        if (!Info.name || Info.name === "0") {
            dispatch(requestInfo());
        }
    }, []);

    return (
        <div className="LoggedInHeaderComponent">
            <div className={`header-row ${props.isRecording ? "blur-background" : ""}`}>
                <div className="header-search-bar">
                    {isMobile && (
                        <button className="sidebar-toggle-button" onClick = {props.toggleSidebar}>
                            <FaBars />
                        </button>
                    )}
                    <div className="search-bar">
                        <FaSearch
                            alt="Search Icon"
                            className="search-icon"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleClickSearch();
                            }}
                        />
                        <input type="text" placeholder="Search by record name" className="search-field" value={search} onChange={(e) => setSearch(e.target.value)} />
                    </div>
                </div>
                <div className="header-record-buttons">
                    <button className="new-folder-button" onClick={() => setShowFolder(true)}>
                        Create Folder
                    </button>
                    <button className="new-record-button" onClick={() => toggleShowRecorder()}>
                        Create Record
                    </button>
                </div>
                {showRecorder && <Recorder setShowRecorder={setShowRecorder} />}
                {showFolder && <CreateFolderComponent setShowFolder={setShowFolder} type={"create"} />}
            </div>
            <div className="header-categories">
                <div
                    className={`header-category`}
                    onClick={() => {
                        setPrevSearch("");
                    }}
                >
                    <div className={`category-link-container ${currentLocation.pathname === "/record/common" ? "selected" : ""}`}>
                        <Link to="/record/common" className={`category-link  ${currentLocation.pathname === "/record/common" ? "catagory-selected" : ""}`}>
                            Common Records
                        </Link>
                    </div>
                </div>
                <div className={`header-category`}>
                    <div className={`category-link-container ${currentLocation.pathname === "/record/favourite" ? "selected" : ""}`}>
                        <Link to="/record/favourite" className={`category-link ${currentLocation.pathname === "/record/favourite" ? "catagory-selected" : ""}`}>
                            Favourite
                        </Link>
                    </div>
                </div>
                <div className={`header-category`}>
                    <div className={`category-link-container ${currentLocation.pathname === "/record/folder" ? "selected" : ""}`}>
                        <Link to="/record/folder" className={`category-link ${currentLocation.pathname === "/record/folder" ? "catagory-selected" : ""}`}>
                            Folders
                        </Link>
                    </div>
                </div>
            </div>
            <div className = "header-horizontal-row"></div>
        </div>
    );
}

export default LoggedInHeaderComponent;

import * as ActionTypes from "../../constants/ActionTypes";

export const sharedLoading = (data) => ({
    type: ActionTypes.SHARE_PERMISSION_DETAIL_LOADING,
    data,
});

export const sharedSuccess = (record) => ({
    type: ActionTypes.SHARE_PERMISSION_DETAIL_SUCCESS,
    record,
});

export const sharedFailed = (message) => ({
    type: ActionTypes.SHARE_PERMISSION_DETAIL_FAILURE,
    message,
});

export const sharedlistLoading = (data) => ({
    type: ActionTypes.SHARE_PEOPLE_LIST_LOADING,
    data,
});

export const sharedlistSuccess = (user) => ({
    type: ActionTypes.SHARE_PEOPLE_LIST_SUCCESS,
    user,
});

export const sharedlistFailed = (message) => ({
    type: ActionTypes.SHARE_PEOPLE_LIST_FAILURE,
    message,
});

export const createPermission = (payload) => ({
    type: ActionTypes.SHARE_PERMISSION_LOADING,
    payload,
});

export const permissionSuccess = (message) => ({
    type: ActionTypes.SHARE_PERMISSION_SUCCESS,
    message,
});

export const createPermissionFailure = (message) => ({
    type: ActionTypes.SHARE_PERMISSION_FAILURE,
    message,
});

export const deletePermission = (payload) => ({
    type: ActionTypes.REMOVE_PERMISSION_LOADING,
    payload,
});

export const deletePermissionSuccess = (payload) => ({
    type: ActionTypes.REMOVE_PERMISSION_SUCCESS,
    payload,
});

export const deletePermissionFailure = (message) => ({
    type: ActionTypes.REMOVE_PERMISSION_FAILURE,
    message,
});

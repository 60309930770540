import { call, put, takeEvery } from "redux-saga/effects";
import * as ActionTypes from "../../constants/ActionTypes";
import { get, deleteApi, post } from "../../api/AxiosConfig";
import { CREATE_SHARE_PERMISSION_PATH, 
    REMOVE_SHARE_PERMISSION_PATH, 
    SHARED_PEOPLE_LIST_PATH, 
    SHARED_RECORDS_DETAIL_PATH 
} from "../../constants/ApiPaths";

const msg = "Your session timed out";

function* getRecord(action) {
    try {
        const response = yield call(get, SHARED_RECORDS_DETAIL_PATH + action.data.id);
        const data = response.data;
        if (response.status === 200) {
            const record = data.Info;
            yield put({ type: ActionTypes.SHARE_PERMISSION_DETAIL_SUCCESS, payload: record });
        } else if (response.status === 401) {
            yield put({ type: ActionTypes.LOGOUT_SUCCESS });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: msg } });
        } else {
            yield put({ type: ActionTypes.SHARE_PERMISSION_DETAIL_FAILURE, data });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: data.message } });
        }
    } catch (error) {
        yield put({ type: ActionTypes.SHARE_PERMISSION_DETAIL_FAILURE, payload: { error } });
        yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: "Failed to load record" } });
    }
}

export function* recordRequest() {
    yield takeEvery(ActionTypes.SHARE_PERMISSION_DETAIL_LOADING, getRecord);
}

function* getList(action) {
    try {
        const response = yield call(get, SHARED_PEOPLE_LIST_PATH + action.data.id);
        const data = response.data;
        if (response.status === 200) {
            const list = data.Info;
            yield put({ type: ActionTypes.SHARE_PEOPLE_LIST_SUCCESS, payload: list });
        } else if (response.status === 401) {
            yield put({ type: ActionTypes.LOGOUT_SUCCESS });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: msg } });
        } else {
            yield put({ type: ActionTypes.SHARE_PEOPLE_LIST_FAILURE, data });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: data.message } });
        }
    } catch (error) {
        yield put({ type: ActionTypes.SHARE_PEOPLE_LIST_FAILURE, payload: { error } });
        yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: "Failed to load record" } });
    }
}

export function* listRequest() {
    yield takeEvery(ActionTypes.SHARE_PEOPLE_LIST_LOADING, getList);
}

function* createPermission(action) {
    try {
        const response = yield call(post, CREATE_SHARE_PERMISSION_PATH, null, action.payload);
        const data = response.data;
        if (response.status === 200) {
            const payload = data.Info;
            yield put({ type: ActionTypes.SHARE_PERMISSION_SUCCESS, payload });
            yield put({ type: ActionTypes.TOAST_SUCCESS, payload: { type: "Success", message: data.status } });
            return { success: true };
        } else if (response.status === 401) {
            yield put({ type: ActionTypes.LOGOUT_SUCCESS });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: msg } });
        } else {
            yield put({ type: ActionTypes.SHARE_PERMISSION_FAILURE, data });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: data.status } });
            return { success: false };
        }
    } catch (error) {
        yield put({ type: ActionTypes.SHARE_PERMISSION_FAILURE, payload: { error } });
        yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: "Failed to share record" } });
        return { success: false };
    }
}

export function* createPermissionRequest() {
    yield takeEvery(ActionTypes.SHARE_PERMISSION_LOADING, createPermission);
}

function* deletePermission(action) {
    try {
        const headers = {
            "Content-Type": "application/json",
        };
        const response = yield call(deleteApi, REMOVE_SHARE_PERMISSION_PATH, null, action.payload, headers);
        const data = response.data;
        if (response.status === 200) {
            const status = data.status;
            const payload = action.payload;
            yield put({ type: ActionTypes.REMOVE_PERMISSION_SUCCESS, payload });
            yield put({ type: ActionTypes.TOAST_SUCCESS, payload: { type: "Success", message: status } });
        } else if (response.status === 401) {
            yield put({ type: ActionTypes.LOGOUT_SUCCESS });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: msg } });
        } else {
            yield put({ type: ActionTypes.REMOVE_PERMISSION_FAILURE, data });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: data.status } });
        }
    } catch (error) {
        yield put({ type: ActionTypes.REMOVE_PERMISSION_FAILURE, payload: { error } });
        yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: "Failed to remove permission" } });
        return { success: false };
    }
}

export function* deletePermissionRequest() {
    yield takeEvery(ActionTypes.REMOVE_PERMISSION_LOADING, deletePermission);
}

import React, { useCallback, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import { HelmetProvider } from 'react-helmet-async';
import "./App.css";
import store from "./redux/ConfigureStore";
import HomePageBody from "./components/HomePageBody.Component";
import LoggedInRoutes from "./components/MainPage/LoggedIn.Routes";
import ProfileComponent from "./components/Profile/Profile.Component";
import Auth from "./components/Auth/Auth.Component";
import { Toaster } from "react-hot-toast";
import toast from "react-hot-toast";
import RecordShareComponent from "./components/RecordCard/RecordShare.Component";
import PricingPlans from "./components/Subscription/Planes.Component";
import TermsOfServicesComponent from "./components/Policies/TermsOfService.Component";
import PrivacyPolicyComponent from "./components/Policies/PrivacyPolicy.Component";
import { Subscription_Feature_Switch } from "./constants/FeatureSwitch";
import SidebarComponent from "./components/Sidebar/Sidebar.Component";
import ContactComponent from "./components/Contact/Contact.Component";

function HandleToast() {
    const type = useSelector((state) => state.Toast.type);
    const message = useSelector((state) => state.Toast.message);
    const update = useSelector((state) => state.Toast.update);

    useEffect(() => {
        if (type === "Success" && update !== null) {
            toast.success(message);
        } else if (update !== null) {
            toast.error(message);
        }
    }, [update]);

    return <div></div>;
}

function SidebarVisible({callBackSideBarVisibility}) {
    const currentLocation = useLocation();
    const locations = ['/record','/profile'];

    const canShowSideBar = (location) => {
        for(let i = 0; i<locations.length; i++)
        {
            if(location.startsWith(locations[i]))
            {
                callBackSideBarVisibility(true);
                return;
            }
        }
        callBackSideBarVisibility(false);
    };

    useEffect(()=>{
        canShowSideBar(currentLocation.pathname);
    },[currentLocation.pathname]);

    return <></>;
}

function App() {
    const [sideBarVisible, setSideBarVisible] = useState(false);
    const [sideBarOpen, setSideBarOpen] = useState(true);
    const [isMobile, setIsMobile] = useState(false);
    const helmetContext = {};
    
    const callBackSideBarVisibility = useCallback((visible) => {
        setSideBarVisible(visible);
    },[sideBarVisible]);

    useEffect(()=>{
        if(!isMobile)
        setSideBarOpen(true);
        else
        setSideBarOpen(false);
    },[isMobile]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1000);
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <HelmetProvider context = {helmetContext}>
            <Provider store={store}>
                <HandleToast />
                <BrowserRouter className="app-echovault">
                    <SidebarVisible callBackSideBarVisibility = {(visible)=>{callBackSideBarVisibility(visible);}}/>
                    {sideBarVisible &&
                        <div className={`sidebar ${sideBarOpen ? '' : 'sidebar-close'}`}>
                            <SidebarComponent showCloseIcon = {isMobile} toggleSidebar = {()=>{setSideBarOpen(!sideBarOpen)}}/>
                        </div>
                    }
                    <div className={`content ${sideBarVisible?'':'no-sidebar'} ${isMobile?'sidebar-mobile-over':''}`}>
                        <Routes>
                            <Route exact path="/" element={<HomePageBody />} />
                            <Route exact path="/auth" element={<Auth signIn={true} />} />
                            <Route exact path="/record/*" element={<LoggedInRoutes toggleSidebar = {()=>{setSideBarOpen(!sideBarOpen)}}/>} />
                            <Route exact path="/share/:recordId" element={<RecordShareComponent />}/>
                            <Route exact path="/profile" element={<ProfileComponent toggleSidebar = {()=>{setSideBarOpen(!sideBarOpen)}}/>} />
                            {Subscription_Feature_Switch && <Route exact path="/pricing" element={<PricingPlans />} />}
                            <Route exact path="/terms-condition" element={<TermsOfServicesComponent />} />
                            <Route exact path="/privacy-policy" element={<PrivacyPolicyComponent />} />
                            <Route exact path="/contact" element={<ContactComponent />} />
                        </Routes>
                        <Toaster
                            position="bottom-center"
                            reverseOrder={true}
                            gutter={8}
                            toastOptions={{
                                duration: 3500,
                            }}
                        />
                    </div>
                </BrowserRouter>
            </Provider>
        </HelmetProvider>
    );
}

export default App;

import React, { useState, useEffect, useRef } from "react";
import { HiDotsCircleHorizontal } from "react-icons/hi";
import "./RecordDisplay.Style.css";
import { useDispatch, useSelector } from "react-redux";
import { renameRecord, deleteRecord } from "../../redux/ActionCreator/Records.ActionCreator";
import { BsFillPlayCircleFill, BsFillPauseCircleFill } from "react-icons/bs";
import { GrBackTen, GrForwardTen } from "react-icons/gr";
import CreateFolderComponent from "../Folders/CreateFolder.Component";
import { changeRecordFolder } from "../../redux/ActionCreator/Records.ActionCreator";
import { toastError } from "../../redux/ActionCreator/Toast.ActionCreator";
import { DOWNLOAD_RECORD_PATH } from "../../constants/ApiPaths";
import { logoutSuccess } from "../../redux/ActionCreator/Auth.ActionCreator";
import { useNavigate } from "react-router-dom";
import { Subscription_Feature_Switch } from "../../constants/FeatureSwitch";

function RecordDisplayComponent(props) {
    // state
    const [isDropdownButton, setDropdownButton] = useState(false);
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editedName, setEditedName] = useState(props.name);
    const [prevName, setPrevName] = useState(props.name);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [showFolder, setShowFolder] = useState(false);

    const records = useSelector((state) => state.Records.records[props.folderName]);
    const subscriptionType = useSelector((state) => state.Subscription.subscriptionType);

    // references
    const audioPlayer = useRef(); // reference our audio component
    const progressBar = useRef(); // reference our progress bar
    const animationRef = useRef(); // reference the animation
    const inputRef = useRef(null);
    const audioContext = useRef();
    const analyser = useRef();
    const dataArray = useRef();
    const canvasRef = useRef();
    const canvasContext = useRef();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const toggleDropdown = (visibility) => {
        setDropdownVisible(visibility);
    };

    const toggleButton = (visibility) => {
        setDropdownButton(visibility);
        if (!visibility) {
            toggleDropdown(visibility);
        }
    };

    const handleRename = () => {
        setIsEditing(false);
        const payload = {
            recordId: props.recordId,
            folderId: props.folderId,
            recordName: editedName,
            folderName: props.folderName,
        };
        let flag = 1;
        for (let i = 0; i < records.length; i++) {
            if (records[i].recordname === editedName) {
                flag = 0;
                break;
            }
        }

        if (flag === 0) {
            setEditedName(prevName);
            const payload = {
                type: "Error",
                message: "Record Name is not unique.",
            };
            dispatch(toastError(payload));
        } else {
            dispatch(renameRecord(payload));
            setPrevName(editedName);
        }
    };

    const handleDelete = (recordId) => {
        const data = {
            recordId: recordId,
        };
        dispatch(deleteRecord(data));
    };

    const handlePerticularMove = () => {
        const payload = {
            recordId: props.recordId,
            newFolderName: props.moveTo,
        };
        dispatch(changeRecordFolder(payload));
        setDropdownVisible(false);
    };

    const convertTime = (seconds) => {
        const hrs = ~~(seconds / 3600);
        const mins = ~~((seconds % 3600) / 60);
        const secs = ~~seconds % 60;
        let formatedTime = "";
        if (hrs > 0) {
            formatedTime += "" + (hrs<10?("0"+hrs):hrs) + ":";
        }
        
        if(mins>0)
        {
            formatedTime += "" + (mins<10?("0"+mins):mins) + ":";
        }
        else
        formatedTime += "00:";

        if(secs>0)
        {
            formatedTime += "" + (secs<10?("0"+secs):secs);
        }
        else
        formatedTime += "00";

        return formatedTime;
    };

    const calculateAge = (date) => {
        const current = Date.parse(new Date());
        let startTime = new Date(date);
        const updatedAt = new Date(startTime.getTime() - startTime.getTimezoneOffset() * 60000);
        const diff = current - updatedAt;
        const min = 1000 * 60;

        const minutes = Math.floor(diff / min);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 31);
        const years = Math.floor(months / 12);

        if (years > 0) {
            if (years === 1) return `${years} year ago`;
            return `${years} years ago`;
        }
        if (months > 0) {
            if (months === 1) return `${months} month ago`;
            return `${months} months ago`;
        }
        if (days > 0) {
            if (days === 1) return `${days} day ago`;
            return `${days} days ago`;
        }
        if (hours > 0) {
            if (hours === 1) return `${hours} hr ago`;
            return `${hours} hrs ago`;
        }
        if (minutes >= 0) {
            if (minutes === 1) return `${minutes} min ago`;
            return `${minutes} mins ago`;
        }
    };

    const togglePlayPause = () => {
        if (!audioContext.current) {
            preparation();
            canvasContext.current = canvasRef.current.getContext("2d");
        }
        const prevValue = isPlaying;
        setIsPlaying(!prevValue);
        if (!prevValue) {
            audioPlayer.current.play();
            audioContext.current.resume();
            loop();
            props.onPlayPause();
            animationRef.current = requestAnimationFrame(whilePlaying);
        } else {
            audioPlayer.current.pause();
            cancelAnimationFrame(animationRef.current);
        }
    };

    useEffect(() => {
        if (isPlaying && !props.isPlaying) {
            togglePlayPause();
        }
    }, [props.isPlaying]);

    const whilePlaying = () => {
        if (!audioPlayer.current) {
            cancelAnimationFrame(animationRef.current);
            return;
        }
        setCurrentTime(audioPlayer.current.currentTime);
        const maxValue = progressBar.current.getAttribute("max");
        const duration = props.seconds;
        const current = audioPlayer.current.currentTime;
        progressBar.current.value = (maxValue * current) / duration;
        changePlayerCurrentTime(current);
        if (current !== audioPlayer.current.duration) {
            animationRef.current = requestAnimationFrame(whilePlaying);
        } else {
            cancelAnimationFrame(animationRef.current);
            audioPlayer.current.currentTime = 0;
            progressBar.current.value = 0;
            setCurrentTime(0);
            audioPlayer.current.pause();
            setIsPlaying(false);
        }
    };

    const changeRange = () => {
        const progressBarValue = progressBar.current.value;
        const maxValue = progressBar.current.getAttribute("max");
        const duration = props.seconds;
        const newCurrentTime = (duration * progressBarValue) / maxValue;
        setCurrentTime(newCurrentTime);
        audioPlayer.current.currentTime = newCurrentTime;
        changePlayerCurrentTime(newCurrentTime);
    };

    const changePlayerCurrentTime = (currentTime) => {};

    const backTen = () => {
        let newCurrentTime = currentTime - 10;

        if (newCurrentTime < 0) newCurrentTime = 0;

        setCurrentTime(newCurrentTime);
        const maxValue = progressBar.current.getAttribute("max");
        const duration = props.seconds;
        progressBar.current.value = (maxValue * newCurrentTime) / duration;
        audioPlayer.current.currentTime = newCurrentTime;
        changePlayerCurrentTime(newCurrentTime);
    };

    const forwardTen = () => {
        let newCurrentTime = currentTime + 10;

        if (newCurrentTime > props.seconds) newCurrentTime = props.seconds;

        setCurrentTime(newCurrentTime);
        const maxValue = progressBar.current.getAttribute("max");
        const duration = props.seconds;
        progressBar.current.value = (maxValue * newCurrentTime) / duration;
        audioPlayer.current.currentTime = newCurrentTime;
        changePlayerCurrentTime(newCurrentTime);
    };

    function handleDownload(fileName) {
        if(Subscription_Feature_Switch && 
        subscriptionType === process.env.REACT_APP_BASIC_SUBSCRIPTION)
        {
            const payload = {
                type: "Error",
                message: "Upgrade plan to enable dowload",
            };
            dispatch(toastError(payload));
            return;
        }
        const anchor = document.createElement("a");
        fetch(process.env.REACT_APP_API_BASE_URL + DOWNLOAD_RECORD_PATH + fileName, {
            method: "GET",
            credentials: "include",
        })
            .then(async (response) => {
                if (response.status === 401) {
                    dispatch(logoutSuccess());
                    return;
                }
                return await response.blob(); // Wait for the blob to be resolved
            })
            .then((blobby) => {
                if (blobby) {
                    let objectUrl = window.URL.createObjectURL(blobby);

                    anchor.href = objectUrl;
                    anchor.download = editedName;
                    anchor.click();

                    window.URL.revokeObjectURL(objectUrl);
                }
            })
            .catch((error) => {});

        anchor.remove();
    }

    const preparation = () => {
        audioContext.current = new AudioContext();
        analyser.current = audioContext.current.createAnalyser();
        analyser.current.fftSize = 128;
        const src = audioContext.current.createMediaElementSource(audioPlayer.current);
        src.connect(analyser.current);
        analyser.current.connect(audioContext.current.destination);
        loop();
    };

    const loop = () => {
        if (!audioPlayer.current) {
            cancelAnimationFrame(animationRef.current);
            return;
        }

        if (audioPlayer.current.paused) {
            return;
        }

        canvasContext.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

        window.requestAnimationFrame(loop);

        dataArray.current = new Uint8Array(analyser.current.frequencyBinCount);
        analyser.current.getByteFrequencyData(dataArray.current);
        changeTracks();
    };

    const changeTracks = () => {
        const canvasWidth = canvasRef.current.width;
        const canvasHeight = canvasRef.current.height;
        if (!dataArray.current) return;
        const barWidth = canvasWidth * 0.01;
        const barSpacing = barWidth;
        for (let i = 3; i < 47; i++) {
            if (dataArray.current[i] <= 4) {
                dataArray.current[i] = 4;
            }
            const barHeight = dataArray.current[i] * 0.5;
            const x = (i + 0.3) * (barWidth + barSpacing);
            const y = (canvasHeight - barHeight) / 2;

            const borderRadius = 2;

            canvasContext.current.fillStyle = "#000000";
            drawRoundedRect(canvasContext.current, x, y, barWidth, barHeight, borderRadius);
        }
    };

    function drawRoundedRect(ctx, x, y, width, height, radius) {
        ctx.beginPath();
        ctx.moveTo(x + radius, y);
        ctx.arcTo(x + width, y, x + width, y + height, radius);
        ctx.arcTo(x + width, y + height, x, y + height, radius);
        ctx.arcTo(x, y + height, x, y, radius);
        ctx.arcTo(x, y, x + width, y, radius);
        ctx.closePath();
        ctx.fill();
    }

    useEffect(() => {
        audioPlayer.current.crossorigin = "anonymous";
        if (!dataArray.current) {
            dataArray.current = [];
            for (let i = 1; i <= 48; i++) {
                if (i % 4 === 0) {
                    dataArray.current.push(2);
                } else if (i % 2 === 0) {
                    dataArray.current.push(2);
                } else if (i % 3 === 0) {
                    dataArray.current.push(1.6);
                } else {
                    dataArray.current.push(2.8);
                }
                dataArray.current[i - 1] *= 40;
            }
            if (!audioContext.current) {
                preparation();
                canvasContext.current = canvasRef.current.getContext("2d");
            }

            canvasContext.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
            changeTracks();
        }

        const handleResize = () => {
            const { width, height } = canvasRef.current.getBoundingClientRect();
            canvasRef.current.width = width;
            canvasRef.current.height = height;
            canvasContext.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
            changeTracks();
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleDetailsPagePropogation = () => {
        navigate(`/share/${props.recordId}`);
    };

    useEffect(() => {
        setEditedName(props.name);
    }, [props.name]);

    useEffect(() => {
        if (isEditing) {
            inputRef.current.focus();
            inputRef.current.select();
        }
    }, [isEditing]);
    useEffect(() => {
        if (audioPlayer.current && props.url) {
            audioPlayer.current.src = props.url;
            audioPlayer.current.load();
        }
      }, [props.url]);

    return (
        <div className="record-card" onMouseEnter={() => toggleButton(true)} onMouseLeave={() => toggleButton(false)}>
            {isDropdownButton && <HiDotsCircleHorizontal onClick={() => toggleDropdown(!isDropdownVisible)} alt="Search Icon" className="options-icon" />}

            {isDropdownVisible && (
                <div className="dropdown">
                    <ul>
                        <li
                            onClick={() => {
                                setIsEditing(!isEditing);
                                toggleDropdown(false);
                            }}
                        >
                            Rename
                        </li>
                        <li
                            onClick={() => {
                                handleDownload(props.fileName);
                                toggleDropdown(false);
                            }}
                        >
                            Download
                        </li>
                        <li
                            onClick={() => {
                                setShowFolder(true);
                                toggleDropdown(false);
                            }}
                        >
                            Move
                        </li>
                        <li
                            onClick={() => {
                                handlePerticularMove();
                                toggleDropdown(false);
                            }}
                        >
                            {props.displayName}
                        </li>
                        <li
                            onClick={() => {
                                handleDelete(props.recordId);
                                toggleDropdown(false);
                            }}
                        >
                            Delete
                        </li>
                    </ul>
                </div>
            )}
            {showFolder && <CreateFolderComponent setShowFolder={setShowFolder} type={"move"} recordId={props.recordId} />}
            <div className="record-image">
                <div id="AudioVisualComponentDiv">
                    <canvas
                        className="records-canvas"
                        ref={canvasRef}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleDetailsPagePropogation();
                        }}
                        style={{ width: "100%", height: "100%" }}
                    ></canvas>
                </div>
                <div className="time-display">{convertTime(props.length)}</div>
            </div>
            <div className="record-footer">
                {isEditing ? (
                    <input
                        ref={inputRef}
                        type="text"
                        value={editedName}
                        onChange={(e) => setEditedName(e.target.value)}
                        onBlur={handleRename}
                        className="edit-input"
                        style={{ background: "none", border: "none", width: "100%", textAlign: "center" }}
                    />
                ) : (
                    <div className="footer-name">{editedName}</div>
                )}
                <div className="footer-old">{calculateAge(props.old)}</div>
                <div className="footer-player">
                    <div className="audioPlayer">
                        <audio
                            ref={audioPlayer}
                            src = {props.url}
                            preload="metadata"
                            type="audio/wav"
                            crossOrigin="anonymous"
                        ></audio>
                        <div className="progressContainer">
                            <div className="currentTime">{convertTime(currentTime)}</div>
                            <div className="progressBarContainer">
                                <input type="range" className="progressBar" defaultValue="0" max="100" ref={progressBar} onChange={changeRange} />
                            </div>
                            <div className="duration">{!isNaN(props.seconds) && convertTime(props.seconds)}</div>
                        </div>
                        <div className="controls">
                            <GrBackTen className="forwardBackward" onClick={backTen}></GrBackTen>
                            <button onClick={togglePlayPause} className="playPause">
                                {isPlaying ? <BsFillPauseCircleFill /> : <BsFillPlayCircleFill className="play" />}
                            </button>
                            <GrForwardTen className="forwardBackward" onClick={forwardTen}></GrForwardTen>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RecordDisplayComponent;

import React from "react";
import "./PageNotFound.style.css";
import { useNavigate } from "react-router-dom";

function PageNotFoundComponent(props) {
    const navigate = useNavigate();

    return (
        <div className="pageNotFoundComponent">
            <h1 className="pageNotFoundHeader">404</h1>
            <p className="pageNotFoundPara">Oops! Page not found.</p>
            <a
                className="pageNotFoundbutton"
                onClick={() => {
                    navigate("/record/common");
                }}
            >
                <i className="icon-home"></i> Go to home page
            </a>
        </div>
    );
}

export default PageNotFoundComponent;

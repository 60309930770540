import React, { useEffect, useState } from "react";
import { Routes, Route, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import LoggedInHeaderComponent from "../Header/LoggedInHeader.Component";
import BaseRecordComponent from "./BaseRecord/BaseRecord.Component";
import BaseFolderComponent from "../Folders/BaseFolder.Component";
import SearchComponent from "../Search/Search.Component";
import { recordsLoading } from "../../redux/ActionCreator/Records.ActionCreator";
import './LoggedIn.Route.css';

export default function LoggedInRoutes(props) {
    const [isRecording, setIsRecording] = useState(false);

    return (
        <div className={`LoggedInRoutes ${isRecording ? "blur-background" : ""}`}>
            <LoggedInHeaderComponent isRecording={isRecording} setIsRecording={setIsRecording} toggleSidebar = {props.toggleSidebar}/>
            <Routes>
                <Route exact path="/common" element={<BaseRecordComponent folderName={"recently added"} displayName={"Favourite"} moveTo={"favourite"} method={recordsLoading} />} />
                <Route exact path="/favourite" element={<BaseRecordComponent folderName={"favourite"} displayName={"Common Records"} moveTo={"recently added"} method={recordsLoading} />} />
                <Route exact path="/folder" element={<BaseFolderComponent />} />
                <Route exact path="/search/:recordName" element={<SearchComponent />} />
                <Route exact path="/folder/:folderId" element={<BaseRecordComponentWrapper displayName={"Favourite"} moveTo={"favourite"} method={recordsLoading} />} />
            </Routes>
        </div>
    );
}

function BaseRecordComponentWrapper(props) {
    const { folderId } = useParams();
    const [folderName, setFolderName] = useState("");

    const folders = useSelector((state) => state.Folders.folders);

    useEffect(() => {
        if (folders) {
            for (let i = 0; i < folders.length; i++) {
                if (folders[i].id === folderId) {
                    setFolderName(folders[i].foldername);
                    break;
                }
            }
        }
    }, [folders]);

    return <BaseRecordComponent folderName={folderName} displayName={props.displayName} moveTo={props.moveTo} method={recordsLoading} />;
}

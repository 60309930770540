import * as ActionTypes from "../../constants/ActionTypes";

export const toastSuccess = (toast) => {
    return {
        type: ActionTypes.TOAST_SUCCESS,
        toast,
    };
};

export const toastError = (payload) => {
    return {
        type: ActionTypes.TOAST_ERROR,
        payload,
    };
};

import * as ActionTypes from "../../constants/ActionTypes";

export const addFolders = (records) => ({
    type: ActionTypes.ADD_FOLDER,
    payload: records,
});

export const foldersLoading = () => ({
    type: ActionTypes.FOLDERS_LOADING,
});

export const foldersFailed = (errmess) => ({
    type: ActionTypes.FOLDERS_FAILURE,
    payload: errmess,
});

export const defaultFolder = () => ({
    type: ActionTypes.DEFAULT_FOLDERS,
});

export const defaultFolderSuccess = (folders) => ({
    type: ActionTypes.DEFAULT_FOLDERS_SUCCESS,
    folders,
});

export const defaultFolderFailure = (message) => ({
    type: ActionTypes.DEFAULT_FOLDERS_FAILURE,
    message,
});

export const createFolder = (payload) => ({
    type: ActionTypes.CREATE_FOLDER,
    payload,
});

export const createFolderSuccess = (message) => ({
    type: ActionTypes.CREATE_FOLDER_SUCCESS,
    message,
});

export const createFolderFailure = (message) => ({
    type: ActionTypes.CREATE_FOLDER_FAILURE,
    message,
});

export const renameFolder = (payload) => ({
    type: ActionTypes.RENAME_FOLDER,
    payload,
});

export const renameFolderSuccess = (message) => ({
    type: ActionTypes.RENAME_FOLDER_SUCCESS,
    message,
});

export const renameFolderFailure = (message) => ({
    type: ActionTypes.RECORDS_SUCCESS,
    message,
});

export const deleteFolder = (payload) => ({
    type: ActionTypes.DELETE_FOLDER,
    payload,
});

export const deleteFolderSuccess = (message) => ({
    type: ActionTypes.DELETE_FOLDER_SUCCESS,
    message,
});

export const deleteFolderFailure = (message) => ({
    type: ActionTypes.DELETE_FOLDER_FAILURE,
    message,
});

import * as ActionTypes from "../../constants/ActionTypes";
import { InitialState } from "../../constants/InitialStates";

export const Help = (state = InitialState.Help, action) => {
    switch (action.type) {
        case ActionTypes.CREATE_HELP: {
            return { ...state, isSubmitting: true, errMess: null };
        }
        case ActionTypes.CREATE_HELP_SUCCESS: {
            return { ...state, isSubmitting: false, errMess: null };
        }
        case ActionTypes.CREATE_HELP_FAILURE: {
            return { ...state, isSubmitting: false, errMess: action.payload };
        }
        default:
            return state;
    }
};

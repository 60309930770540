export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const GOOGLE_LOGIN_REQUEST = "GOOGLE_LOGIN_REQUEST";
export const GOOGLE_LOGIN_SUCCESS = "GOOGLE_LOGIN_SUCCESS";
export const GOOGLE_LOGIN_FAILURE = "GOOGLE_LOGIN_FAILURE";

export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_ERROR";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const CHANGE_INFO_REQUEST = "CHANGE_INFO_REQUEST";
export const CHANGE_INFO_SUCCESS = "CHANGE_INFO_SUCCESS";
export const CHANGE_INFO_FAILURE = "CHANGE_INFO_FAILURE";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const DELETE_PROFILE_REQUEST = "DELETE_PROFILE_REQUEST";
export const DELETE_PROFILE_SUCCESS = "DELETE_PROFILE_SUCCESS";
export const DELETE_PROFILE_FAILURE = "DELETE_PROFILE_FAILURE";

export const OTP_REQUEST = "OTP_REQUEST";
export const OTP_SUCCESS = "OTP_SUCCESS";
export const OTP_FAILURE = "OTP_FAILURE";

export const VERIFIED_REQUEST = "VERIFIED_REQUEST";
export const VERIFIED_SUCCESS = "VERIFIED_SUCCESS";
export const VERIFIED_FAILURE = "VERIFIED_FAILURE";

export const INFO_REQUEST = "INFO_REQUEST";
export const INFO_SUCCESS = "INFO_SUCCESS";
export const INFO_FAILURE = "INFO_FAILURE";

export const RECORDS_LOADING = "RECORDS_LOADING";
export const RECORDS_SUCCESS = "RECORDS_SUCCESS";
export const RECORDS_FAILURE = "RECORDS_FAILED";

export const SEARCH_LOADING = "SEARCH_LOADING";
export const SEARCH_SUCCESS = "SEARCH_SUCCESS";
export const SEARCH_FAILURE = "SEARCH_FAILED";

export const ADD_RECORDS = "ADD_RECORDS";
export const ADD_RECORDS_FAILURE = "ADD_RECORDS_FAILURE";
export const ADD_RECORDS_SUCCESS = "ADD_RECORDS_SUCCESS";

export const RENAME_RECORD = "RENAME_RECORD";
export const RENAME_RECORD_SUCCESS = "RENAME_RECORD_SUCCESS";
export const RENAME_RECORD_FAILURE = "RENAME_RECORD_FAILURE";

export const CREATE_RECORD = "CREATE_RECORD";
export const CREATE_RECORD_SUCCESS = "CREATE_RECORD_SUCCESS";
export const CREATE_RECORD_FAILURE = "CREATE_RECORD_FAILURE";

export const DELETE_RECORD = "DELETE_RECORD";
export const DELETE_RECORD_SUCCESS = "DELETE_RECORD_SUCCESS";
export const DELETE_RECORD_FAILURE = "DELETE_RECORD_FAILURE";

export const CHANGE_RECORD_FOLDER = "CHANGE_RECORD_FOLDER";
export const CHANGE_RECORD_FOLDER_SUCCESS = "CHANGE_RECORD_FOLDER_SUCCESS";
export const CHANGE_RECORD_FOLDER_FAILURE = "CHANGE_RECORD_FOLDER_FAILURE";

export const RECORD_LOADING = "RECORD_LOADING";
export const RECORD_SUCCESS = "RECORD_SUCCESS";
export const RECORD_FAILURE = "RECORD_FAILED";

export const SHARE_PERMISSION_DETAIL_LOADING = "SHARE_PERMISSION_DETAIL_LOADING";
export const SHARE_PERMISSION_DETAIL_SUCCESS = "SHARE_PERMISSION_DETAIL_SUCCESS";
export const SHARE_PERMISSION_DETAIL_FAILURE = "SHARE_PERMISSION_DETAIL_FAILURE";

export const SHARE_PERMISSION_LOADING = "SHARE_PERMISSION_LOADING";
export const SHARE_PERMISSION_SUCCESS = "SHARE_PERMISSION_SUCCESS";
export const SHARE_PERMISSION_FAILURE = "SHARE_PERMISSION_FAILED";

export const SHARE_PEOPLE_LIST_LOADING = "SHARE_PEOPLE_LIST_LOADING";
export const SHARE_PEOPLE_LIST_SUCCESS = "SHARE_PEOPLE_LIST_SUCCESS";
export const SHARE_PEOPLE_LIST_FAILURE = "SHARE_PEOPLE_LIST_FAILURE";

export const REMOVE_PERMISSION_LOADING = "REMOVE_PERMISSION_LOADING";
export const REMOVE_PERMISSION_SUCCESS = "REMOVE_PERMISSION_SUCCESS";
export const REMOVE_PERMISSION_FAILURE = "REMOVE_PERMISSION_FAILED";

export const CREATE_FEEDBACK = "CREATE_FEEDBACK";
export const CREATE_FEEDBACK_SUCCESS = "CREATE_FEEDBACK_SUCCESS";
export const CREATE_FEEDBACK_FAILURE = "CREATE_FEEDBACK_FAILURE";

export const CREATE_HELP = "CREATE_HELP";
export const CREATE_HELP_SUCCESS = "CREATE_HELP_SUCCESS";
export const CREATE_HELP_FAILURE = "CREATE_HELP_FAILURE";

export const FOLDERS_LOADING = "FOLDERS_LOADING";
export const FOLDERS_SUCCESS = "FOLDERS_SUCCESS";
export const FOLDERS_FAILURE = "FOLDERS_FAILED";

export const CREATE_FOLDER = "CREATE_FOLDER";
export const CREATE_FOLDER_SUCCESS = "CREATE_FOLDER_SUCCESS";
export const CREATE_FOLDER_FAILURE = "CREATE_FOLDER_FAILURE";

export const DELETE_FOLDER = "DELETE_FOLDER";
export const DELETE_FOLDER_SUCCESS = "DELETE_FOLDER_SUCCESS";
export const DELETE_FOLDER_FAILURE = "DELETE_FOLDER_FAILURE";

export const RENAME_FOLDER = "RENAME_FOLDER";
export const RENAME_FOLDER_SUCCESS = "RENAME_FOLDER_SUCCESS";
export const RENAME_FOLDER_FAILURE = "RENAME_FOLDER_FAILURE";

export const ADD_FOLDER = "ADD_FOLDER";
export const ADD_FOLDER_FAILURE = "ADD_FOLDER_FAILED";
export const ADD_FOLDER_SUCCESS = "ADD_FOLDER_SUCCESS";

export const DEFAULT_FOLDERS = "DEFAULT_FOLDERS";
export const DEFAULT_FOLDERS_SUCCESS = "DEFAULT_FOLDERS_SUCCESS";
export const DEFAULT_FOLDERS_FAILURE = "DEFAULT_FOLDERS_FAILURE";

export const CREATE_SUBSCRIBER = "CREATE_SUBSCRIBER";
export const CREATE_SUBSCRIBER_SUCCESS = "CREATE_SUBSCRIBER_SUCCESS";
export const CREATE_SUBSCRIBER_FAILURE = "CREATE_SUBSCRIBER_FAILURE";

export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";
export const CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS";
export const CANCEL_SUBSCRIPTION_FAILURE = "CANCEL_SUBSCRIPTION_FAILURE";

export const CONTINUE_SUBSCRIPTION = "CONTINUE_SUBSCRIPTION";
export const CONTINUE_SUBSCRIPTION_SUCCESS = "CONTINUE_SUBSCRIPTION_SUCCESS";
export const CONTINUE_SUBSCRIPTION_FAILURE = "CONTINUE_SUBSCRIPTION_FAILURE";

export const CUSTOMER_PORTAL = "CUSTOMER_PORTAL";
export const CUSTOMER_PORTAL_SUCCESS = "CUSTOMER_PORTAL_SUCCESS";
export const CUSTOMER_PORTAL_FAILURE = "CUSTOMER_PORTAL_FAILURE";

export const CLIP_LOADING = "CLIP_LOADING";
export const CLIP_SUCCESS = "CLIP_SUCCESS";
export const CLIP_FAILURE = "CLIP_FAILED";

export const CREATE_CLIP_LOADING = "CREATE_CLIP_LOADING";
export const CREATE_CLIP_SUCCESS = "CREATE_CLIP_SUCCESS";
export const CREATE_CLIP_FAILURE = "CREATE_CLIP_FAILURE";

export const UNCLIP_LOADING = "UNCLIP_LOADING";
export const UNCLIP_SUCCESS = "UNCLIP_SUCCESS";
export const UNCLIP_FAILURE = "UNCLIP_FAILURE";

export const ERROR_MESS = "ERROR_MESS";

export const TOAST_SUCCESS = "TOAST_SUCCESS";
export const TOAST_ERROR = "TOAST_ERROR";

import React from "react";
import "./LoadingGeneral.Style.css";

function LoadingComponent(props) {
    return (
        <div className="spinner">
            <div className="roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>

            <div>{props.loading} ...</div>
        </div>
    );
}

export default LoadingComponent;

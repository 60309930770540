import { call, put, takeEvery } from "redux-saga/effects";
import * as ActionTypes from "../../constants/ActionTypes";
import { get, post } from "../../api/AxiosConfig";
import { CREATE_CLIPS_PATH, GET_CLIP_PATH, UNCLIP_PATH } from "../../constants/ApiPaths";

const msg = "Your session timed out";

function* getUrl(action) {
    try {
        const response = yield call(get, GET_CLIP_PATH + action.data.id);
        const data = response.data;
        if (response.status === 200) {
            const urls = data.Info;
            yield put({ type: ActionTypes.CLIP_SUCCESS, payload: urls });
        } else if (response.status === 401) {
            yield put({ type: ActionTypes.LOGOUT_SUCCESS });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: msg } });
        } else {
            yield put({ type: ActionTypes.CLIP_FAILURE, data });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: data.message } });
        }
    } catch (error) {
        yield put({ type: ActionTypes.CLIP_FAILURE, payload: { error } });
        yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: "Failed to load clips." } });
    }
}

export function* urlsRequest() {
    yield takeEvery(ActionTypes.CLIP_LOADING, getUrl);
}

function* createClip(action) {
    try {
        const response = yield call(post, CREATE_CLIPS_PATH, null, action.payload);
        const data = response.data;
        if (response.status === 200) {
            const payload = action.payload;
            yield put({ type: ActionTypes.CREATE_CLIP_SUCCESS, payload });
            yield put({ type: ActionTypes.TOAST_SUCCESS, payload: { type: "Success", message: data.status } });
            return { success: true };
        } else if (response.status === 401) {
            yield put({ type: ActionTypes.LOGOUT_SUCCESS });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: msg } });
        } else {
            yield put({ type: ActionTypes.CREATE_CLIP_FAILURE, data });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: data.status } });
            return { success: false };
        }
    } catch (error) {
        yield put({ type: ActionTypes.CREATE_CLIP_FAILURE, payload: { error } });
        yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: "Failed to clip." } });
        return { success: false };
    }
}

export function* createClipRequest() {
    yield takeEvery(ActionTypes.CREATE_CLIP_LOADING, createClip);
}

function* deleteClip(action) {
    try {
        const response = yield call(post, UNCLIP_PATH, null, action.payload);
        const data = response.data;
        if (response.status === 200) {
            const status = data.status;
            const payload = action.payload;
            yield put({ type: ActionTypes.UNCLIP_SUCCESS, payload });
            yield put({ type: ActionTypes.TOAST_SUCCESS, payload: { type: "Success", message: status } });
        } else if (response.status === 401) {
            yield put({ type: ActionTypes.LOGOUT_SUCCESS });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: msg } });
        } else {
            yield put({ type: ActionTypes.UNCLIP_FAILURE, data });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: data.status } });
        }
    } catch (error) {
        yield put({ type: ActionTypes.UNCLIP_FAILURE, payload: { error } });
        yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: "Failed to remove clip" } });
        return { success: false };
    }
}

export function* deleteClipRequest() {
    yield takeEvery(ActionTypes.UNCLIP_LOADING, deleteClip);
}

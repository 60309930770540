import { Link, useNavigate } from "react-router-dom";
import "./Header.Style.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { AUTHENTICATION_CHECK_PATH } from "../../constants/ApiPaths";
import { logoutSuccess } from "../../redux/ActionCreator/Auth.ActionCreator";
import { Subscription_Feature_Switch } from "../../constants/FeatureSwitch";
//import logo from '../../../public/vector/default-monochrome.svg';

function HeaderComponent() {
    const token = useSelector((state) => state.Auth.token);
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const checkToken = () => {
        if (token !== null) {
            fetch(process.env.REACT_APP_API_BASE_URL + AUTHENTICATION_CHECK_PATH, { credentials: "include" })
                .then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        return navigate("/record/common");
                    } else {
                        dispatch(logoutSuccess());
                    }
                })
                .catch((error) => {
                    dispatch(logoutSuccess());
                });
        }
    };

    useEffect(() => {
        checkToken();
    }, [token]);

    return (
        <div className="HeaderComponetDiv">
            <header className="d-flex  align-items-center justify-content-md-between  mb-4">
                <a href="/" className="logo">
                    <img src='/vector/logo.png' className="logoImg" alt="logo" />
                    <div className="header-company-name">EchoVault</div>
                </a>
                <div className="header-menu">
                    <a href={process.env.REACT_APP_EXTENSION} className="extension">
                        Extension
                    </a>

                    {
                        Subscription_Feature_Switch &&
                        <Link to="/pricing" className="signin" style={{ textDecoration: "none" }}>
                            Pricing
                        </Link>
                    }

                    <Link to="/auth" state={{ method: "signin" }} className="signin" style={{ textDecoration: "none" }}>
                        SIGN IN
                    </Link>

                    <Link to="/auth" state={{ method: "signup" }} className="signup" style={{ textDecoration: "none" }}>
                        SIGN UP
                    </Link>
                </div>
            </header>
        </div>
    );
}

export default HeaderComponent;

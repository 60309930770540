import { useNavigate } from "react-router-dom";
import "./HeaderRegistrationPage.Style.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { AUTHENTICATION_CHECK_PATH } from "../../constants/ApiPaths";
import { logoutSuccess } from "../../redux/ActionCreator/Auth.ActionCreator";

function HeaderComponent(props) {
    const token = useSelector((state) => state.Auth.token);
    const [backTo, setbackTo] = useState("/");
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const checkToken = () => {
        if (token !== null) {
            fetch(process.env.REACT_APP_API_BASE_URL + AUTHENTICATION_CHECK_PATH, { credentials: "include" })
                .then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        if (props.noredirect) {
                            setbackTo("/record/common");
                            return;
                        }
                        return navigate("/record/common");
                    } else {
                        if (props.noredirect) {
                            setbackTo("/");
                            return;
                        }
                        dispatch(logoutSuccess());
                    }
                })
                .catch((error) => {
                    dispatch(logoutSuccess());
                });
        }
    };

    useEffect(() => {
        checkToken();
    }, [token]);

    return (
        <div className="HeaderComponetDivRegistration">
            <a href={backTo} className="logo">
                <img src="/vector/logo.png" className="logoImg" alt="Record logo" />
                <div className="auth-header-company-name">EchoVault</div>
            </a>
        </div>
    );
}

export default HeaderComponent;

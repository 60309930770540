import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import './Contact.Style.css';
import { useDispatch, useSelector } from "react-redux";
import HeaderComponent from "../Header/HeaderRegistrationPage.Component";
import FooterComponent from "../Footer/Footer.Component";
import { toastError } from "../../redux/ActionCreator/Toast.ActionCreator";
import { createHelp } from "../../redux/ActionCreator/Help.ActionCreator";
import SeoComponent from "../seo/Seo.Component";

function ContactComponent(props) {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [help, setHelp] = useState("");
    const [submitting, setSubmitting] = useState(false);

    const isSubmitting = useSelector((state) => state.Help.isSubmitting);
    const err = useSelector((state) => state.Help.err);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSubmit = () => {
        const nameRegex = "(^[A-Za-z]{1,16})([ ]{0,1})([A-Za-z]{1,16})?([ ]{0,1})?([A-Za-z]{3,16})?([ ]{0,1})?([A-Za-z]{1,16})";
        if (name === "" || email === "" || help === "") {
            const payload = {
                type: "Error",
                message: "All fields are mandatory",
            };
            dispatch(toastError(payload));
            return;
        } else if (!name.match(nameRegex)) {
            const payload = {
                type: "Error",
                message: "please enter valid name",
            };
            dispatch(toastError(payload));
            return;
        }
        setSubmitting(true);
        const payload = {
            name: name,
            email: email,
            helprequire: help,
        };
        dispatch(createHelp(payload));
        setName("");
        setEmail("");
        setHelp("");
    };

    useEffect(() => {
        if(isSubmitting === false && submitting)
        {
            if(err === null)
            {
                return navigate('/record/common');
            }
            else
            {
                setSubmitting(false);
            }
        }
    },[isSubmitting]);

    return (
        <div className="contact-wrapper">
            <SeoComponent
                title = 'Contact'
                description = 'How can we help you? - Echovault'
                name = 'Echovault'
                type = 'website' />
            <HeaderComponent redirectTo={"/record/common"} noredirect={true}/>
            <div>
                <form className="form-container-help">
                    <h2 className="help-form-title">Contact Us</h2>
                    <h3 className="help-form-subtitle">If you need assistance or have inquiries, please complete the form provided below.</h3>
                    <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} maxLength="35" className="help-form-field"/>
                    <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} maxLength="40" className="help-form-field"/>
                    <input type="text" placeholder="How can we help you ..." value={help} onChange={(e) => setHelp(e.target.value)} maxLength="200" className="help-form-field"/>
                    <button type="button" onClick={handleSubmit} className="help-form-field help-submit-button" disabled = {submitting}>{submitting? "Sending ..." : "Submit"}</button>
                </form>
            </div>
            <FooterComponent/>
        </div>
    );
}

export default ContactComponent;

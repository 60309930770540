import React, { useState, useEffect, useRef } from "react";
import AudioAnimationComponent from "./AudioAnimation.Component";

function AudioAnalyzer(props) {
    const audioContext = useRef();
    const analyser = useRef();
    const audioSource = useRef();
    const dataArray = useRef();
    const frameRef = useRef();
    const [audioData, setAudioData] = useState([]);
    const [defaultData, setDefaultData] = useState([0]);
    const timer = useRef(0);

    const updateVisualization = () => {
        const currentTime = Date.now();
        const elapsedTime = currentTime - timer.current;
        if (elapsedTime >= 16) {
            analyser.current.getByteFrequencyData(dataArray.current);
            setAudioData([...dataArray.current]);
            timer.current = currentTime;
        } else {
            setAudioData([...defaultData]);
        }
        frameRef.current = requestAnimationFrame(updateVisualization);
    };

    useEffect(() => {
        audioContext.current = new (window.AudioContext || window.webkitAudioContext)();
        analyser.current = audioContext.current.createAnalyser();

        const source = audioContext.current.createMediaStreamSource(props.audio);
        source.connect(analyser.current);
        audioSource.current = source;

        analyser.current.fftSize = 256;

        const bufferLength = analyser.current.frequencyBinCount;
        dataArray.current = new Uint8Array(bufferLength);
        timer.current = Date.now();

        updateVisualization();
    }, []);

    useEffect(() => {
        return () => {
            cancelAnimationFrame(frameRef.current);
            analyser.current.disconnect();
            audioSource.current.disconnect();
        };
    }, []);
    return (
        <div>
            <AudioAnimationComponent audioData={audioData} setLevelData={props.setLevelData} levelData={props.levelData} />
        </div>
    );
}

export default AudioAnalyzer;

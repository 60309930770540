import { takeEvery, put, call } from "redux-saga/effects";

import { deleteApi, get, post, putApi } from "../../api/AxiosConfig";

import {
    INFO_PATH,
    LOGIN_PATH,
    LOGOUT_PATH,
    SIGNUP_PATH,
    CHANGE_INFO_PATH,
    DELETE_PROFILE_PATH,
    GET_OTP_PATH,
    VERIFY_OTP_PATH,
    CHANGE_PASSWORD_PATH,
    GOOGLE_LOGIN_PATH,
} from "../../constants/ApiPaths";

import * as ActionTypes from "../../constants/ActionTypes";

const msg = "Your session timed out";

function* login(action) {
    try {
        const response = yield call(post, LOGIN_PATH, null, action.creds);
        const data = response.data;
        if (response.status === 200) {
            yield put({ type: ActionTypes.LOGIN_SUCCESS, data });
            yield put({
                type: ActionTypes.TOAST_SUCCESS,
                payload: { type: "Success", message: data.status },
            });
        } else {
            yield put({ type: ActionTypes.LOGIN_FAILURE, data });
            yield put({
                type: ActionTypes.TOAST_ERROR,
                payload: { type: "Error", message: data.message },
            });
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOGIN_FAILURE, payload: { error } });
        yield put({
            type: ActionTypes.TOAST_ERROR,
            payload: { type: "Error", message: "Unable to login" },
        });
    }
}

export function* loginRequest() {
    yield takeEvery(ActionTypes.LOGIN_REQUEST, login);
}

function* googleLogin(action) {
    try {
        const response = yield call(
            post,
            GOOGLE_LOGIN_PATH,
            null,
            action.creds
        );
        const data = response.data;
        if (response.status === 200) {
            yield put({ type: ActionTypes.GOOGLE_LOGIN_SUCCESS, data });
            yield put({
                type: ActionTypes.TOAST_SUCCESS,
                payload: { type: "Success", message: data.status },
            });
        } else {
            yield put({ type: ActionTypes.GOOGLE_LOGIN_FAILURE, data });
            yield put({
                type: ActionTypes.TOAST_ERROR,
                payload: { type: "Error", message: data.message },
            });
        }
    } catch (error) {
        yield put({
            type: ActionTypes.GOOGLE_LOGIN_FAILURE,
            payload: { error },
        });
        yield put({
            type: ActionTypes.TOAST_ERROR,
            payload: { type: "Error", message: "Unable to login" },
        });
    }
}

export function* googleLoginRequest() {
    yield takeEvery(ActionTypes.GOOGLE_LOGIN_REQUEST, googleLogin);
}

function* signup(action) {
    try {
        const response = yield call(post, SIGNUP_PATH, null, action.creds);
        const data = response.data;
        if (response.status === 200) {
            yield put({ type: ActionTypes.SIGNUP_SUCCESS, data });
            yield put({
                type: ActionTypes.TOAST_SUCCESS,
                payload: { type: "Success", message: data.status },
            });
            yield put({ type: ActionTypes.LOGIN_REQUEST, creds: action.creds });
        } else {
            yield put({ type: ActionTypes.SIGNUP_FAILURE, data });
            yield put({
                type: ActionTypes.TOAST_ERROR,
                payload: { type: "Error", message: data.status },
            });
        }
    } catch (error) {
        yield put({ type: ActionTypes.SIGNUP_FAILURE, payload: { error } });
        yield put({
            type: ActionTypes.TOAST_ERROR,
            payload: { type: "Error", message: "Unable to signup" },
        });
    }
}

export function* signupRequest() {
    yield takeEvery(ActionTypes.SIGNUP_REQUEST, signup);
}

function* logout(action) {
    try {
        const response = yield call(get, LOGOUT_PATH);
        const data = response.data;
        if (response.status === 200) {
            yield put({ type: ActionTypes.LOGOUT_SUCCESS, data });
            yield put({
                type: ActionTypes.TOAST_SUCCESS,
                payload: { type: "Success", message: data.status },
            });
        } else if (response.status === 401) {
            yield put({ type: ActionTypes.LOGOUT_SUCCESS });
            yield put({
                type: ActionTypes.TOAST_ERROR,
                payload: { type: "Error", message: msg },
            });
        } else {
            yield put({ type: ActionTypes.LOGOUT_FAILURE, data });
            yield put({
                type: ActionTypes.TOAST_ERROR,
                payload: { type: "Error", message: data.message },
            });
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOGOUT_FAILURE, payload: { error } });
        yield put({
            type: ActionTypes.TOAST_ERROR,
            payload: { type: "Error", message: "Logout unsuccessfull" },
        });
    }
}

export function* logoutRequest() {
    yield takeEvery(ActionTypes.LOGOUT_REQUEST, logout);
}

function* infoRequest() {
    try {
        const response = yield call(get, INFO_PATH);
        const data = response.data;
        if (response.status === 200) {
            const Info = data.Info;
            yield put({ type: ActionTypes.INFO_SUCCESS, Info });
        } else if (response.status === 401) {
            yield put({ type: ActionTypes.LOGOUT_SUCCESS });
            yield put({
                type: ActionTypes.TOAST_ERROR,
                payload: { type: "Error", message: msg },
            });
        } else {
            yield put({ type: ActionTypes.INFO_FAILURE, data });
            yield put({
                type: ActionTypes.TOAST_ERROR,
                payload: { type: "Error", message: data.message },
            });
        }
    } catch (error) {
        yield put({ type: ActionTypes.INFO_FAILURE, payload: { error } });
        yield put({
            type: ActionTypes.TOAST_ERROR,
            payload: {
                type: "Error",
                message: "Cann't fetch profile information",
            },
        });
    }
}

export function* getInfoRequest() {
    yield takeEvery(ActionTypes.INFO_REQUEST, infoRequest);
}

function* changeInfo(action) {
    try {
        const response = yield call(
            putApi,
            CHANGE_INFO_PATH,
            null,
            action.data
        );
        const data = response.data;
        if (response.status === 200) {
            const Info = data.Info;
            yield put({ type: ActionTypes.CHANGE_INFO_SUCCESS, Info });
            yield put({
                type: ActionTypes.TOAST_SUCCESS,
                payload: { type: "Success", message: data.status },
            });
        } else if (response.status === 401) {
            yield put({ type: ActionTypes.LOGOUT_SUCCESS });
            yield put({
                type: ActionTypes.TOAST_ERROR,
                payload: { type: "Error", message: msg },
            });
        } else {
            yield put({ type: ActionTypes.CHANGE_INFO_FAILURE, data });
            yield put({
                type: ActionTypes.TOAST_ERROR,
                payload: { type: "Error", message: data.status },
            });
        }
    } catch (error) {
        yield put({
            type: ActionTypes.CHANGE_INFO_FAILURE,
            payload: { error },
        });
        yield put({
            type: ActionTypes.TOAST_ERROR,
            payload: { type: "Error", status: "profile info is not updated" },
        });
    }
}

export function* changeInfoRequest() {
    yield takeEvery(ActionTypes.CHANGE_INFO_REQUEST, changeInfo);
}

function* deleteUser(action) {
    try {
        const response = yield call(deleteApi, DELETE_PROFILE_PATH, null);
        const data = response.data;
        if (response.status === 200) {
            const payload = data.Info;
            yield put({ type: ActionTypes.DELETE_PROFILE_SUCCESS, payload });
            yield put({
                type: ActionTypes.TOAST_SUCCESS,
                payload: { type: "Success", message: data.status },
            });
        } else if (response.status === 401) {
            yield put({ type: ActionTypes.LOGOUT_SUCCESS });
            yield put({
                type: ActionTypes.TOAST_ERROR,
                payload: { type: "Error", message: msg },
            });
        } else {
            yield put({ type: ActionTypes.DELETE_PROFILE_FAILURE, data });
            yield put({
                type: ActionTypes.TOAST_ERROR,
                payload: { type: "Error", message: data.status },
            });
        }
    } catch (error) {
        yield put({
            type: ActionTypes.DELETE_PROFILE_FAILURE,
            payload: { error },
        });
        yield put({
            type: ActionTypes.TOAST_ERROR,
            payload: { type: "Error", message: "Unable to delete profile." },
        });
        return { success: false };
    }
}

export function* deleteUserRequest() {
    yield takeEvery(ActionTypes.DELETE_PROFILE_REQUEST, deleteUser);
}

function* getOtp(action) {
    try {
        const response = yield call(post, GET_OTP_PATH, null, action.data);
        const data = response.data;
        if (response.status === 200) {
            const Info = data.Info;
            yield put({ type: ActionTypes.OTP_SUCCESS, Info });
            yield put({
                type: ActionTypes.TOAST_SUCCESS,
                payload: { type: "Success", message: data.status },
            });
        } else {
            yield put({ type: ActionTypes.OTP_FAILURE, data });
            yield put({
                type: ActionTypes.TOAST_ERROR,
                payload: { type: "Error", message: data.status },
            });
        }
    } catch (error) {
        yield put({ type: ActionTypes.OTP_FAILURE, payload: { error } });
        yield put({
            type: ActionTypes.TOAST_ERROR,
            payload: { type: "Error", status: "Unable to send otp" },
        });
    }
}

export function* getOtpRequest() {
    yield takeEvery(ActionTypes.OTP_REQUEST, getOtp);
}

function* verifyOtp(action) {
    try {
        const response = yield call(post, VERIFY_OTP_PATH, null, action.data);
        const data = response.data;
        if (response.status === 200) {
            const Info = data.Info;
            yield put({ type: ActionTypes.VERIFIED_SUCCESS, Info });
            if (action.data.method === "changePassword") {
                yield put({
                    type: ActionTypes.CHANGE_PASSWORD_REQUEST,
                    data: action.data,
                });
            } else if (action.data.method === "signUp") {
                yield put({
                    type: ActionTypes.SIGNUP_REQUEST,
                    creds: action.data,
                });
            }
        } else {
            yield put({ type: ActionTypes.VERIFIED_FAILURE, data });
            yield put({
                type: ActionTypes.TOAST_ERROR,
                payload: { type: "Error", message: data.status },
            });
        }
    } catch (error) {
        yield put({ type: ActionTypes.VERIFIED_FAILURE, payload: { error } });
        yield put({
            type: ActionTypes.TOAST_ERROR,
            payload: { type: "Error", status: "Unable to verify otp" },
        });
    }
}

export function* verifyOtpRequest() {
    yield takeEvery(ActionTypes.VERIFIED_REQUEST, verifyOtp);
}

function* changePassword(action) {
    try {
        const response = yield call(
            putApi,
            CHANGE_PASSWORD_PATH,
            null,
            action.data
        );
        const data = response.data;
        if (response.status === 200) {
            const Info = data.Info;
            yield put({ type: ActionTypes.CHANGE_PASSWORD_SUCCESS, Info });
            yield put({
                type: ActionTypes.TOAST_SUCCESS,
                payload: { type: "Success", message: data.status },
            });
        } else {
            yield put({ type: ActionTypes.CHANGE_PASSWORD_FAILURE, data });
            yield put({
                type: ActionTypes.TOAST_ERROR,
                payload: { type: "Error", message: data.status },
            });
        }
    } catch (error) {
        yield put({
            type: ActionTypes.CHANGE_PASSWORD_FAILURE,
            payload: { error },
        });
        yield put({
            type: ActionTypes.TOAST_ERROR,
            payload: { type: "Error", status: "Failed to update password" },
        });
    }
}

export function* changePasswordRequest() {
    yield takeEvery(ActionTypes.CHANGE_PASSWORD_REQUEST, changePassword);
}

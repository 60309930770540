import { all } from "redux-saga/effects";
import { loginRequest, 
        logoutRequest, 
        signupRequest, 
        getInfoRequest, 
        changeInfoRequest, 
        deleteUserRequest, 
        getOtpRequest, 
        verifyOtpRequest, 
        changePasswordRequest,
        googleLoginRequest
    } from "./Auth.Sagas";

import { defaultFolderRequest, 
        createFolderRequest, 
        allFolderRequest, 
        renameFolderRequest, 
        deleteFolderRequest 
    } from "./Folders.Sagas";

import { recordsRequest, 
        renameRecordRequest, 
        createRecordRequest, 
        deleteRecordRequest, 
        changeRecordFolderRequest, 
        searchRequest 
    } from "./Records.Sagas";

import { recordRequest, 
        createPermissionRequest, 
        deletePermissionRequest, 
        listRequest 
    } from "./Share.Saga";

import { createFeedbackRequest 
    } from "./Feedback.Sagas";

import { createSubscriberRequest, 
        cancelSubscriptionRequest, 
        continueSubscriptionRequest,
        customerPortalRequest
    } from "./Subscription.Sagas";

import { urlsRequest, 
        createClipRequest, 
        deleteClipRequest
    } from "./Clip.Sagas";

import{ createHelpRequest
    } from "./Help.Sagas";

export default function* rootSaga() {
    yield all([
        loginRequest(),
        googleLoginRequest(),
        signupRequest(),
        logoutRequest(),
        getInfoRequest(),
        changeInfoRequest(),
        deleteUserRequest(),
        getOtpRequest(),
        verifyOtpRequest(),
        changePasswordRequest(),

        defaultFolderRequest(),
        allFolderRequest(),
        createFolderRequest(),
        renameFolderRequest(),
        deleteFolderRequest(),

        searchRequest(),
        recordsRequest(),
        renameRecordRequest(),
        createRecordRequest(),
        deleteRecordRequest(),
        changeRecordFolderRequest(),

        recordRequest(),
        listRequest(),
        createPermissionRequest(),
        deletePermissionRequest(),

        createFeedbackRequest(),
        
        createHelpRequest(),

        createSubscriberRequest(),
        cancelSubscriptionRequest(),
        continueSubscriptionRequest(),
        customerPortalRequest(),

        urlsRequest(),
        createClipRequest(),
        deleteClipRequest()
    ]);
}

import { call, put, takeEvery } from "redux-saga/effects";
import * as ActionTypes from "../../constants/ActionTypes";
import { post } from "../../api/AxiosConfig";
import { CREATE_SUBSCRIBER_PATH, CANCEL_SUBSCRIPTION_PATH, CONTINUE_SUBSCRIPTION_PATH, CUSTOMER_PORTAL_PATH } from "../../constants/ApiPaths";

const msg = "Your session timed out";

function* createSubscriber(action) {
    try {
        const response = yield call(post, CREATE_SUBSCRIBER_PATH, null, action.payload);
        const data = response.data;
        if (response.status === 200) {
            const payload = data.Info;
            yield put({ type: ActionTypes.CREATE_SUBSCRIBER_SUCCESS, payload });
        } else if (response.status === 401) {
            yield put({ type: ActionTypes.LOGOUT_SUCCESS });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: msg } });
        } else {
            yield put({ type: ActionTypes.CREATE_SUBSCRIBER_FAILURE, data });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: data.status } });
        }
    } catch (error) {
        yield put({ type: ActionTypes.CREATE_SUBSCRIBER_FAILURE, payload: { error } });
        yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: "Please retry unable to open purchase screen" } });
    }
}

export function* createSubscriberRequest() {
    yield takeEvery(ActionTypes.CREATE_SUBSCRIBER, createSubscriber);
}

function* cancelSubscription(action) {
    try {
        const response = yield call(post, CANCEL_SUBSCRIPTION_PATH, null, action.payload);
        const data = response.data;
        if (response.status === 200) {
            const payload = data.Info;
            yield put({ type: ActionTypes.CANCEL_SUBSCRIPTION_SUCCESS, payload });
            yield put({ type: ActionTypes.TOAST_SUCCESS, payload: { type: "Success", message: data.status } });
        } else if (response.status === 401) {
            yield put({ type: ActionTypes.LOGOUT_SUCCESS });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: msg } });
        } else {
            yield put({ type: ActionTypes.CANCEL_SUBSCRIPTION_FAILURE, data });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: data.status } });
        }
    } catch (error) {
        yield put({ type: ActionTypes.CANCEL_SUBSCRIPTION_FAILURE, payload: { error } });
        yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: "Please retry unable to cancel subscription." } });
    }
}

export function* cancelSubscriptionRequest() {
    yield takeEvery(ActionTypes.CANCEL_SUBSCRIPTION, cancelSubscription);
}

function* continueSubscription(action) {
    try {
        const response = yield call(post, CONTINUE_SUBSCRIPTION_PATH, null, action.payload);
        const data = response.data;
        if (response.status === 200) {
            const payload = data.Info;
            yield put({ type: ActionTypes.CONTINUE_SUBSCRIPTION_SUCCESS, payload });
            yield put({ type: ActionTypes.TOAST_SUCCESS, payload: { type: "Success", message: data.status } });
        } else if (response.status === 401) {
            yield put({ type: ActionTypes.LOGOUT_SUCCESS });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: msg } });
        } else {
            yield put({ type: ActionTypes.CONTINUE_SUBSCRIPTION_FAILURE, data });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: data.status } });
        }
    } catch (error) {
        yield put({ type: ActionTypes.CONTINUE_SUBSCRIPTION_FAILURE, payload: { error } });
        yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: "Please retry unable to continue subscription." } });
    }
}

export function* continueSubscriptionRequest() {
    yield takeEvery(ActionTypes.CONTINUE_SUBSCRIPTION, continueSubscription);
}

function* customerPortal(action) {
    try {
        const response = yield call(post, CUSTOMER_PORTAL_PATH, null, action.payload);
        const data = response.data;
        if (response.status === 200) {
            const payload = data.Info;
            yield put({ type: ActionTypes.CUSTOMER_PORTAL_SUCCESS, payload });
        } else if (response.status === 401) {
            yield put({ type: ActionTypes.LOGOUT_SUCCESS });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: msg } });
        } else {
            yield put({ type: ActionTypes.CUSTOMER_PORTAL_FAILURE, data });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: data.status } });
        }
    } catch (error) {
        yield put({ type: ActionTypes.CUSTOMER_PORTAL_FAILURE, payload: { error } });
        yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: "Please refresh page unable to open portal!!!" } });
    }
}

export function* customerPortalRequest() {
    yield takeEvery(ActionTypes.CUSTOMER_PORTAL, customerPortal);
}

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addFolders, createFolder } from "../../redux/ActionCreator/Folders.ActionCreator";
import { changeRecordFolder } from "../../redux/ActionCreator/Records.ActionCreator";
import "./CreateFolder.Style.css";
import Select from "react-select";
import { toastError } from "../../redux/ActionCreator/Toast.ActionCreator";

function CreateFolderComponent(props) {
    const [folderName, setFolderName] = useState("");
    const [selectedFolder, setSelectedFolder] = useState("");
    const [totalFolders, setTotalFolder] = useState(0);
    const [options, setOptions] = useState([]);
    const dispatch = useDispatch();
    const folders = useSelector((state) => state.Folders.folders);
    const folderSaving = useSelector((state) => state.Folders.folderSaving);

    const handleFolderSelect = (folder) => {
        setSelectedFolder(folder.value);
    };

    const handleInputChange = (e) => {
        setFolderName(e.target.value);
    };

    const handleCreateClick = () => {
        if (folderName.length === 0) return;

        if(folderName.length>20)
        {
            const payload = {
                type: "Error",
                message: "Folder name should contains less then 20 characters",
            };
            dispatch(toastError(payload));
            return;
        }

        const payload = {
            foldername: folderName,
        };
        dispatch(createFolder(payload));
    };

    const handleMoveClick = () => {
        const payload = {
            recordId: props.recordId,
            newFolderName: selectedFolder,
        };

        if (!selectedFolder) {
            const payload = {
                type: "Error",
                message: "Please Select folder",
            };
            dispatch(toastError(payload));
            return;
        }

        dispatch(changeRecordFolder(payload));
    };

    const handleCancelClick = () => {
        props.setShowFolder(false);
    };

    useEffect(() => {
        if (!folders) {
            dispatch(addFolders());
            return;
        }
        const currentFolders = folders.length;
        if (totalFolders !== 0 && totalFolders < currentFolders) {
            setTotalFolder(0);
            props.setShowFolder(false);
        } else {
            setTotalFolder(currentFolders);
        }

        const option = folders
            .filter(function (folder) {
                if (folder.foldername === "recently added" || folder.foldername === "recently deleted" || folder.foldername === "favourite") {
                    return false;
                }
                return true;
            })
            .map((folder) => ({
                value: folder.foldername,
                label: folder.foldername,
            }));
        setOptions(option);
    }, [folders]);

    useEffect(() => {
        if (!folders) {
            dispatch(addFolders());
            return;
        }
        const option = folders
            .filter(function (folder) {
                if (folder.foldername === "recently added" || folder.foldername === "recently deleted" || folder.foldername === "favourite") {
                    return false;
                }
                return true;
            })
            .map((folder) => ({
                value: folder.foldername,
                label: folder.foldername,
            }));
        setOptions(option);
    }, []);

    const customStyles = {
        option: (defaultStyles, state) => ({
            ...defaultStyles,
            backgroundColor: state.isSelected ? "#a0a0a0" : "#fff",
            margin: "3px 5px 3px 8px",
            pading: "2px",
            "borderRadius": "12px",
            "maxWidth": "175px",
            overflow: "hidden",
            cursor: "pointer",
            "&:hover": {
                color: "#fff",
                "backgroundColor": "#467facc7",
            },
            color: "#141129",
        }),

        menuList: (base) => ({
            ...base,

            "::-webkit-scrollbar": {
                width: "7px",
                height: "0px",
                "border-radius": "15px",
            },
            "::-webkit-scrollbar-track": {
                background: "#f1f1f1",
                "border-radius": "15px",
            },
            "::-webkit-scrollbar-thumb": {
                background: "#888",
                "border-radius": "15px",
            },
            "::-webkit-scrollbar-thumb:hover": {
                background: "#555",
                "border-radius": "15px",
            },
        }),

        menu: (provided) => ({
            ...provided,
            width: "100%",
            "borderRadius": "8px",
            marginbottom: 0,
            boxshadow: "none",
            overflow: "hidden",
        }),

        control: (defaultStyles) => ({
            ...defaultStyles,
            backgroundColor: "#ffffff",
            border: "none",
            boxShadow: "none",
            "fontSize": "16px",
            "borderRadius": "10px",
            overflow: "hidden",
        }),
        singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#141129" }),
    };

    return (
        <div className="create-folder-wrapper">
            <div className="create-folder">
                <div className="input-container">
                    <span className="folder-icon"></span>
                    {props.type === "create" ? (
                        <input type="text" placeholder="Enter folder name" value={folderName} onChange={handleInputChange} className="folder-name-field" />
                    ) : (
                        <div className="wrap-dropdown">
                            <Select onChange={handleFolderSelect} className="folder-dropdown" options={options} styles={customStyles} />
                        </div>
                    )}
                </div>

                <div>
                    <button className="cancel-folder-button" onClick={handleCancelClick}>
                        Cancel
                    </button>
                    {props.type === "create" ? (
                        <button className="create-folder-button" onClick={handleCreateClick} disabled = {folderSaving}>
                            {folderSaving ? 'Creating...' : 'Create'}
                        </button>
                    ) : (
                        <button className="create-folder-button" onClick={handleMoveClick} disabled = {folderSaving}>
                            {folderSaving ? 'Moving...' : 'Move'}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}

export default CreateFolderComponent;

export const InitialState = {
    Auth: {
        isLoading: false,
        isAuthenticated: true,
        isOtpRetrived: false,
        isPasswordChanged: false,
        token: 1,
        errMess: null,
        Info: { name: "0" },
    },
    ErrorMess: { errMess: "0" },
    Records: { isLoading: true,
        errMess: null,
        records: {},
        isOpen: false,
        isDelete: false
    },
    Feedbacks: { isSubmitting: false,
        errMess: null,
        feedbacks: []
    },
    Help: { isSubmitting: false,
        errMess: null
    },
    Share: { isLoading: true,
        isSharing: false,
        isUnSharing: false,
        errMess: null,
        record: null,
        list: []
    },
    Folders: { isLoading: true,
        errMess: null,
        folderSaving: false,
        folders: []
    },
    Subscription: { checkoutSession: null,
        isCancelling: false,
        subscriptionType: process.env.REACT_APP_BASIC_SUBSCRIPTION,
        canceled: process.env.REACT_APP_BASIC_SUBSCRIPTIONSTATUS_INACTIVE,
        customerPortalSession: null,
        nextPaymentDate: null
    },
    Clip: { isLoading: true,
        isClipping: false,
        isUnClipping: false,
        errMess: null,
        urls: []
    },
    Toast: { message: null, type: null, update: null },
};

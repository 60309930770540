import "./HomePageBody.Style.css";
import { Link } from "react-router-dom";
import CortonaComponent from "./Animation/Cortona.Component";
import HeaderComponent from "./Header/Header.Component";
import FooterComponent from "./Footer/Footer.Component";

function HomePageBody(props) {
    return (
        <div className="homepage-component">
            <HeaderComponent />
            <section className="HeomePageBodyComponetDiv">
                <video className='videoTag' autoPlay loop muted>
                    <source src='backgroundvideo.mp4' type='video/mp4' />
                </video>
                <div className="Intro">
                    <h1 className="intromsg">Record Memories Anytime</h1>
                    <div className="introalign">
                        <div className="introtxt">Capture, Share and Amplify your stories with Echo</div>
                        <Link className="introbutton" to="/auth" state={{ method: "signup" }}>
                            Register
                        </Link>
                        <p className="introtxtsmall">Immerse Yourself in Stories</p>
                    </div>
                </div>
            </section>
            <div className="website-information-title">Record any time with ease</div>
            <div className="website-information-subtitle">Record the moment with few clicks and share it with anyone easily</div>
            <section className="website-information">
                <div className="homepage-information-audiocomponent">
                    <CortonaComponent />
                </div>
                <div className="homepage-description-audiocomponent">
                    Immerse yourself in the art of capturing cherished moments, allowing the genuine emotion in your voice to weave through each instance. With just a touch, seize the essence of these
                    special times, and effortlessly share the magic with those who matter most.
                </div>
            </section>
            <div className="website-information-title">Clip the records</div>
            <div className="website-information-subtitle">Record with the help of our extension and clip it to the page</div>
            <section className="website-information website-information-alternative">
                <div className="homepage-description-audiocomponent">
                With just a click, capture your thoughts on any webpage, making it easier to share and remember later. Whether you're 
                explaining a concept to a friend or revisiting a page for yourself, hearing your own voice adds a personal touch to your 
                browsing experience. Say goodbye to endless note-taking and hello to effortless browsing, all in your own words.
                </div>
                <div className="homepage-information-audiocomponent homepage-information-img">
                    <img src="homepageextdemo.png" className="website-information-ext-demo" alt="Record Extension Demo"/>
                </div>
            </section>
            <div className="echovault-features">
                <div className="website-information-title">Supported Features</div>
                <div className="features-list">
                    <span className="feature">
                        <span className="feature-record-icon feature-icon"></span>Audio recording
                    </span>
                    <span className="feature">
                        <span className="feature-download-icon feature-icon"></span>Download files
                    </span>
                    <span className="feature">
                        <span className="feature-storage-icon feature-icon"></span>Record Storage
                    </span>
                    <span className="feature">
                        <span className="feature-share-icon feature-icon"></span>Recorded audio sharing
                    </span>
                    <span className="feature">
                        <span className="feature-extension-icon feature-icon"></span>Chrome Extension
                    </span>
                </div>
            </div>
            <FooterComponent />
        </div>
    );
}

export default HomePageBody;

import * as ActionTypes from "../../constants/ActionTypes";
import { InitialState } from "../../constants/InitialStates";

export const Folders = (state = InitialState.Folders, action) => {
    switch (action.type) {
        case ActionTypes.ADD_FOLDER:
            return { ...state, isLoading: true };
        case ActionTypes.ADD_FOLDER_SUCCESS: {
            return { ...state, isLoading: false, errMess: null, folders: action.folders };
        }
        case ActionTypes.ADD_FOLDER_FAILURE:
            return { ...state, isLoading: false, errMess: action.message };
        case ActionTypes.FOLDERS_LOADING:
            return { ...state, isLoading: true, errMess: null, folders: [] };
        case ActionTypes.FOLDERS_FAILURE:
            return { ...state, isLoading: false, errMess: action.payload, folders: [] };
        case ActionTypes.RENAME_FOLDER: {
            return { ...state };
        }
        case ActionTypes.RENAME_FOLDER_SUCCESS: {
            const folders = state.folders;
            for (let i = 0; i < folders.length; i++) {
                if (folders[i].id === action.payload.folderId) {
                    folders[i].foldername = action.payload.folderName;
                    break;
                }
            }
            return { ...state, errMess: null, folders: [...folders] };
        }
        case ActionTypes.RENAME_FOLDER_FAILURE: {
            return { ...state };
        }
        case ActionTypes.DELETE_FOLDER: {
            return { ...state };
        }
        case ActionTypes.DELETE_FOLDER_SUCCESS: {
            const folders = state.folders;
            const folderName = action.payload.folderName;
            const updatedFolders = folders.filter((folder) => !(folder.foldername === folderName));
            return { ...state, errMess: null, folders: [...updatedFolders] };
        }
        case ActionTypes.DELETE_FOLDER_FAILURE: {
            return { ...state, errMess: action.payload };
        }
        case ActionTypes.DEFAULT_FOLDERS:
            return { ...state, isLoading: true };
        case ActionTypes.DEFAULT_FOLDERS_SUCCESS:
            return { ...state, isLoading: false, folders: [...state.folders, ...action.folders] };
        case ActionTypes.DEFAULT_FOLDERS_FAILURE:
            return { ...state, isLoading: false, errMess: action.message };
        case ActionTypes.CREATE_FOLDER: {
            return { ...state, folderSaving: true };
        }
        case ActionTypes.CREATE_FOLDER_SUCCESS: {
            return { ...state,folderSaving: false,  folders: [...state.folders, action.payload] };
        }
        case ActionTypes.CREATE_RECORD_FAILURE: {
            return { ...state, folderSaving: false, errMess: action.payload };
        }
        case ActionTypes.CHANGE_RECORD_FOLDER: {
            return { ...state, folderSaving: true };
        }
        case ActionTypes.CHANGE_RECORD_FOLDER_SUCCESS: {
            const folders = state.folders;
            let count = 0;
            for (let i = 0; i < folders.length; i++) {
                if (count === 2) {
                    break;
                }
                if (folders[i].foldername === action.payload.oldfoldername) {
                    folders[i].totalfiles = folders[i].totalfiles - 1;
                    folders[i].totalsize = action.payload.oldfoldersize;
                    count = count + 1;
                }
                if (folders[i].foldername === action.payload.newfoldername) {
                    folders[i].totalfiles = folders[i].totalfiles + 1;
                    folders[i].totalsize = action.payload.newfoldersize;
                    count = count + 1;
                }
            }
            return { ...state, folderSaving: false, folders: folders };
        }
        case ActionTypes.CHANGE_RECORD_FOLDER_FAILURE: {
            return { ...state, folderSaving: false };
        }
        case ActionTypes.DELETE_RECORD_SUCCESS: {
            const folders = state.folders;
            for (let i = 0; i < folders.length; i++) {
                if (folders[i].id === action.payload[0].folderid) {
                    folders[i].totalfiles = folders[i].totalfiles - 1;
                    folders[i].totalsize = folders[i].totalsize - action.payload[0].size;
                    break;
                }
            }
            return { ...state, folders: folders };
        }
        case ActionTypes.CREATE_RECORD_SUCCESS: {
            const folders = state.folders;
            for (let i = 0; i < folders.length; i++) {
                if (folders[i].id === action.payload.folderid) {
                    folders[i].totalfiles = folders[i].totalfiles + 1;
                    folders[i].totalsize = folders[i].totalsize + action.payload.size;
                    break;
                }
            }
            return { ...state, folders: folders };
        }
        case ActionTypes.LOGOUT_SUCCESS:
            return { errMess: null, folders: [] };
        case ActionTypes.DELETE_PROFILE_SUCCESS:
            return { errMess: null, folders: [] };
        default:
            return state;
    }
};

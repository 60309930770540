import React from "react";
import './Policy.Style.css';
import HeaderComponent from "../Header/HeaderRegistrationPage.Component";
import FooterComponent from "../Footer/Footer.Component";
import { Subscription_Feature_Switch } from "../../constants/FeatureSwitch";
import SeoComponent from "../seo/Seo.Component";

function PrivacyPolicyComponent(props) {
    
    return (
        <div className="policy-wrapper">
            <SeoComponent
                title = 'Privacy policy'
                description = 'Echovault - Privacy policy'
                name = 'Echovault'
                type = 'website' />
            <HeaderComponent redirectTo={"/auth"} noredirect={true}/>
            <div className="privacy-policy-componenet">
                <h1 className="policy-title">Privacy Policy</h1>
                <p className="policy-subsection-information">Effective: March 31, 2024</p>
                <p className="policy-subsection-information">This Privacy Policy outlines how Echovault collects, utilizes, and shares your personal information, along with detailing 
                    your choices and rights concerning your personal information. This Privacy Policy pertains to data concerning you as an identifiable individual that Echovault acquires 
                    or gathers during your engagement with us or our offerings, website, and software (referred to as the "Services"). The Echovault website is a software as a service site. 
                    By using the Echovault website, you consent to the data practices described in this statement. 
                </p>
                <div className="policy-subsection">
                    {/*section 1*/}
                    <h2 className="policy-subsection-heading">Information Collection</h2>
                    <p className="policy-subsection-information">
                    <strong className="policy-subsection-subtopic">Account Information:</strong> In order to set up an account for the Services or activate specific features, it is necessary for you to furnish account information, including 
                        your name, email, password, and authentication credentials. {Subscription_Feature_Switch && <>If you opt for a paid subscription, we (or our payment processors) may necessitate your billing particulars, 
                        such as credit card information, banking details, and billing address.</>}
                    </p>

                    <p className="policy-subsection-information">
                    <strong className="policy-subsection-subtopic">Audio and Other Customer Data:</strong> In using our Services, our customers submit or upload audio recordings, seek user support, or provide other Customer Data (defined in 
                        our Terms of Service) to us.
                    </p>
                    
                    <p className="policy-subsection-information">
                    <strong className="policy-subsection-subtopic">Usage Data:</strong> We automatically collect usage data about how you interact with our Services when you use them. For example, this could be actions you take on our platform, 
                        such as your sharing activity.
                    </p>
                    
                    <p className="policy-subsection-information">
                    <strong className="policy-subsection-subtopic">Third-Party Integrations:</strong> We automatically collect usage data about how you interact with our Services when you use them. For example, this could be actions you take on our platform, 
                        such as your sharing activity.
                    </p>

                    {/* section 2*/}
                    <h2 className="policy-subsection-heading">How We Use Your Information</h2>
                    <p className="policy-subsection-information">
                        To provide, maintain and improve our Services.
                    </p>
                    <p className="policy-subsection-information">
                        To keep our Services secure and protect against fraud, abuse, and intrusion.
                    </p>
                    <p className="policy-subsection-information">
                        To provide user support, information, and services requested by you.
                    </p>
                    <p className="policy-subsection-information">
                        To comply with our legal obligations, including responding to a court order or other valid legal process.
                    </p>
                    <p className="policy-subsection-information">
                        For other purposes with your consent.
                    </p>

                    <p className="policy-subsection-information">
                        Please keep in mind that customers control their accounts and associated Customer Data. We use Customer Data according to customer's instructions and our Terms and 
                        conditions. Customers are able to: (1) restrict, remove, disclose, and access content and information associated with the account; (2) grant, deny, or limit access 
                        to those accounts.
                    </p>

                    {/* section 3*/}
                    <h2 className="policy-subsection-heading">Sharing Information with Third Parties</h2>
                    <p className="policy-subsection-information">
                        Echovault does not sell, rent or lease its customer lists to third parties. We disclose information to our service providers or other third-parties so they can help us 
                        provide our Services and run our business. Examples include for storing Customer Data, payment processing, providing customer service. We'll only disclose the information 
                        necessary for these parties to perform their services for us.
                    </p>
                    
                    {/* section 4*/}
                    <h2 className="policy-subsection-heading">How We Protect Your Information</h2>
                    <p className="policy-subsection-information">
                        We are committed to protecting your information from unauthorized access, use, disclosure, and loss. We use industry-standard security practices to keep your information 
                        secure, such as encryption, access controls, physical security measures, and internal reviews of data collection, use, and storage.
                    </p>
                    <p className="policy-subsection-information">
                        However, data transmissions over the internet cannot be guaranteed to be 100% secure or safe from intrusion by others. Be sure to use secure internet connections, protect 
                        your login credentials, and create strong passwords for your account.
                    </p>

                    {/* section 4*/}
                    <h2 className="policy-subsection-heading">Data Retention</h2>
                    <p className="policy-subsection-information">
                        We'll retain information you store on our Services for as long as your account exists or as long as we need it to provide you Services. If you delete your account or your 
                        content from Echovault, we'll permanently delete your account, unless we need to retain any information to comply with our legal obligations, resolve disputes, or enforce 
                        our agreements. For any other information we may receive or collect from you, we'll retain that information for only as long as is necessary for the purposes described in 
                        this Privacy Policy.
                    </p>

                    {/* section 5*/}
                    <h2 className="policy-subsection-heading">Use of Cookies</h2>
                    <p className="policy-subsection-information">
                        The Echovault website may use “cookies” to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies 
                        cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the 
                        cookie to you.
                    <br/>
                    <br/>
                        One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific 
                        page. For example, if you personalize Echovault pages, or register with Echovault site or services, a cookie helps Echovault to recall your specific information on subsequent visits. 
                        This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same Echovault website, the 
                        information you previously provided can be retrieved, so you can easily use the Echovault features that you customized.
                    <br/>
                    <br/>
                        You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. 
                        If you choose to decline cookies, you may not be able to fully experience the interactive features of the Echovault services or websites you visit.
                    </p>

                    {/* section 6*/}
                    <h2 className="policy-subsection-heading">Children Under Thirteen</h2>
                    <p className="policy-subsection-information">
                        If you are under 13 years old (or the age of digital consent in your country), you may not sign up for Echovault, and please do not send any personal information about 
                        yourself to Echovault. Echovault does not knowingly collect personally identifiable information from children under the age of thirteen.
                    </p>

                    {/* section 7*/}
                    <h2 className="policy-subsection-heading">Changes to this Statement</h2>
                    <p className="policy-subsection-information">
                        We may update this Privacy Policy by posting the updates to our website. If an update materially impacts your rights or how we use your personal information, we will notify 
                        you either by email or other direct communication before the updates take effect. Any other revisions will become effective on the date the updates are posted by Echovault.
                    </p>
                </div>
            </div>
            <FooterComponent/>
        </div>
    );
}

export default PrivacyPolicyComponent;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./ShareForm.Style.css";
import { toastError } from "../../redux/ActionCreator/Toast.ActionCreator";
import { createPermission, deletePermission } from "../../redux/ActionCreator/Share.ActionCreator";
import LoadingComponent from "../Loading/LoadingGeneral.Component";
import { Subscription_Feature_Switch } from "../../constants/FeatureSwitch";

export default function ShareDisplayComponent(props) {
    const [emailToShare, setemailToShare] = useState("");
    const [hoveredEmail, setHoveredEmail] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const list = useSelector((state) => state.Share.list);
    const isSharing = useSelector((state) => state.Share.isSharing);
    const isUnSharing = useSelector((state) => state.Share.isUnSharing);
    const subscriptionType = useSelector((state) => state.Subscription.subscriptionType);

    const handleInputChange = (e) => {
        setemailToShare(e.target.value);
    };

    const handleCloseShareClick = () => {
        props.setIsShareOpen(false);
    };

    const toPascalCase = (str) => {
        if (!str) {
            return str;
        }
        return str.replace(/\w+/g, function (word) {
            return word.charAt(0).toUpperCase() + word.slice(1);
        });
    };

    const shareRecord = () => {
        if(isSharing)
        {
            return;
        }
        if(Subscription_Feature_Switch && 
        subscriptionType === process.env.REACT_APP_BASIC_SUBSCRIPTION && list.length>=1)
        {
            const payload = {
                type: "Error",
                message: "Upgrade plan to increase sharing limit",
            };
            dispatch(toastError(payload));
            return navigate('/pricing');
        }
        if (!emailToShare.length) {
            const payload = {
                type: "Error",
                message: "Please enter valid email",
            };
            dispatch(toastError(payload));
            return;
        }

        const payload = {
            recordId: props.recordId,
            user: emailToShare,
        };
        dispatch(createPermission(payload));
    };

    const unshareWithPerson = (email) => {
        const payload = {
            recordId: props.recordId,
            user: email,
        };
        dispatch(deletePermission(payload));
    };

    return (
        <div className="create-share-wrapper">
            {isUnSharing && <LoadingComponent loading="Permission Removing" />}
            <div className="share-input-wrapper">
                <button className="close-create-share" onClick={handleCloseShareClick}></button>
                <div className="create-share">
                    <input type="text" placeholder="Enter registered email address" value={emailToShare} onChange={handleInputChange} className="share-email-field" />
                    <button className="create-share-button" onClick={shareRecord} disabled = {isSharing}>
                    {isSharing ? 'Sharing' : 'Share'}
                    </button>
                </div>
                <div className="share-people-list">
                    <div className="create-share-title">Shared With</div>
                    <div className="create-share-people-list">
                        {list.map((sharedWith) => {
                            return (
                                <div className="create-shared-with-person" key={sharedWith.email} onMouseEnter={() => setHoveredEmail(sharedWith.email)} onMouseLeave={() => setHoveredEmail(null)}>
                                    <span className="record-shared-with-name">{sharedWith.name && toPascalCase(sharedWith.name)}</span>
                                    <button
                                        className="record-delete-button"
                                        onClick={() => {
                                            unshareWithPerson(sharedWith.email);
                                        }}
                                    >
                                        Remove
                                    </button>
                                    {hoveredEmail && hoveredEmail === sharedWith.email && (
                                        <div className={`email-tooltip ${sharedWith.email === list[list.length - 1].email ? "last-position" : ""}`}>
                                            <div className={`email-tooltip-email ${sharedWith.email === list[list.length - 1].email ? "last-position-email" : ""}`}>{sharedWith.email}</div>
                                            <div className={`tooltip-arrow ${sharedWith.email === list[list.length - 1].email ? "last-position-arrow" : ""}`}></div>
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}

import React, { useState, useRef, useEffect } from "react";
import PostRecordingViewerComponent from "./PostRecordingViewer.Component";
import AudioAnalyzer from "./AudioAnalyser.Component";
import { useDispatch, useSelector } from "react-redux";
import { createRecord, recordsLoading } from "../../redux/ActionCreator/Records.ActionCreator";
import { BsFillPlayCircleFill, BsFillPauseCircleFill } from "react-icons/bs";
import LoadingComponent from "../Loading/LoadingGeneral.Component";
import "./Recorder.Style.css";
import { toastError } from "../../redux/ActionCreator/Toast.ActionCreator";
import { Subscription_Feature_Switch } from "../../constants/FeatureSwitch";
import RecordRTC from "recordrtc";

function AudioRecorder(props) {
    const [recordingStage, setRecordingStage] = useState(0);
    const [totalRecords, setTotalRecord] = useState(0);
    const [stream, setStream] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [levelData, setLevelData] = useState([]);
    const [audio, setAudio] = useState(null);
    const [audioBlob, setAudioBlob] = useState(null);
    const [defaultDuration, setDefaultDuration] = useState(0);
    const [prevSec, setPrevSec] = useState(0);
    const [isEditing, setIsEditing] = useState(0);
    const [forceStop, setForceStop] = useState(false);
    const [editedName, setEditedName] = useState("Enter Record Name");

    const subscriptionType = useSelector((state) => state.Subscription.subscriptionType);

    const dispatch = useDispatch();

    const inputRef = useRef(null);
    const timeInterval = useRef(null);
    const audioPlayer = useRef();
    const mediaRecorder = useRef(null);
    const isStopped = useRef(false);

    const folders = useSelector((state) => state.Folders.folders);
    const records = useSelector((state) => state.Records.records);
    const isOpen = useSelector((state) => state.Records.isOpen);

    const mimeType = "audio/webm";
    var commonRecordsId = null;

    var time;

    (() => {
        if (navigator.mediaDevices === undefined) {
            navigator.mediaDevices = {};
        }

        if (navigator.mediaDevices.getUserMedia === undefined) {
            navigator.mediaDevices.getUserMedia = function (constrains) {
                var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
                if (!getUserMedia) {
                    const payload = {
                        type: "Error",
                        message: "Please allow microphone access.",
                    };
                    dispatch(toastError(payload));
                    return;
                }

                return new Promise(function (resolve, reject) {
                    getUserMedia.call(navigator, constrains, resolve, reject);
                });
            };
        }
    })();

    function parseTime(sec) {
        var h = parseInt(sec / 3600);
        var m = parseInt(sec / 60);
        sec = sec - (h * 3600 + m * 60);

        h = h === 0 ? "" : h + ":";
        sec = sec < 10 ? "0" + sec : sec;

        return h + m + ":" + sec;
    }

    function start() {
        navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then(function (stream) {
                window.streamReference = stream;
                setStream(stream);

                const options = {
                    disableLogs: true,
                    type: "audio",
                    mimeType: 'audio/webm',
                    bufferSize: 16384,
                    desiredSampRate: 16000,
                    numberOfAudioChannels: 1,
                };

                mediaRecorder.current = new RecordRTC.StereoAudioRecorder(stream, options);
        
                mediaRecorder.current.record();

                const timeButton = document.getElementById("time");
                time = new Date().getTime();
                timeButton.innerHTML = "00:00";
                const updateTime = () => {
                    const now = new Date().getTime();
                    setDefaultDuration(now - time);
                    const elapsedSeconds = Math.floor((now - time) / 1000);

                    if (elapsedSeconds - prevSec <= 0) return;

                    setPrevSec(elapsedSeconds);
                    timeButton.innerHTML = parseTime(elapsedSeconds);
                    if(Subscription_Feature_Switch && subscriptionType === process.env.REACT_APP_BASIC_SUBSCRIPTION && elapsedSeconds>=300)
                    {
                        setForceStop(true);
                        const payload = {
                            type: "Error",
                            message: "Upgrade plan to support larger audio recordings",
                        };
                        dispatch(toastError(payload));
                    }

                    if(!Subscription_Feature_Switch && elapsedSeconds>=300)
                    {
                        setForceStop(true);
                        const payload = {
                            type: "Error",
                            message: "The maximum duration for a record is 5 minutes",
                        };
                        dispatch(toastError(payload));
                    }
                };
                timeInterval.current = setInterval(updateTime);
            })
            .catch(function (error) {
                const payload = {
                    type: "Error",
                    message: "Please allow microphone access inside settings.",
                };
                dispatch(toastError(payload));
            });
    }

    function stop() {
        clearInterval(timeInterval.current);
        mediaRecorder.current.stop((blob) => {
            const audioBlob = blob;
            const audioUrl = URL.createObjectURL(audioBlob);
            setAudioBlob(audioBlob);
            setAudio(audioUrl);
            setPrevSec(0);
            isStopped.current = true;
            window.streamReference.getAudioTracks().forEach((track) => track.stop());
        });
    }

    const handleRecordClick = () => {
        setRecordingStage(1);
        start();
    };

    useEffect(()=>{
        if(forceStop)
        {
            handleStopRecordingClick();
            setForceStop(false);
        }
    },[forceStop]);

    const handleStopRecordingClick = () => {
        if (defaultDuration === 0) {
            return;
        }
        if (mediaRecorder) {
            setRecordingStage(2);
            stop();
            setIsEditing(true);
        }
    };

    const handleDeleteRecordingClick = () => {
        if(isPlaying)
        {
            handlePlayPauseClick();
        }
        props.setShowRecorder(false);
    };

    const handlePlayPauseClick = () => {
        setIsPlaying(!isPlaying);
    };

    const handleSaveRecordingClick = async () => {
        if(!isStopped.current)
        {
            return;
        }

        if(isPlaying)
        {
            handlePlayPauseClick();
        }

        folders?.forEach((folderObj) => {
            if (folderObj.foldername === "recently added") {
                commonRecordsId = folderObj.id;
            }
        });

        if(editedName.length>25)
        {
            const payload = {
                type: "Error",
                message: "Record names should be less than 25 characters.",
            };
            dispatch(toastError(payload));
            return;
        }

        const formData = new FormData();

        const audioFile = new File([audioBlob], "filename.wav", { type: mimeType });
        formData.append("audio", audioFile);
        formData.append("folderId", commonRecordsId);
        formData.append("recordName", editedName);
        formData.append("seconds", defaultDuration);
        formData.append("load", records["recently added"] ? false : true);
        const Info = {
            load: records["recently added"] ? false : true,
            folderName: "recently added",
            folderId: commonRecordsId,
        };
        const payload = {
            formData: formData,
            Info: Info,
        };
        if (Info.load) {
            const data = {
                folderName: "recently added",
                id: commonRecordsId,
            };
            await new Promise((resolve) => {
                dispatch(recordsLoading(data));
                resolve();
            });
        }
        dispatch(createRecord(payload));
    };

    useEffect(() => {
        if (records["recently added"]) {
            const currentRecords = records["recently added"].length;
            if ((totalRecords === -1 && currentRecords > 0) || (totalRecords > 0 && totalRecords < currentRecords)) {
                setTotalRecord(0);
                props.setShowRecorder(false);
            } else {
                if (currentRecords === 0) {
                    setTotalRecord(-1);
                } else {
                    setTotalRecord(currentRecords);
                }
            }
        }
    }, [records]);

    const handleEditNameClick = () => {
        setIsEditing(true);
    };

    useEffect(() => {
        if (isEditing && inputRef.current) {
            inputRef.current.focus();
            inputRef.current.select();
        }
    }, [isEditing]);

    const handleRename = async () => {
        setIsEditing(false);
    };

    const handleCloseRecordingClick = () => {
        props.setShowRecorder(false);
    };

    return (
        <div className="RecorderComponentDiv">
            {isOpen && <LoadingComponent loading="Uploading" />}
            {recordingStage === 0 ? (
                <div>
                    <button className="close-recording" onClick={handleCloseRecordingClick}></button>
                    <div className="upper" onClick={handleRecordClick}>
                        Click the button to start recording...
                    </div>
                </div>
            ) : recordingStage === 1 ? (
                <div className="upper recording-animation">
                    {stream ? <AudioAnalyzer audio={stream} setLevelData={setLevelData} levelData={levelData} /> : ""}
                    Recording...
                </div>
            ) : (
                <div className="upper playing-animation">
                    <div>
                        {isEditing ? (
                            <input ref={inputRef} type="text" value={editedName} onChange={(e) => setEditedName(e.target.value)} onBlur={handleRename} className="recorded-audio-name-input" />
                        ) : (
                            <div className="recorded-audio-name">
                                <div className="recorded-audio-name-text">{editedName}</div>
                                <button className="edit-recorded-name" onClick={handleEditNameClick}></button>
                            </div>
                        )}
                    </div>
                    <PostRecordingViewerComponent
                        ref={audioPlayer}
                        src={audio}
                        isPlaying={isPlaying}
                        setIsPlaying={setIsPlaying}
                        levelData={levelData}
                        maxBarHeight={60}
                        defaultDuration={defaultDuration / 1000}
                    />
                </div>
            )}

            {recordingStage === 0 ? (
                <button className="record_btn" onClick={handleRecordClick}></button>
            ) : recordingStage === 1 ? (
                <div className="recording">
                    <button className="stop-recording" onClick={handleStopRecordingClick}></button>
                    <span className="recordin-time" id="time">
                        00:00
                    </span>
                </div>
            ) : (
                <div className="playing">
                    <button className="player delete-record" onClick={handleDeleteRecordingClick}></button>
                    {isPlaying ? (
                        <BsFillPauseCircleFill className="pause play-pause-recording" onClick={handlePlayPauseClick} />
                    ) : (
                        <BsFillPlayCircleFill className="pause play-pause-recording" onClick={handlePlayPauseClick} />
                    )}
                    <button className="player save-record" onClick={handleSaveRecordingClick} disabled = {!isStopped.current}></button>
                </div>
            )}
        </div>
    );
}

export default AudioRecorder;

import React from "react";
import "./LoadingUsed.Style.css";

const LoadingComponent = () => {
    return (
        <div className="loading-component">
            <div className="loading-container-used">
                <ul className="loading-bars">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>
        </div>
    );
};

export default LoadingComponent;

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { HiDotsCircleHorizontal } from "react-icons/hi";
import { deleteFolder, renameFolder } from "../../redux/ActionCreator/Folders.ActionCreator";
import { toastError } from "../../redux/ActionCreator/Toast.ActionCreator";
import "./FolderDisplay.style.css";

export default function FolderDisplayComponent(props) {
    const [isDropdownButton, setDropdownButton] = useState(false);
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [editedName, setEditedName] = useState(props.folderName);
    const [prevName, setPrevName] = useState(props.folderName);
    const [isEditing, setIsEditing] = useState(false);

    const inputRef = useRef(null);
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const folders = useSelector((state) => state.Folders.folders);

    const handleRename = () => {
        setIsEditing(false);
        let flag = 1;

        for (let i = 0; i < folders.length; i++) {
            if (folders[i].foldername === editedName) {
                flag = 0;
                break;
            }
        }

        if (flag === 0) {
            setEditedName(prevName);
            const payload = {
                type: "Error",
                message: "Please Enter unique folder name.",
            };
            dispatch(toastError(payload));
        } else {
            const payload = {
                folderId: props.folderId,
                folderName: editedName,
            };
            dispatch(renameFolder(payload));
            setPrevName(editedName);
        }
    };

    const handleDelete = () => {
        const payload = {
            folderName: editedName,
        };
        dispatch(deleteFolder(payload));
    };

    const toggleDropdown = (visibility) => {
        setDropdownVisible(visibility);
    };

    const toggleButton = (visibility) => {
        setDropdownButton(visibility);
        if (!visibility) {
            toggleDropdown(visibility);
        }
    };

    useEffect(() => {
        if (isEditing) {
            inputRef.current.focus();
            inputRef.current.select();
        }
    }, [isEditing]);

    const openFolder = () => {
        navigate(`/record/folder/${props.folderId}`);
    };

    const calculateFormat = () => {
        const size = props.totalSize;
        if(!size)
        return "0.00 KB";;
        if (size < 1024) {
            return size.toFixed(2) + " KB";
        } else if (size < 1024 * 1024) {
            return (size / 1024).toFixed(2) + " MB";
        } else if (size < 1024 * 1024 * 1024) {
            return (size / (1024 * 1024)).toFixed(2) + " GB";
        } else {
            return (size / (1024 * 1024 * 1024)).toFixed(2) + " TB";
        }
    };

    return (
        <div className="folderDisplay" onMouseEnter={() => toggleButton(true)} onMouseLeave={() => toggleButton(false)} onClick={() => openFolder()}>
            <div className="folderContent">
                <span className="folder-icon-empty"></span>
                {isEditing ? (
                    <input
                        ref={inputRef}
                        type="text"
                        value={editedName}
                        onChange={(e) => setEditedName(e.target.value)}
                        onBlur={handleRename}
                        className="edit-input-folder"
                        style={{ background: "none", border: "none", width: "100%", textAlign: "left" }}
                    />
                ) : (
                    <div className="folder-name">{editedName}</div>
                )}
            </div>
            {isDropdownButton && (
                <HiDotsCircleHorizontal
                    onClick={(e) => {
                        e.stopPropagation();
                        toggleDropdown(!isDropdownVisible);
                    }}
                    alt="Options"
                    className="options-icon-folder"
                />
            )}

            {isDropdownVisible && (
                <div className="dropdown-folder">
                    <ul>
                        <li
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsEditing(!isEditing);
                                toggleDropdown(false);
                            }}
                        >
                            Rename
                        </li>
                        <li
                            onClick={(e) => {
                                e.stopPropagation();
                                handleDelete();
                                toggleDropdown(false);
                            }}
                        >
                            Delete
                        </li>
                    </ul>
                </div>
            )}
            <div className="folderDetails">
                <div className="totalFiles">{props.totalFiles} record</div>
                <div className="totalSize">{calculateFormat()}</div>
            </div>
        </div>
    );
}

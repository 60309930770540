import * as ActionTypes from "../../constants/ActionTypes";

export const addRecords = (records) => ({
    type: ActionTypes.ADD_RECORDS,
    payload: records,
});

export const recordsLoading = (data) => ({
    type: ActionTypes.RECORDS_LOADING,
    data,
});

export const recordsSuccess = (records) => ({
    type: ActionTypes.RECORDS_SUCCESS,
    records,
});

export const recordsFailed = (message) => ({
    type: ActionTypes.RECORDS_FAILURE,
    message,
});

export const searchLoading = (data) => ({
    type: ActionTypes.SEARCH_LOADING,
    data,
});

export const searchsSuccess = (records) => ({
    type: ActionTypes.SEARCH_SUCCESS,
    records,
});

export const searchFailed = (message) => ({
    type: ActionTypes.SEARCH_FAILURE,
    message,
});

export const renameRecord = (payload) => ({
    type: ActionTypes.RENAME_RECORD,
    payload,
});

export const renameRecordSuccess = (message) => ({
    type: ActionTypes.RENAME_RECORD,
    message,
});

export const renameRecordFailure = (message) => ({
    type: ActionTypes.RENAME_RECORD,
    message,
});

export const createRecord = (payload) => ({
    type: ActionTypes.CREATE_RECORD,
    payload,
});

export const createRecordSuccess = (message) => ({
    type: ActionTypes.CREATE_RECORD,
    message,
});

export const createRecordFailure = (message) => ({
    type: ActionTypes.CREATE_RECORD,
    message,
});

export const deleteRecord = (payload) => ({
    type: ActionTypes.DELETE_RECORD,
    payload,
});

export const deleteRecordSuccess = (message) => ({
    type: ActionTypes.DELETE_RECORD_SUCCESS,
    message,
});

export const deleteRecordFailure = (message) => ({
    type: ActionTypes.DELETE_RECORD_FAILURE,
    message,
});

export const changeRecordFolder = (payload) => ({
    type: ActionTypes.CHANGE_RECORD_FOLDER,
    payload,
});

export const changeRecordFolderSuccess = (message) => ({
    type: ActionTypes.CHANGE_RECORD_FOLDER_SUCCESS,
    message,
});

export const changeRecordFolderFailure = (message) => ({
    type: ActionTypes.CHANGE_RECORD_FOLDER_FAILURE,
    message,
});

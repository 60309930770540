import { call, put, takeEvery } from "redux-saga/effects";
import * as ActionTypes from "../../constants/ActionTypes";
import { get, post, putApi, deleteApi } from "../../api/AxiosConfig";
import { DEFAULT_FOLDER_PATH, CREATE_FOLDER_PATH, GET_ALL_FOLDERS_PATH, RENAME_FOLDER_PATH, DELETE_FOLDER_PATH } from "../../constants/ApiPaths";

const msg = "Your session timed out";

function* defaultFolders() {
    try {
        const response = yield call(get, DEFAULT_FOLDER_PATH);
        const data = response.data;
        if (response.status === 200) {
            const folders = data.Info;
            yield put({ type: ActionTypes.DEFAULT_FOLDERS_SUCCESS, folders });
        } else if (response.status === 401) {
            yield put({ type: ActionTypes.LOGOUT_SUCCESS });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: msg } });
        } else {
            yield put({ type: ActionTypes.DEFAULT_FOLDERS_FAILURE, data });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: data.message } });
        }
    } catch (error) {
        yield put({ type: ActionTypes.DEFAULT_FOLDERS_FAILURE, payload: { error } });
        yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: "Unable to load Folder" } });
    }
}

export function* defaultFolderRequest() {
    yield takeEvery(ActionTypes.DEFAULT_FOLDERS, defaultFolders);
}

function* createFolder(action) {
    try {
        const response = yield call(post, CREATE_FOLDER_PATH, null, action.payload);
        const data = response.data;
        if (response.status === 200) {
            const payload = data.Info;
            yield put({ type: ActionTypes.CREATE_FOLDER_SUCCESS, payload });
            yield put({ type: ActionTypes.TOAST_SUCCESS, payload: { type: "Success", message: data.status } });
        } else if (response.status === 401) {
            yield put({ type: ActionTypes.LOGOUT_SUCCESS });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: msg } });
        } else {
            yield put({ type: ActionTypes.CREATE_FOLDER_FAILURE, data });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: data.status } });
        }
    } catch (error) {
        yield put({ type: ActionTypes.CREATE_FOLDER_FAILURE, payload: { error } });
        yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: "Unable to create new folder." } });
    }
}

export function* createFolderRequest() {
    yield takeEvery(ActionTypes.CREATE_FOLDER, createFolder);
}

function* allFolders() {
    try {
        const response = yield call(get, GET_ALL_FOLDERS_PATH);
        const data = response.data;

        if (response.status === 200) {
            const folders = data.Info;
            yield put({ type: ActionTypes.ADD_FOLDER_SUCCESS, folders });
        } else if (response.status === 401) {
            yield put({ type: ActionTypes.LOGOUT_SUCCESS });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: msg } });
        } else {
            yield put({ type: ActionTypes.ADD_FOLDER_FAILURE, data });
        }
    } catch (error) {
        yield put({ type: ActionTypes.ADD_FOLDER_FAILURE, payload: { error } });
        yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: "Unable to get folders" } });
    }
}

export function* allFolderRequest() {
    yield takeEvery(ActionTypes.ADD_FOLDER, allFolders);
}

function* renameFolder(action) {
    try {
        const response = yield call(putApi, RENAME_FOLDER_PATH, null, action.payload);
        const data = response.data;
        if (response.status === 200) {
            const payload = action.payload;
            yield put({ type: ActionTypes.RENAME_FOLDER_SUCCESS, payload });
            yield put({ type: ActionTypes.TOAST_SUCCESS, payload: { type: "Success", message: data.status } });
        } else if (response.status === 401) {
            yield put({ type: ActionTypes.LOGOUT_SUCCESS });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: msg } });
        } else {
            yield put({ type: ActionTypes.RENAME_FOLDER_FAILURE, data });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: data.status } });
        }
    } catch (error) {
        yield put({ type: ActionTypes.RENAME_FOLDER_FAILURE, payload: { error } });
        yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: "Can't rename folder" } });
    }
}

export function* renameFolderRequest() {
    yield takeEvery(ActionTypes.RENAME_FOLDER, renameFolder);
}

function* deleteFolder(action) {
    try {
        const response = yield call(deleteApi, DELETE_FOLDER_PATH + action.payload.folderName, null);
        const data = response.data;
        if (response.status === 200) {
            const payload = action.payload;
            yield put({ type: ActionTypes.DELETE_FOLDER_SUCCESS, payload });
            yield put({ type: ActionTypes.TOAST_SUCCESS, payload: { type: "Success", message: data.status } });
        } else if (response.status === 401) {
            yield put({ type: ActionTypes.LOGOUT_SUCCESS });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: msg } });
        } else {
            yield put({ type: ActionTypes.DELETE_FOLDER_FAILURE, data });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: data.status } });
        }
    } catch (error) {
        yield put({ type: ActionTypes.DELETE_FOLDER_FAILURE, payload: { error } });
        yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: "Unable to delete folder" } });
        return { success: false };
    }
}

export function* deleteFolderRequest() {
    yield takeEvery(ActionTypes.DELETE_FOLDER, deleteFolder);
}

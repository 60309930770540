import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./ShareForm.Style.css";
import { toastError } from "../../redux/ActionCreator/Toast.ActionCreator";
import { FaExternalLinkAlt } from "react-icons/fa";
import { createClip, deleteClip } from "../../redux/ActionCreator/Clip.ActionCreator";
import LoadingComponent from "../Loading/LoadingGeneral.Component";
import { Subscription_Feature_Switch } from "../../constants/FeatureSwitch";

export default function ClipComponent(props) {
    const [urlToClip, setUrlToClip] = useState("");
    const [hoveredUrl, setHoveredUrl] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const urls = useSelector((state) => state.Clip.urls);
    const isClipping = useSelector((state) => state.Clip.isClipping);
    const isUnClipping = useSelector((state) => state.Clip.isUnClipping);
    const subscriptionType = useSelector((state) => state.Subscription.subscriptionType);

    const handleInputChange = (e) => {
        setUrlToClip(e.target.value);
    };

    const handleCloseClipClick = () => {
        props.setIsClipOpen(false);
    };

    const clipRecord = () => {
        if(isClipping)
        {
            return;
        }

        if(Subscription_Feature_Switch && 
        subscriptionType === process.env.REACT_APP_BASIC_SUBSCRIPTION && urls.length>=1)
        {
            const payload = {
                type: "Error",
                message: "Upgrade plan to increase clip limit",
            };
            dispatch(toastError(payload));
            return navigate('/pricing');
        }
        
        if (urlToClip.length>2000) {
            const payload = {
                type: "Error",
                message: "Url is too long to clip!!!",
            };
            dispatch(toastError(payload));
            return;
        }

        if (!urlToClip.length) {
            const payload = {
                type: "Error",
                message: "Enter no empty url!!!",
            };
            dispatch(toastError(payload));
            return;
        }

        const payload = {
            recordId:props.recordId,
            url : urlToClip
        };
        dispatch(createClip(payload));
    };

    const unClipRecord= (url) => {
        const payload = {
            recordId: props.recordId,
            url: url
        };
        dispatch(deleteClip(payload));
    };

    const processUrlToDomain = (url) => {
        if(!url)
        return "";

        const a = document.createElement("a");  
        a.href = url;
        const domain = a.hostname;
        if(domain)
            return domain;
        else
            return url;
    };

    const truncateUrl = (url) => {
        if(!url)
        return;
        const truncTill = 50;
        if(window.innerWidth > 768)
        return (url.length<truncTill)?url:(url.slice(0,truncTill)+'...');
        else
        return (url.length<30)?url:(url.slice(0,30)+'...');
    };

    const handleRedirect = (url) => {
        window.open(url, "_blank", "noreferrer")
    };

    return (
        <div className="create-share-wrapper">
            {isUnClipping && <LoadingComponent loading="Unclipping" />}
            <div className="share-input-wrapper">
                <button className="close-create-share" onClick={handleCloseClipClick}></button>
                <div className="create-share">
                    {
                        props.Owner &&
                        <><input type="text" placeholder="Enter url to clip" value={urlToClip} onChange={handleInputChange} className="share-email-field" />
                        <button className="create-share-button" onClick={clipRecord} disabled = {isClipping}>
                        {isClipping ? 'Clipping' : 'Clip'}
                        </button></>
                    }
                </div>
                <div className="share-people-list">
                    <div className="create-share-title">Record is attached on</div>
                    <div className="create-share-people-list">
                        {urls && urls.map((url) => {
                            return (
                                <div className="create-shared-with-person" key={url.url} onMouseEnter={() => setHoveredUrl(url.url)} onMouseLeave={() => setHoveredUrl(null)}>
                                    <span className="record-shared-with-name">{processUrlToDomain(url.url)}</span>
                                    <button className = "echovault-ext-toggle-button echovault-ext-redirect-button">
                                        <FaExternalLinkAlt onClick = {() => {handleRedirect(url.url);}}/>
                                    </button>
                                    {
                                        props.Owner &&
                                        <button
                                            className="record-delete-button"
                                            onClick={() => {
                                                unClipRecord(url.url);
                                            }}
                                        >
                                            Remove
                                        </button>
                                    }
                                    {hoveredUrl && hoveredUrl === url.url && (
                                        <div className={`email-tooltip ${url.url === urls[urls.length - 1].url ? "last-position" : ""}`}>
                                            <div className={`email-tooltip-email ${url.url === urls[urls.length - 1].url ? "last-position-email" : ""}`}>{truncateUrl(url.url)}</div>
                                            <div className={`tooltip-arrow ${url.url === urls[urls.length - 1].url ? "last-position-arrow" : ""}`}></div>
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}

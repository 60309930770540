import React from "react";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { toastError } from "../../redux/ActionCreator/Toast.ActionCreator";
import { useDispatch } from "react-redux";
import { requestGoogleLogin } from "../../redux/ActionCreator/Auth.ActionCreator";

function GoogleAuth() {
    const dispatch = useDispatch();
    return (
        <div
            style={{
                marginTop: "10px",
            }}
        >
            <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENTID}>
                <GoogleLogin
                    onSuccess={(credentialResponse) => {
                        dispatch(requestGoogleLogin(credentialResponse));
                    }}
                    onError={() => {
                        const payload = {
                            type: "Error",
                            message: "Login Failed",
                        };
                        dispatch(toastError(payload));
                    }}
                    size="large"
                    text="continue_with"
                />
            </GoogleOAuthProvider>
        </div>
    );
}

export default GoogleAuth;

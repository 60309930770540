import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteProfile } from "../../redux/ActionCreator/Auth.ActionCreator";
import { cancelSubscription } from "../../redux/ActionCreator/Subscription.ActionCreator";
import "./Confirmation.Style.css";

function ConfirmationComponent(props) {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.Auth.isLoading);
    const isCancelling = useSelector((state) => state.Subscription.isCancelling);
    const [clicked, setClicked] = useState(false);

    const handleCancelClick = () => {
        props.callback();
    }

    const handleSuccessClick = () => {
        setClicked(true);
        if(props.title === "Unsubscribe")
        {
            dispatch(cancelSubscription(props.confirmationPayload));
        }
        else
        {
            dispatch(deleteProfile(props))
        }
    }

    useEffect(()=>{
        if((!isLoading || !isCancelling) && clicked)
        {
            handleSuccessClick();
        }
    }, [isLoading, isCancelling]);

    return (
        <div className="confirmation-component-wrapper">
            <div className="confirmation-component">
                <h3 id="confirmation-screen-title">{props.title}</h3>
                <p id="confirmation-screen-message">{props.message}</p>
                <div className="confirmation-component-buttons">
                    <button className="confirmation-screen-cancel-button" onClick = {handleCancelClick}>Cancel</button>
                    {
                        (isLoading || isCancelling)
                        ?
                            <button className="confirmation-screen-success-button">{props.confirming}</button>
                        :
                            <button className="confirmation-screen-success-button" onClick = {handleSuccessClick}>{props.confirmationButton}</button>
                    }
                </div>
            </div>
        </div>
    );
}

export default ConfirmationComponent;

import { call, put, takeEvery } from "redux-saga/effects";
import * as ActionTypes from "../../constants/ActionTypes";
import { post } from "../../api/AxiosConfig";
import { CREATE_HELP_PATH } from "../../constants/ApiPaths";

function* createHelp(action) {
    try {
        const response = yield call(post, CREATE_HELP_PATH, null, action.payload);
        const data = response.data;
        if (response.status === 200) {
            const payload = data.Info;
            yield put({ type: ActionTypes.CREATE_HELP_SUCCESS, payload });
            yield put({ type: ActionTypes.TOAST_SUCCESS, payload: { type: "Success", message: data.status } });
        } else {
            yield put({ type: ActionTypes.CREATE_HELP_FAILURE, data });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: data.status } });
        }
    } catch (error) {
        yield put({ type: ActionTypes.CREATE_HELP_FAILURE, payload: { error } });
        yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: "Unable to post issue." } });
    }
}

export function* createHelpRequest() {
    yield takeEvery(ActionTypes.CREATE_HELP, createHelp);
}

import { createStore, combineReducers, applyMiddleware } from "redux";
import { Auth } from "./Reducers/Auth.Reducer";
import { ErrorMess } from "./Reducers/ErrorMess.Reducer";
import { Folders } from "./Reducers/Folders.Reducer";
import { Records } from "./Reducers/Records.Reducer";
import { Share } from "./Reducers/Share.Reducer";
import { Subscription } from "./Reducers/Subscription.Reducer";
import { Clip } from "./Reducers/Clip.Reducer";
import { Help } from "./Reducers/Help.Reducer";
import { Toast } from "./Reducers/Toast.Reducer";
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";
import RootSaga from "./Sagas/RootSaga";

const isDevelopment = process.env.NODE_ENV === 'development';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

if (isDevelopment) {
    const { logger } = require('redux-logger');
    middlewares.push(logger);
}

const store = createStore(
    combineReducers({
        Subscription,
        Records,
        Share,
        Folders,
        ErrorMess,
        Auth,
        Clip,
        Help,
        Toast,
    }),
    applyMiddleware(...middlewares)
);

sagaMiddleware.run(RootSaga);

export default store;

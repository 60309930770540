import * as ActionTypes from "../../constants/ActionTypes";
import { InitialState } from "../../constants/InitialStates";

export const Clip = (state = InitialState.Clip, action) => {
    switch (action.type) {
        case ActionTypes.CLIP_LOADING: {
            return { ...state, isLoading: true, errMess: null };
        }
        case ActionTypes.CLIP_SUCCESS: {
            return { ...state, isLoading: false, errMess: null, urls: action.payload };
        }
        case ActionTypes.CLIP_FAILURE: {
            return { ...state, isLoading: false, errMess: action.payload };
        }
        case ActionTypes.CREATE_CLIP_LOADING: {
            return { ...state, isClipping: true };
        }
        case ActionTypes.CREATE_CLIP_SUCCESS: {
            return { ...state, isClipping: false, urls: [...state.urls, {'url':action.payload.url}] };
        }
        case ActionTypes.CREATE_CLIP_FAILURE: {
            return { ...state, isClipping: false };
        }
        case ActionTypes.UNCLIP_LOADING: {
            return { ...state, isUnClipping: true };
        }
        case ActionTypes.UNCLIP_SUCCESS: {
            let newUrls = state.urls.filter((url) => !(url.url === action.payload.url));
            return { ...state, isUnClipping: false, urls: [...newUrls] };
        }
        case ActionTypes.UNCLIP_FAILURE: {
            return { ...state, isUnClipping: false };
        }
        case ActionTypes.LOGOUT_SUCCESS:
            return { isLoading: true, isClipping: false, isUnClipping: false, errMess: null, urls: [] };
        case ActionTypes.DELETE_PROFILE_SUCCESS:
            return { isLoading: true, isClipping: false, isUnClipping: false, errMess: null, urls: [] };
        default:
            return state;
    }
};

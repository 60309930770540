import * as ActionTypes from "../../constants/ActionTypes";
import { InitialState } from "../../constants/InitialStates";

export const Toast = (state = InitialState.Toast, action) => {
    switch (action.type) {
        case ActionTypes.TOAST_SUCCESS:
            return { ...state, message: action.payload.message, type: action.payload.type, update: !state.update };
        case ActionTypes.TOAST_ERROR: {
            return { ...state, message: action.payload.message, type: action.payload.type, update: !state.update };
        }
        default:
            return state;
    }
};

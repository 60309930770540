import React, { useState, useEffect, useRef } from "react";
import "./Cortona.Style.css";

function CortonaComponent(props) {
    const [isPlaying, setIsPlaying] = useState(false);
    const audioPlayer = useRef(); // reference our audio component
    const animationRef = useRef(); // reference the animation
    const audioContext = useRef();
    const analyser = useRef();
    const dataArray = useRef();
    const canvasRef = useRef();
    const toggleBtnsRef = useRef();
    const canvasContext = useRef();
    const url = "https://storage.googleapis.com/audionotes-7d8dc.appspot.com/MP3Records/demo_msg.wav?GoogleAccessId=firebase-adminsdk-k3gzn%40audionotes-7d8dc.iam.gserviceaccount.com&Expires=3565967400&Signature=XjZP8%2BdGH6wd11hM5oDGXNHmeT%2FBeLT3q063RmuFDIB4B2Ru%2F5%2FTvRyjw58aTfKIIYD48XehVD9RApbmhJdFFtJ%2FzTM7p%2Fso8Fq7TX%2BVKXzJ%2BoBpcqCO4guvKzVdx3U6xDW%2B1CdyQWlNQzg%2BwDxrervtZ2ERVKsFXINvXUaUI8dtAFc%2FWidJZy0Yxyy5hHjTTR1qPNQFr5vkzVWy%2FrHtk5TQQFppTYSySM05GXHpif93HeehM3pO9ibvtb%2Bt4%2BrvFImo0H72DcsA8NFMnvLisu5z4EBKY7BpolTpHaaauUjGef%2BJxo3AfvR%2BUAtQfGX3kEMtb%2Bu8Tss9lcqn4XRZfg%3D%3D";

    const togglePlayPause = () => {
        if (!audioContext.current) {
            preparation();
            canvasContext.current = canvasRef.current.getContext("2d");
        }
        const prevValue = isPlaying;
        setIsPlaying(!prevValue);
        if (!prevValue) {
            audioPlayer.current.play();
            audioContext.current.resume();
            loop();
            toggleBtnsRef.current.innerHTML = `<svg viewBox="0 0 448 512" title="pause">
            <path d="M144 479H48c-26.5 0-48-21.5-48-48V79c0-26.5 21.5-48 48-48h96c26.5 0 48 21.5 48 48v352c0 26.5-21.5 48-48 48zm304-48V79c0-26.5-21.5-48-48-48h-96c-26.5 0-48 21.5-48 48v352c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48z" />
        </svg>`;
        } else {
            audioPlayer.current.pause();
            cancelAnimationFrame(animationRef.current);
            toggleBtnsRef.current.innerHTML = `<svg viewBox="0 0 448 512" title="play">
                                            <path d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z" />
                                        </svg>`;
        }
    };

    const preparation = () => {
        audioContext.current = new AudioContext();
        analyser.current = audioContext.current.createAnalyser();
        analyser.current.fftSize = 128;
        const src = audioContext.current.createMediaElementSource(audioPlayer.current);
        src.connect(analyser.current);
        analyser.current.connect(audioContext.current.destination);
        loop();
    };

    const loop = () => {
        if (!audioPlayer.current) {
            cancelAnimationFrame(animationRef.current);
            return;
        }

        if (audioPlayer.current.paused) {
            return;
        }

        canvasContext.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

        window.requestAnimationFrame(loop);

        dataArray.current = new Uint8Array(analyser.current.frequencyBinCount);
        analyser.current.getByteFrequencyData(dataArray.current);
        changeTracks();
    };

    const changeTracks = () => {
        const canvasWidth = canvasRef.current.width;
        const canvasHeight = canvasRef.current.height;
        if (!dataArray.current) return;
        const barWidth = canvasWidth * 0.01;
        const barSpacing = barWidth;
        for (let i = 0; i < 30; i++) {
            if (dataArray.current[i] <= 4) {
                dataArray.current[i] = 4;
            }
            const barHeight = dataArray.current[i] * 0.5;
            const x = (i + 10) * (barWidth + barSpacing);
            const y = (canvasHeight - barHeight) / 2;

            const borderRadius = 2;

            canvasContext.current.fillStyle = "#000000";
            drawRoundedRect(canvasContext.current, x, y, barWidth, barHeight, borderRadius);
        }
    };

    function drawRoundedRect(ctx, x, y, width, height, radius) {
        ctx.beginPath();
        ctx.moveTo(x + radius, y);
        ctx.arcTo(x + width, y, x + width, y + height, radius);
        ctx.arcTo(x + width, y + height, x, y + height, radius);
        ctx.arcTo(x, y + height, x, y, radius);
        ctx.arcTo(x, y, x + width, y, radius);
        ctx.closePath();
        ctx.fill();
    }

    useEffect(() => {
        audioPlayer.current.crossorigin = "anonymous";
        if (!dataArray.current) {
            dataArray.current = [];
            for (let i = 1; i <= 48; i++) {
                if (i % 2 === 0) {
                    dataArray.current.push(160);
                } else if (i % 3 === 0) {
                    dataArray.current.push(120);
                } else {
                    dataArray.current.push(210);
                }
            }
            if (!audioContext.current) {
                preparation();
                canvasContext.current = canvasRef.current.getContext("2d");
            }

            canvasContext.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
            changeTracks();
        }

        const handleResize = () => {
            const { width, height } = canvasRef.current.getBoundingClientRect();
            canvasRef.current.width = width;
            canvasRef.current.height = height;
            canvasContext.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
            changeTracks();
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div id="CortonaComponentDiv">
            <audio
                ref={audioPlayer}
                src={url}
                type="audio/wav"
                crossOrigin="anonymous"
                id="audio"
            ></audio>
            <div id="visualizer">
                <canvas className="records-canvas" ref={canvasRef} style={{ width: "100%", height: "120px" }}></canvas>
            </div>
            <button id="toggle-play" ref={toggleBtnsRef} onClick={togglePlayPause}>
                <svg viewBox="0 0 448 512" title="play">
                    <path d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z" />
                </svg>
            </button>
        </div>
    );
}

export default CortonaComponent;

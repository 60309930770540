import React from "react";
import "./EmptyPage.Style.css";

const EmptyPageComponent = () => {
    return (
        <div className="empty-component">
            <div className="empty-component-container">
                Add a record to this folder
                {
                    //use chrome extension for easy recording and avaibility
                }
            </div>
        </div>
    );
};

export default EmptyPageComponent;

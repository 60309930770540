import * as ActionTypes from "../../constants/ActionTypes";

export const createHelp = (payload) => ({
    type: ActionTypes.CREATE_HELP,
    payload,
});

export const createHelpSuccess = (message) => ({
    type: ActionTypes.CREATE_HELP_SUCCESS,
    message,
});

export const createHelpFailure = (message) => ({
    type: ActionTypes.CREATE_HELP_FAILURE,
    message,
});

import * as ActionTypes from "../../constants/ActionTypes";

export const clipsLoading = (data) => ({
    type: ActionTypes.CLIP_LOADING,
    data,
});

export const clipSuccess = (urls) => ({
    type: ActionTypes.CLIP_SUCCESS,
    urls,
});

export const clipFailed = (message) => ({
    type: ActionTypes.CLIP_FAILURE,
    message,
});

export const createClip = (payload) => ({
    type: ActionTypes.CREATE_CLIP_LOADING,
    payload,
});

export const createClipSuccess = (message) => ({
    type: ActionTypes.CREATE_CLIP_SUCCESS,
    message,
});

export const createClipFailure = (message) => ({
    type: ActionTypes.CREATE_CLIP_FAILURE,
    message,
});

export const deleteClip = (payload) => ({
    type: ActionTypes.UNCLIP_LOADING,
    payload,
});

export const deleteClipSuccess = (payload) => ({
    type: ActionTypes.UNCLIP_SUCCESS,
    payload,
});

export const deleteClipFailure = (message) => ({
    type: ActionTypes.UNCLIP_FAILURE,
    message,
});

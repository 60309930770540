import React from "react";
import FolderDisplayComponent from "./FolderDisplay.Component";
import { useSelector } from "react-redux";
import LoadingComponent from "../Loading/LoadingUsed.Component";
import EmptyFolderComponent from "../Loading/NoFolder.Component";
import SeoComponent from "../seo/Seo.Component";

export default function BaseFolderComponent(props) {
    const folders = useSelector((state) => state.Folders.folders);
    const isLoading = useSelector((state) => state.Folders.isLoading);

    return (
        <>
            <SeoComponent
                title = 'Folders'
                description = 'Organize your records easily'
                name = 'Echovault'
                type = 'website' />
            {isLoading ? (
                <LoadingComponent />
            ) : (
                <div className="folders">
                    {folders && folders.length - 3 ? (
                        folders?.map((folder) => {
                            return folder.foldername === "recently added" || folder.foldername === "recently deleted" || folder.foldername === "favourite" ? (
                                ""
                            ) : (
                                <FolderDisplayComponent key={folder.id} folderId={folder.id} folderName={folder.foldername} totalFiles={folder.totalfiles} totalSize={folder.totalsize} />
                            );
                        })
                    ) : (
                        <EmptyFolderComponent />
                    )}
                </div>
            )}
        </>
    );
}

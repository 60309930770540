import React from 'react';
import { Helmet } from 'react-helmet-async';

function SeoComponent(props) {
    return (
        <Helmet>
            <title>{props.title}</title>
            <meta name='description' content = {props.description} />
            <meta property="og:type" content = {props.type} />
            <meta property="og:title" content = {props.title} />
            <meta property="og:description" content = {props.description} />
            <meta name="twitter:creator" content = {props.name} />
            <meta name="twitter:card" content = {props.type} />
            <meta name="twitter:title" content = {props.title} />
            <meta name="twitter:description" content = {props.description} />
        </Helmet>
    );
}

export default SeoComponent;
import React, { useState, useEffect } from "react";
import RecordDisplayComponent from "../../RecordCard/RecordDisplay.Component";
import "./BaseRecord.Style.css";
import { useDispatch, useSelector } from "react-redux";
import LoadingComponent from "../../Loading/LoadingUsed.Component";
import LoadingComponentGeneral from "../../Loading/LoadingGeneral.Component";
import EmptyPageComponent from "../../Loading/EmptyPage.Component";
import SeoComponent from "../../seo/Seo.Component";

export default function BaseRecordComponent(props) {
    const dispatch = useDispatch();
    const folders = useSelector((state) => state.Folders.folders);
    const records = useSelector((state) => state.Records.records[props.folderName]);
    const isLoading = useSelector((state) => state.Records.isLoading);
    const [folderName, setFolderName] = useState({});
    const [currentlyPlaying, setCurrentlyPlaying] = useState(null);

    const isDelete = useSelector((state) => state.Records.isDelete);

    useEffect(() => {
        let recordsId = null;
        folders?.forEach((folderObj) => {
            if (folderObj.foldername === props.folderName) {
                recordsId = folderObj.id;
            }
        });

        if ((recordsId && !records) || props.recordName !== undefined) {
            let payload = {
                folderName: props.folderName,
            };
            if (props.recordName === undefined) {
                payload.id = recordsId;
            } else {
                payload.recordName = props.recordName;
            }
            dispatch(props.method(payload));
        }
        return () => {
            setCurrentlyPlaying(null);
        };
    }, [props.folderName, props.recordName, folders]);

    useEffect(() => {
        const idToName = {};
        for (let i = 0; i < folders.length; i++) {
            idToName[folders[i].id] = folders[i].foldername;
        }
        setFolderName(idToName);
    }, [folders]);

    const handlePlayPause = (recordId) => {
        setCurrentlyPlaying(recordId);
    };

    return (
        <>
            <SeoComponent
                title = 'Records'
                description = 'Revisit your favourite record online'
                name = 'Echovault'
                type = 'website' />
            {isLoading ? (
                <LoadingComponent />
            ) : (
                <div className="common-records">
                    {isDelete && <LoadingComponentGeneral loading="Delete in progress" />}
                    {records && records.length ? (
                        records?.map((record) => {
                            return (
                                <RecordDisplayComponent
                                    key={record.id}
                                    folderId={record.folderid}
                                    recordId={record.id}
                                    length={record.seconds}
                                    name={record.recordname}
                                    old={record.createdat}
                                    fileName={record.firebaserecordname}
                                    url={record.url}
                                    folderName={folderName[record.folderid]}
                                    displayName={props.displayName}
                                    moveTo={props.moveTo}
                                    seconds={record.seconds}
                                    isPlaying={currentlyPlaying === record.id}
                                    onPlayPause={() => handlePlayPause(record.id)}
                                />
                            );
                        })
                    ) : (
                        <EmptyPageComponent />
                    )}
                </div>
            )}
        </>
    );
}

import axios, { AxiosError } from "axios";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const axiosInstance = axios.create({
    baseURL: baseUrl,
    withCredentials: true,
});

// axiosInstance.interceptors.request.use(
//     (config) => {
//         const token = localStorage.getItem("token");
//         if (token) {
//             config.headers["Authorization"] = "Bearer " + token;
//         }
//         return config;
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
// );

export async function get(url, queryParams = null, headers = null) {
    try {
        const response = await axiosInstance.request({
            method: "get",
            url: url,
            params: queryParams,
            headers: headers,
        });
        return response;
    } catch (error) {
        if (error instanceof AxiosError) {
            return error.response;
        } else {
            throw error;
        }
    }
}

export async function post(url, queryParams = null, bodyParams = null, headers = null) {
    try {
        const response = await axiosInstance.request({
            method: "post",
            url: url,
            params: queryParams,
            headers: {
                "Content-Type": "application/json",
            },
            data: JSON.stringify(bodyParams),
        });
        return response;
    } catch (error) {
        if (error instanceof AxiosError) {
            return error.response;
        } else {
            throw error;
        }
    }
}

export async function postFormData(url, queryParams = null, bodyParams = null, headers = null) {
    try {
        const response = await axiosInstance.request({
            method: "post",
            url: url,
            params: queryParams,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: bodyParams,
        });
        return response;
    } catch (error) {
        if (error instanceof AxiosError) {
            return error.response;
        } else {
            throw error;
        }
    }
}

export async function deleteApi(url, queryParams = null, bodyParams = null, headers = null) {
    try {
        const response = await axiosInstance.request({
            method: "delete",
            url: url,
            params: queryParams,
            headers: headers,
            data: JSON.stringify(bodyParams),
        });
        return response;
    } catch (error) {
        if (error instanceof AxiosError) {
            return error.response;
        } else {
            throw error;
        }
    }
}

export async function putApi(url, queryParams = null, bodyParams = null, headers = null) {
    try {
        const response = await axiosInstance.request({
            method: "put",
            url: url,
            params: queryParams,
            headers: {
                "Content-Type": "application/json",
            },
            data: JSON.stringify(bodyParams),
        });

        return response;
    } catch (error) {
        if (error instanceof AxiosError) {
            return error.response;
        } else {
            throw error;
        }
    }
}

export async function patch(url, queryParams = null, bodyParams = null, headers = null) {
    try {
        const response = await axiosInstance.request({
            method: "patch",
            url: url,
            params: queryParams,
            headers: {
                "Content-Type": "application/json",
            },
            data: JSON.stringify(bodyParams),
        });

        return response;
    } catch (error) {
        if (error instanceof AxiosError) {
            return error.response;
        } else {
            throw error;
        }
    }
}

import React, { useEffect, useState } from "react";
import "./Auth.Style.css";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
    getOtpRequest,
    otpVerifyRequest,
    requestLogin,
} from "../../redux/ActionCreator/Auth.ActionCreator";
import { toastError } from "../../redux/ActionCreator/Toast.ActionCreator";
import HeaderComponent from "../Header/HeaderRegistrationPage.Component";
import SeoComponent from "../seo/Seo.Component";
import GoogleAuth from "./GoogleAuthentication.Component";

function Auth() {
    const { state } = useLocation();
    const [method, setMethod] = useState(state?.method ?? "signin");

    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [confPassword, setConfPassword] = useState("");
    const [isForgetOpen, setIsForgetOpen] = useState(false);
    const [email, setEmail] = useState("");
    const [emailVerification, setEmailVerification] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [otpVerify, setVerify] = useState("");
    const [name, setName] = useState("");
    const [hasOtp, setHasOtp] = useState(false);
    const [isGoogle, setIsGoogle] = useState(false);

    const isOtpRetrived = useSelector((state) => state.Auth.isOtpRetrived);
    const isLoading = useSelector((state) => state.Auth.isLoading);
    const isPasswordChanged = useSelector(
        (state) => state.Auth.isPasswordChanged
    );

    const dispatch = useDispatch();

    const changeToSignUp = () => {
        setMethod("signup");
    };

    const changeToSignIn = () => {
        setMethod("signin");
    };

    const handleForget = () => {
        setIsForgetOpen(!isForgetOpen);
    };

    useEffect(() => {
        if (state && state.method) {
            if (state.method === "signup") {
                changeToSignUp();
            } else if (state.method === "signin") {
                changeToSignIn();
            }
        }
    }, [state]);

    useEffect(() => {
        if (isOtpRetrived) {
            setHasOtp(true);
        }
    }, [isOtpRetrived]);

    useEffect(() => {
        if (isPasswordChanged) {
            setIsForgetOpen(false);
        }
    }, [isPasswordChanged]);

    const handleLogin = async (e) => {
        if (isLoading) {
            return;
        }

        e.preventDefault();
        const creds = {
            email: email,
            password: password,
        };
        dispatch(requestLogin(creds));
    };

    const verifyOtp = (e) => {
        if (isLoading) {
            return;
        }

        e.preventDefault();
        if (!preVerification()) {
            return;
        } else if (!otpVerify) {
            const payload = {
                type: "Error",
                message: "Please Enter the Otp",
            };
            dispatch(toastError(payload));
        } else {
            const method = "changePassword";
            const payload = {
                email: emailVerification,
                otp: otpVerify,
                password: newPassword,
                method: method,
            };
            dispatch(otpVerifyRequest(payload));
        }
    };

    const preVerification = () => {
        const regex =
            "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$";
        if (emailVerification === "") {
            const payload = {
                type: "Error",
                message: "please enter email address",
            };
            dispatch(toastError(payload));
            return false;
        } else if (newPassword !== confirmPassword) {
            const payload = {
                type: "Error",
                message: "Confirm password and password should be same",
            };
            dispatch(toastError(payload));
            return false;
        } else if (!newPassword.match(regex)) {
            const payload = {
                type: "Error",
                message:
                    "Password must contain one lowercase character, one uppercase character, one number and one special character and minimum length of 8.",
            };
            dispatch(toastError(payload));
            return false;
        }
        return true;
    };

    const preVerificationSignUp = () => {
        const regex =
            "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$";
        const nameRegex =
            "(^[A-Za-z]{1,16})([ ]{0,1})([A-Za-z]{1,16})?([ ]{0,1})?([A-Za-z]{1,16})?([ ]{0,1})?([A-Za-z]{1,16})";
        if (
            email === "" ||
            username === "" ||
            name === "" ||
            password === "" ||
            confPassword === ""
        ) {
            const payload = {
                type: "Error",
                message: "All fields are mandatory",
            };
            dispatch(toastError(payload));
            return false;
        } else if (password !== confPassword) {
            const payload = {
                type: "Error",
                message: "Confirm password and password should be same",
            };
            dispatch(toastError(payload));
            return false;
        } else if (!password.match(regex)) {
            const payload = {
                type: "Error",
                message:
                    "Password must contain one lowercase character, one uppercase character, one number and one special character and minimum length of 8.",
            };
            dispatch(toastError(payload));
            return false;
        } else if (!name.match(nameRegex)) {
            const payload = {
                type: "Error",
                message: "please enter valid name",
            };
            dispatch(toastError(payload));
            return false;
        }
        return true;
    };

    const sendOtp = (e) => {
        if (isLoading) {
            return;
        }

        e.preventDefault();
        if (preVerification()) {
            const payload = {
                email: emailVerification,
                name: name,
            };
            dispatch(getOtpRequest(payload));
        }
    };

    const signupSendOtp = (e) => {
        if (isLoading) {
            return;
        }

        e.preventDefault();
        if (preVerificationSignUp()) {
            const payload = {
                email: email,
                name: name,
            };
            dispatch(getOtpRequest(payload));
        }
    };

    const reEnterDetails = async (e) => {
        e.preventDefault();

        if (isLoading) {
            return;
        }

        setHasOtp(false);
    };

    const handleSignUp = async (e) => {
        e.preventDefault();

        if (isLoading) {
            return;
        }

        if (!preVerificationSignUp()) {
            return;
        } else if (!otpVerify) {
            const payload = {
                type: "Error",
                message: "Please Enter the Otp",
            };
            dispatch(toastError(payload));
        } else {
            const method = "signUp";
            const payload = {
                username: username,
                password: password,
                email: email,
                name: name,
                otp: otpVerify,
                method: method,
            };

            dispatch(otpVerifyRequest(payload));
        }
    };

    return (
        <>
            <SeoComponent
                title="Auth"
                description="Echovault - a better way to record, clip and share audio online"
                name="Echovault"
                type="website"
            />
            <HeaderComponent redirectTo={"/auth"} noredirect={false} />
            <div id="auth-container">
                <div
                    className={`login-card ${
                        method === "signup" ? "right-panel-active" : ""
                    } `}
                    id="login-card"
                >
                    <div className="form-container sign-up-container">
                        <form>
                            <h2>Create Account</h2>
                            {!hasOtp && (
                                <input
                                    type="text"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            )}
                            {!hasOtp && (
                                <input
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            )}
                            {!hasOtp && (
                                <input
                                    type="text"
                                    placeholder="Username"
                                    value={username}
                                    onChange={(e) =>
                                        setUserName(e.target.value)
                                    }
                                />
                            )}
                            {!hasOtp && (
                                <input
                                    type="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                />
                            )}
                            {!hasOtp && (
                                <input
                                    type="password"
                                    placeholder="Confirm Password"
                                    value={confPassword}
                                    onChange={(e) =>
                                        setConfPassword(e.target.value)
                                    }
                                />
                            )}
                            {hasOtp && (
                                <input
                                    type="text"
                                    placeholder="OTP"
                                    onChange={(e) => setVerify(e.target.value)}
                                    disabled={!isOtpRetrived}
                                />
                            )}
                            {hasOtp ? (
                                <div>
                                    <Link
                                        className="resend-otp"
                                        onClick={reEnterDetails}
                                        disabled={isLoading}
                                    >
                                        Back
                                    </Link>
                                    <br />
                                    <Link
                                        className="resend-otp"
                                        onClick={signupSendOtp}
                                        disabled={isLoading}
                                    >
                                        Resend OTP
                                    </Link>
                                    <br />
                                    <button onClick={handleSignUp}>
                                        {isLoading
                                            ? "Signing Up ..."
                                            : "Sign Up"}
                                    </button>
                                    <br />
                                    <Link
                                        className="extra-visible-small"
                                        onClick={changeToSignIn}
                                    >
                                        Sign In
                                    </Link>
                                    By registering, you agree to the{" "}
                                    <a
                                        href="/terms-condition"
                                        style={{
                                            color: "#625df5",
                                            fontSize: "14px",
                                            textDecoration: "underline",
                                        }}
                                        className="terms-privacy"
                                    >
                                        Terms
                                    </a>{" "}
                                    and{" "}
                                    <a
                                        href="/privacy-policy"
                                        style={{
                                            color: "#625df5",
                                            fontSize: "14px",
                                            textDecoration: "underline",
                                        }}
                                        className="terms-privacy"
                                    >
                                        Privacy Policy
                                    </a>
                                </div>
                            ) : (
                                <div>
                                    <button
                                        onClick={signupSendOtp}
                                        disabled={isLoading}
                                    >
                                        {isLoading ? "Sending ..." : "Send otp"}
                                    </button>
                                    <GoogleAuth/>
                                    <br />
                                    <Link
                                        className="extra-visible-small"
                                        onClick={changeToSignIn}
                                    >
                                        Sign In
                                    </Link>
                                </div>
                            )}
                        </form>
                    </div>

                    <div className="form-container sign-in-container">
                        {isForgetOpen ? (
                            <form>
                                <h1>Forgot password</h1>
                                <input
                                    type="text"
                                    placeholder="Email"
                                    value={emailVerification}
                                    onChange={(e) =>
                                        setEmailVerification(e.target.value)
                                    }
                                />
                                <input
                                    type="password"
                                    placeholder="Password"
                                    value={newPassword}
                                    onChange={(e) =>
                                        setNewPassword(e.target.value)
                                    }
                                />
                                <input
                                    type="password"
                                    placeholder="Confirm Password"
                                    value={confirmPassword}
                                    onChange={(e) =>
                                        setConfirmPassword(e.target.value)
                                    }
                                />
                                {isOtpRetrived && (
                                    <input
                                        type="text"
                                        placeholder="OTP"
                                        onChange={(e) =>
                                            setVerify(e.target.value)
                                        }
                                        disabled={!isOtpRetrived}
                                    />
                                )}
                                {isOtpRetrived ? (
                                    <div>
                                        <Link
                                            className="resend-otp"
                                            onClick={sendOtp}
                                        >
                                            Resend OTP
                                        </Link>
                                        <br />
                                        <Link onClick={handleForget}>
                                            Back to login
                                        </Link>
                                        <br />
                                        <button
                                            onClick={verifyOtp}
                                            disabled={isLoading}
                                        >
                                            {isLoading
                                                ? "Changing ..."
                                                : "Reset"}
                                        </button>
                                    </div>
                                ) : (
                                    <div>
                                        <Link onClick={handleForget}>
                                            Back to login
                                        </Link>
                                        <br />
                                        <button
                                            onClick={sendOtp}
                                            disabled={isLoading}
                                        >
                                            {isLoading
                                                ? "Sending ..."
                                                : "Send otp"}
                                        </button>
                                    </div>
                                )}
                            </form>
                        ) : (
                            <form
                                onSubmit={handleLogin}
                                id="auth-extra-top-margin"
                            >
                                <h1>Sign in</h1>
                                <input
                                    type="text"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <input
                                    type="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                />
                                <Link onClick={handleForget}>
                                    Forgot your password?
                                </Link>
                                <button disabled={isLoading}>
                                    {isLoading ? "Logging in ..." : "Sign In"}
                                </button>
                                 <GoogleAuth/>
                                <Link
                                    className="extra-visible-small"
                                    onClick={changeToSignUp}
                                >
                                    Sign up
                                </Link>
                            </form>
                        )}
                    </div>

                    <div className="overlay-container">
                        <div className="overlay">
                            <div className="overlay-panel overlay-left">
                                <h1>Welcome Back!</h1>
                                <p>
                                    To keep connected with us please login with
                                    your sign-in details
                                </p>
                                <button
                                    onClick={changeToSignIn}
                                    className="ghost"
                                    id="signIn"
                                >
                                    Sign In
                                </button>
                            </div>

                            <div className="overlay-panel overlay-right">
                                <h1>Hello, Friend!</h1>
                                <p>
                                    Enter your details and start journey with us
                                </p>
                                <button
                                    onClick={changeToSignUp}
                                    className="ghost"
                                    id="signUp"
                                >
                                    Sign Up
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Auth;

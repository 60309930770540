import * as ActionTypes from "../../constants/ActionTypes";
import { InitialState } from "../../constants/InitialStates";

export const Subscription = (state = InitialState.Subscription, action) => {
    switch (action.type) {
        case ActionTypes.CREATE_SUBSCRIBER_SUCCESS:
            return { ...state, checkoutSession: action.payload.sessionurl };
        case ActionTypes.LOGIN_SUCCESS:
            return { ...state, subscriptionType: action.data.Info.subscriptiontype, canceled: action.data.Info.canceled, nextPaymentDate: action.data.Info.nextpaymentdate };
        case ActionTypes.INFO_SUCCESS:
            return { ...state, subscriptionType: action.Info.subscriptiontype, canceled: action.Info.canceled, nextPaymentDate: action.Info.nextpaymentdate };
        case ActionTypes.CANCEL_SUBSCRIPTION:
            return { ...state, isCancelling: true };
        case ActionTypes.CANCEL_SUBSCRIPTION_SUCCESS:
            return { ...state, isCancelling: false, canceled: process.env.REACT_APP_BASIC_SUBSCRIPTIONSTATUS_ENDING };
        case ActionTypes.CANCEL_SUBSCRIPTION_FAILURE:
            return { ...state, isCancelling: false };
        case ActionTypes.CONTINUE_SUBSCRIPTION:
            return { ...state, isCancelling: true };
        case ActionTypes.CONTINUE_SUBSCRIPTION_SUCCESS:
            return { ...state, isCancelling: false, canceled: process.env.REACT_APP_BASIC_SUBSCRIPTIONSTATUS_ACTIVE };
        case ActionTypes.CONTINUE_SUBSCRIPTION_FAILURE:
            return { ...state, isCancelling: false };
        case ActionTypes.CUSTOMER_PORTAL:
            return { ...state, isCancelling: true };
        case ActionTypes.CUSTOMER_PORTAL_SUCCESS:
            return { ...state, isCancelling: false, customerPortalSession: action.payload.url };
        case ActionTypes.CUSTOMER_PORTAL_FAILURE:
            return { ...state, isCancelling: false };
        default:
            return state;
    }
};

import React, { useState, useRef, useEffect } from "react";
import "./PostRecordingViewer.Style.css";

const PostRecordingViewerComponent = (props) => {
    const [totalBars, setTotalBars] = useState(0);
    const [extraBars, setExtraBars] = useState(0);

    const audioPlayer = useRef(null);
    const animationRef = useRef(null);
    const canvasRef = useRef(null);
    const canvasContext = useRef(null);
    const usedBars = useRef(null);

    const barWidth = 1;
    const barSpacing = 0;
    let canvasHeight = 200;
    const sideBarWidth = 10;

    const setCanvasSize = () => {
        canvasRef.current.width = window.innerWidth * 0.75;
        canvasRef.current.height = canvasHeight;
    };

    const drawBars = () => {
        const { levelData } = props;
        canvasContext.current = canvasRef.current.getContext("2d");

        if (canvasContext.current && levelData && levelData.length > 0) {
            setCanvasSize();
            canvasContext.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
            const canvasWidth = canvasRef.current.width;
            canvasHeight = canvasRef.current.height;
            const barCount = levelData.length;
            const totalBarWidth = barCount * (barWidth + barSpacing);
            if (totalBarWidth <= canvasWidth) {
                const spaceForBlankBars = (canvasWidth - totalBarWidth) / 2;
                const blankBars = Math.floor(spaceForBlankBars / (barWidth + barSpacing));
                let temp = [];
                let index = 0;
                for (let i = 0; i < barCount; i++) {
                    temp = [levelData[i]];
                    drawSubBars(temp, index, true);
                    index++;
                }
                for (let i = 0; i < 2 * blankBars; i++) {
                    temp = [1];
                    drawSubBars(temp, index, false);
                    index++;
                }
                setTotalBars(index);
                setExtraBars(2 * blankBars);
            } else {
                let newLevels = [];
                const reducedBarCount = Math.floor(canvasWidth / (barWidth + barSpacing));
                const step = barCount / reducedBarCount;
                let startFrom = 100;
                let index = 0;
                for (let i = 0; i < reducedBarCount; i++) {
                    let remaining = step * 100;
                    let sum = 0;
                    while (index !== levelData.length && remaining > 0) {
                        sum += (levelData[index] * startFrom) / 100;
                        remaining -= startFrom;
                        if (remaining) {
                            if (remaining >= 100) startFrom = 100;
                            else startFrom = remaining;
                            index++;
                        } else {
                            if (startFrom === 100) {
                                startFrom = 100;
                                index++;
                            } else startFrom = 100 - startFrom;
                        }
                    }
                    sum /= step;
                    newLevels.push(sum);
                    drawSubBars([sum], i, true);
                }
                setTotalBars(reducedBarCount);
                setExtraBars(0);
            }
            const leftDecoration = document.querySelector(".decoration-front");
            const rightDecoration = document.querySelector(".decoration-rear");
            const padding = window.innerWidth * 0.125 - sideBarWidth;
            leftDecoration.style.left = `${padding}px`;
            rightDecoration.style.right = `${padding}px`;
        }
    };

    const drawSubBars = (subData, index, solid) => {
        const startX = index * (barWidth + barSpacing);
        let barHeight = 0;
        for (let i = 0; i < subData.length; i++) {
            barHeight += subData[i];
        }
        barHeight /= subData.length;
        const x = startX;
        const y = (canvasHeight - barHeight) / 2;

        if (solid) {
            canvasContext.current.fillStyle = "#264c70";
        } else {
            canvasContext.current.fillStyle = "#416d972b";
        }
        canvasContext.current.fillRect(x, y, barWidth, barHeight);
    };

    const handleCanvasClick = (event) => {
        const canvasRect = canvasRef.current.getBoundingClientRect();
        const clickX = event.clientX - canvasRect.left;
        changeRange(clickX);
    };

    useEffect(() => {
        drawBars();
        canvasRef.current.addEventListener("click", handleCanvasClick);
    }, [props.levelData]);

    const handleResize = () => {
        drawBars();
    };

    useEffect(() => {
        usedBars.current = totalBars - extraBars;
        alignTrackBar();
    }, [totalBars]);

    const handleLoadedMetadata = () => {
        alignTrackBar();
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        audioPlayer.current.addEventListener("loadedmetadata", handleLoadedMetadata);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        const prevValue = props.isPlaying;
        if (prevValue) {
            audioPlayer.current.play();
            animationRef.current = requestAnimationFrame(whilePlaying);
        } else {
            audioPlayer.current.pause();
            cancelAnimationFrame(animationRef.current);
        }
    }, [props.isPlaying]);

    const alignTrackBar = () => {
        if(!audioPlayer.current)
        return;

        const totalDuration = Math.floor((audioPlayer.current.duration === Infinity ? props.defaultDuration : audioPlayer.current.duration) * 1000);
        const currentTime = Math.floor(audioPlayer.current.currentTime * 1000);
        const innerWidth = window.innerWidth;

        let currentPixels = (currentTime * usedBars.current) / totalDuration + (innerWidth * 0.25) / 2;
        const minBarDist = (innerWidth * 0.25) / 2 + 0.5;
        const maxBarDist = canvasRef.current.width + minBarDist - 15;

        if (currentPixels > maxBarDist) currentPixels = maxBarDist;

        if (currentPixels < minBarDist || isNaN(currentPixels)) {
            currentPixels = minBarDist;
        }
        const barController = document.querySelector(".bar-controller");
        barController.style.left = `${currentPixels}px`;
    };

    const whilePlaying = () => {
        changePlayerCurrentTime();
        alignTrackBar();
        animationRef.current = requestAnimationFrame(whilePlaying);
    };

    const changeRange = (clickX) => {
        const canvasWidth = usedBars.current;
        if (canvasWidth === 0) return;

        const totalDuration = Math.floor((audioPlayer.current.duration === Infinity ? props.defaultDuration : audioPlayer.current.duration) * 1000);
        const currentTime = (totalDuration * clickX) / (canvasWidth * 1000);
        audioPlayer.current.currentTime = currentTime;
        alignTrackBar();
    };

    const changePlayerCurrentTime = () => {
        if (!audioPlayer.current || !audioPlayer.current.duration || !audioPlayer.current.currentTime) {
            return;
        }

        if (audioPlayer.current.duration === audioPlayer.current.currentTime) {
            props.setIsPlaying(false);
            audioPlayer.current.currentTime = 0;
            props.setIsPlaying(false);
        }
    };

    return (
        <div className="audioPlayer">
            <audio ref={audioPlayer} src={props.src} preload="metadata"></audio>

            <div className="progress-container">
                <div className="decoration-front"></div>
                <canvas className="diplay-recorded-canvas" ref={canvasRef}></canvas>
                <div className="bar-controller"></div>
                <div className="decoration-rear"></div>
            </div>
        </div>
    );
};

export default PostRecordingViewerComponent;

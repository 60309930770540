import * as ActionTypes from "../../constants/ActionTypes";
import { InitialState } from "../../constants/InitialStates";

export const Records = (state = InitialState.Records, action) => {
    switch (action.type) {
        case ActionTypes.ADD_RECORDS: {
            return { ...state, isLoading: false, errMess: null, records: action.payload };
        }
        case ActionTypes.RECORDS_LOADING: {
            return { ...state, isLoading: true, errMess: null };
        }
        case ActionTypes.RECORDS_SUCCESS: {
            const foldername = action.payload.foldername;
            const records = action.payload.records;
            return { ...state, isLoading: false, errMess: null, records: { ...state.records, [foldername]: records } };
        }
        case ActionTypes.RECORDS_FAILURE: {
            return { ...state, isLoading: false, errMess: action.payload };
        }
        case ActionTypes.SEARCH_LOADING: {
            return { ...state, isLoading: true, errMess: null };
        }
        case ActionTypes.SEARCH_SUCCESS: {
            const foldername = action.payload.foldername;
            const records = action.payload.records;
            return { ...state, isLoading: false, errMess: null, records: { ...state.records, [foldername]: records } };
        }
        case ActionTypes.SEARCH_FAILURE: {
            return { ...state, isLoading: false, errMess: action.payload };
        }
        case ActionTypes.RENAME_RECORD: {
            return { ...state, isLoading: false };
        }
        case ActionTypes.RENAME_RECORD_SUCCESS: {
            let records = state.records[action.payload.folderName];
            for (let i = 0; i < records.length; i++) {
                if (records[i].id === action.payload.recordid) {
                    records[i].recordname = action.payload.recordname;
                    break;
                }
            }
            return { ...state, isLoading: false, errMess: null, records: { ...state.records, [action.payload.foldername]: records } };
        }
        case ActionTypes.RENAME_RECORD_FAILURE: {
            return { ...state };
        }
        case ActionTypes.CREATE_RECORD: {
            return { ...state, isLoading: false, isOpen: true };
        }
        case ActionTypes.CREATE_RECORD_SUCCESS: {
            const records = state.records[action.payload.foldername];
            if (records) {
                return { ...state, isLoading: false, errMess: null, isOpen: false, records: { ...state.records, [action.payload.foldername]: [...records, action.payload] } };
            } else {
                return { ...state, isLoading: false, errMess: null, isOpen: false, records: { ...state.records } };
            }
        }
        case ActionTypes.CREATE_RECORD_FAILURE: {
            return { ...state, isLoading: false, isOpen: false, errMess: action.payload };
        }
        case ActionTypes.DELETE_RECORD: {
            return { ...state, isLoading: false, isDelete: true };
        }
        case ActionTypes.DELETE_RECORD_SUCCESS: {
            const records = state.records[action.payload[0].foldername];
            const folderId = action.payload[0].folderid;
            const recordId = action.payload[0].id;
            const updatedRecords = records.filter((record) => !(record.folderid === folderId && record.id === recordId));
            return { ...state, isLoading: false, errMess: null, isDelete: false, records: { ...state.records, [action.payload[0].foldername]: updatedRecords } };
        }
        case ActionTypes.DELETE_RECORD_FAILURE: {
            return { ...state, isLoading: false, isDelete: false, errMess: action.payload };
        }
        case ActionTypes.CHANGE_RECORD_FOLDER: {
            return { ...state, isLoading: false };
        }
        case ActionTypes.CHANGE_RECORD_FOLDER_SUCCESS: {
            const records = state.records[action.payload.oldfoldername];
            const recordId = action.payload.id;
            const newRec = records.filter((record) => record.id === recordId);
            const updatedRecords = records.filter((record) => !(record.id === recordId));

            let newFolderRecords = state.records[action.payload.newfoldername];
            if (newFolderRecords !== undefined) {
                newFolderRecords.push(newRec[0]);
                return { ...state, isLoading: false, errMess: null, records: { ...state.records, [action.payload.oldfoldername]: updatedRecords, [action.payload.newfoldername]: newFolderRecords } };
            } else {
                return { ...state, isLoading: false, errMess: null, records: { ...state.records, [action.payload.oldfoldername]: updatedRecords } };
            }
        }
        case ActionTypes.CHANGE_RECORD_FOLDER_FAILURE: {
            return { ...state, isLoading: false, errMess: action.payload };
        }
        case ActionTypes.LOGOUT_SUCCESS:
            return { isLoading: true, errMess: null, records: {}, isOpen: false, isDelete: false };
        case ActionTypes.DELETE_PROFILE_SUCCESS:
            return { isLoading: true, errMess: null, records: {}, isOpen: false, isDelete: false };
        default:
            return state;
    }
};

import React, { useEffect, useRef, useState } from "react";
import "./CommonCanvasAudioTrack.Style.css";

export default function CommonCanvasAudioTrackComponent(props) {
    const [hoverPosition, setHoverPosition] = useState(null);

    const isDataGather = useRef(false);
    const audioPlayer = useRef();
    const animationRef = useRef();
    const counter = useRef({ time: 0, occ: 0 });
    const audioContext = useRef();
    const analyser = useRef();
    const canvasRef = useRef();
    const volumeRef = useRef();
    const canvasContext = useRef();
    const dataArrayRef = useRef();
    const offlineAudioContext = useRef();
    const analyserNode = useRef();
    const currentTime = useRef(0);
    const totalDuration = useRef(0);
    const hoverTimeDisplay = useRef(null);

    const barWidth = 1.5;
    const barSpacing = 2;
    let canvasHeight = 150;
    const limitBars = 1500;
    let canvasWidth;

    const reduceBarCount = (barCount, reducedBarCount) => {
        const step = barCount / reducedBarCount;
        let startFrom = 100;
        let index = 0;
        let reducedBars = [];
        for (let i = 0; i < reducedBarCount; i++) {
            let remaining = step * 100;
            let sum = 0;
            while (index !== volumeRef.current.length && remaining > 0) {
                sum += (volumeRef.current[index] * startFrom) / 100;
                remaining -= startFrom;
                if (remaining) {
                    if (remaining >= 100) startFrom = 100;
                    else startFrom = remaining;
                    index++;
                } else {
                    if (startFrom === 100) {
                        startFrom = 100;
                        index++;
                    } else startFrom = 100 - startFrom;
                }
            }
            sum /= step;
            reducedBars.push(sum);
        }
        return reducedBars;
    };

    const loop = () => {
        if (!audioPlayer.current) {
            cancelAnimationFrame(animationRef.current);
            return;
        }

        if (audioPlayer.current.paused) {
            return;
        }

        canvasContext.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

        window.requestAnimationFrame(loop);
        drawBars();
    };

    const preparation = async () => {
        audioContext.current = new (window.AudioContext || window.webkitAudioContext)();

        try {
            analyser.current = audioContext.current.createAnalyser();
            analyser.current.fftSize = 64;
            const src = audioContext.current.createMediaElementSource(audioPlayer.current);
            src.connect(analyser.current);
            analyser.current.connect(audioContext.current.destination);
            loop();
        } catch (error) {}
    };

    const setCanvasSize = () => {
        const fixedHeight = canvasHeight; // Set your desired fixed height
        const fixedWidthPercentage = 0.75; // Set the percentage of the inner width

        const newCanvasWidth = window.innerWidth * fixedWidthPercentage;

        canvasRef.current.width = newCanvasWidth;
        canvasRef.current.height = fixedHeight;
    };

    const formatTime = (timeInSeconds) => {
        if (isNaN(timeInSeconds)) return;

        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = Math.floor(timeInSeconds % 60);

        return padNumber(minutes) + ":" + padNumber(seconds);
    };

    const padNumber = (num) => {
        return num < 10 ? "0" + num : num;
    };

    const drawBars = () => {
        if (!canvasContext.current) {
            canvasContext.current = canvasRef.current.getContext("2d");
        }

        if (canvasContext.current && volumeRef.current) {
            if (totalDuration.current < props.seconds - 10 || totalDuration.current === 0) {
                totalDuration.current = props.seconds;
            }
            setCanvasSize();

            canvasContext.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
            canvasWidth = canvasRef.current.width;
            canvasHeight = canvasRef.current.height;
            const barCount = volumeRef.current.length;
            const totalBarWidth = barCount * (barWidth + barSpacing);
            if (totalBarWidth <= canvasWidth) {
                const spaceForBlankBars = (canvasWidth - totalBarWidth) / 2;
                const blankBars = Math.floor(spaceForBlankBars / (barWidth + barSpacing));
                const coveredBars = (currentTime.current * barCount) / totalDuration.current;
                let temp = [];
                let index = 0;

                for (let i = 0; i < barCount; i++) {
                    const isCovered = coveredBars > i;
                    temp = [volumeRef.current[i]];
                    drawSubBars(temp, index, true, isCovered);
                    index++;
                }

                for (let i = 0; i < 2 * blankBars; i++) {
                    temp = [1];
                    drawSubBars(temp, index, false);
                    index++;
                }
            } else {
                const reducedBarCount = Math.floor(canvasWidth / (barWidth + barSpacing));
                const reducedBars = reduceBarCount(barCount, reducedBarCount);
                const coveredBars = (currentTime.current * reducedBarCount) / totalDuration.current;

                reducedBars.map((Bar, i) => {
                    const isCovered = coveredBars > i;
                    drawSubBars([Bar], i, true, isCovered);
                });
            }

            const textMargin = 5;
            const textHeight = 11;
            const textX = canvasWidth - textMargin - 105;
            const textY = canvasHeight - textMargin - textHeight;

            canvasContext.current.fillStyle = "#ffffff";
            canvasContext.current.font = "14px Arial";
            canvasContext.current.fillText(formatTime(currentTime.current) + " - " + formatTime(totalDuration.current), textX, textY);
        }
    };

    const drawSubBars = (subData, index, solid, covered) => {
        const startX = index * (barWidth + barSpacing);
        let barHeight = 0;
        for (let i = 0; i < subData.length; i++) {
            barHeight += subData[i];
        }

        barHeight /= subData.length;
        const x = startX;
        const y = (canvasHeight - barHeight) / 2;

        if (covered) {
            canvasContext.current.fillStyle = "#88aabf";
        } else if (solid) {
            canvasContext.current.fillStyle = "#264c70";
        } else {
            canvasContext.current.fillStyle = "#416d972b";
        }
        canvasContext.current.fillRect(x, y, barWidth, barHeight);
    };

    const whilePlaying = () => {
        if (!audioPlayer.current) {
            cancelAnimationFrame(animationRef.current);
            return;
        }

        currentTime.current = audioPlayer.current.currentTime;
        const current = audioPlayer.current.currentTime;

        // this need as actual time is bit milliseconds lower than decoded duration
        if (counter.current.time === current) {
            counter.current.occ += 1;
        } else {
            counter.current = {
                time: current,
                occ: 1,
            };
        }
        if (counter.current.occ < 100 && current !== totalDuration.current) {
            animationRef.current = requestAnimationFrame(whilePlaying);
        } else {
            if (counter.current.occ >= 20 && current !== totalDuration.current) {
                totalDuration.current = current;
            }
            cancelAnimationFrame(animationRef.current);
            audioPlayer.current.currentTime = 0;
            currentTime.current = 0;
            audioPlayer.current.pause();
            props.togglePlayPause();
        }
    };

    const togglePlayPause = () => {
        if (!audioContext.current) {
            preparation();
            canvasContext.current = canvasRef.current.getContext("2d");
        }

        const curValue = props.isPlaying;

        try {
            if (curValue) {
                audioPlayer.current.play();
                audioContext.current.resume();
                loop();
                animationRef.current = requestAnimationFrame(whilePlaying);
            } else {
                audioPlayer.current.pause();
                cancelAnimationFrame(animationRef.current);
            }
        } catch (err) {
            //play pause clicked at time
        }
    };

    useEffect(() => {
        togglePlayPause();
    }, [props.isPlaying]);

    const handleCanvasClick = (event) => {
        if (!isDataGather.current) return;
        const canvasRect = canvasRef.current.getBoundingClientRect();
        const clickX = event.clientX - canvasRect.x;
        const canvasWidth = canvasRect.width;
        const currentTimeToChange = (totalDuration.current * clickX) / canvasWidth;

        audioPlayer.current.currentTime = currentTimeToChange;
        currentTime.current = currentTimeToChange;
        drawBars();
    };

    const handleCanvasHover = (event) => {
        if (!isDataGather.current) return;

        const canvasRect = canvasRef.current.getBoundingClientRect();
        const clickX = event.clientX - canvasRect.x;
        const canvasWidth = canvasRect.width;
        const currentTime = (totalDuration.current * clickX) / canvasWidth;
        setHoverPosition({
            x: clickX,
            y: canvasRect.height / 2,
            timex: event.clientX - (hoverTimeDisplay.current ? hoverTimeDisplay.current.offsetWidth / 2 : 0),
            timey: canvasRect.top - (hoverTimeDisplay.current ? hoverTimeDisplay.current.offsetHeight : 0),
            time: currentTime,
        });
    };

    useEffect(() => {
        audioPlayer.current.crossorigin = "anonymous";

        const handleResize = () => {
            drawBars();
        };

        if (!audioContext.current) {
            preparation();
            canvasContext.current = canvasRef.current.getContext("2d");
        }

        window.addEventListener("resize", handleResize);
        canvasRef.current.addEventListener("click", handleCanvasClick);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    function getVolume(frequencyData) {
        let normSamples = frequencyData.map((points) => points / 4);
        let sum = 0;

        for (let i = 0; i < frequencyData.length; i++) {
            sum += normSamples[i] * normSamples[i];
        }

        let volume = Math.sqrt(sum / normSamples.length);

        volume -= 10;

        if (volume <= 0) volume = 1;

        const volumeMappings = [
            { max: 1, value: 1 },
            { max: 2, value: 4 },
            { max: 3, value: 5 },
            { max: 4, value: 7 },
            { max: 5, value: 4 },
            { max: 6, value: 3 },
            { max: 7, value: 2 },
            { max: 8, value: 2 },
            { max: 9, value: 6 },
            { max: 10, value: 8 },

            { max: 11, value: 3 },
            { max: 12, value: 5 },
            { max: 13, value: 8 },
            { max: 14, value: 7 },
            { max: 15, value: 10 },
            { max: 16, value: 5 },
            { max: 17, value: 4 },
            { max: 18, value: 3 },
            { max: 19, value: 2 },
            { max: 20, value: 13 },

            { max: 21, value: 7 },
            { max: 22, value: 17 },
            { max: 23, value: 7 },
            { max: 24, value: 15 },
            { max: 25, value: 21 },
            { max: 26, value: 27 },
            { max: 27, value: 17 },
            { max: 28, value: 14 },
            { max: 29, value: 21 },
            { max: 30, value: 12 },

            { max: 31, value: 20 },
            { max: 32, value: 30 },
            { max: 33, value: 35 },
            { max: 34, value: 40 },
            { max: 35, value: 25 },
            { max: 36, value: 30 },
            { max: 37, value: 15 },
            { max: 38, value: 15 },
            { max: 39, value: 18 },
            { max: 40, value: 30 },

            { max: 41, value: 20 },
            { max: 42, value: 25 },
            { max: 43, value: 30 },
            { max: 44, value: 45 },
            { max: 45, value: 20 },
            { max: 46, value: 15 },
            { max: 47, value: 30 },
            { max: 48, value: 20 },
            { max: 49, value: 10 },
            { max: 50, value: 8 },

            { max: 51, value: 10 },
            { max: 52, value: 20 },
            { max: 53, value: 10 },
            { max: 54, value: 30 },
            { max: 55, value: 10 },
            { max: 56, value: 45 },
            { max: 57, value: 55 },
            { max: 58, value: 60 },
            { max: 59, value: 20 },
            { max: 60, value: 10 },

            { max: 61, value: 50 },
            { max: 62, value: 60 },
            { max: 63, value: 40 },
            { max: Infinity, value: 60 },
        ];

        let volumeFiltered = 1;
        for (const mapping of volumeMappings) {
            if (volume <= mapping.max) {
                volumeFiltered = mapping.value === 1 ? 2 : mapping.value * 3;
                break;
            }
        }

        return volumeFiltered;
    }

    useEffect(() => {
        if (volumeRef.current) {
            return;
        }
        volumeRef.current = [];
        const initializeAudio = async () => {
            const audioData = await fetch(props.url).then((response) => response.arrayBuffer());
            const audioBuffer = await new (window.AudioContext || window.webkitAudioContext)().decodeAudioData(audioData);
            offlineAudioContext.current = new OfflineAudioContext(audioBuffer.numberOfChannels, audioBuffer.length, audioBuffer.sampleRate);

            const audioBufferSourceNode = offlineAudioContext.current.createBufferSource();
            audioBufferSourceNode.buffer = audioBuffer;
            analyserNode.current = offlineAudioContext.current.createAnalyser();
            analyserNode.current.fftSize = 256;

            audioBufferSourceNode.connect(analyserNode.current);
            analyserNode.current.connect(offlineAudioContext.current.destination);

            audioBufferSourceNode.start();

            const bufferLength = analyserNode.current.frequencyBinCount;
            dataArrayRef.current = new Uint8Array(bufferLength);

            const renderQuantumInSeconds = 128 / audioBuffer.sampleRate;
            const durationInSeconds = audioBuffer.duration;
            totalDuration.current = durationInSeconds;

            const analyze = (index) => {
                try {
                    const suspendTime = renderQuantumInSeconds * index;
                    if (suspendTime < durationInSeconds) {
                        offlineAudioContext.current.suspend(suspendTime).then(() => {
                            analyserNode.current.getByteFrequencyData(dataArrayRef.current);
                            const volume = getVolume(dataArrayRef.current);
                            volumeRef.current.push(volume);
                            analyze(index + 1);
                        });
                    }

                    if (index === 1) {
                        offlineAudioContext.current.startRendering().then((buffer) => {
                            if (volumeRef.current.length > limitBars) {
                                volumeRef.current = reduceBarCount(volumeRef.current.length, limitBars);
                            }
                            isDataGather.current = true;
                            props.toggleCanvasCreated();
                        });
                    } else {
                        offlineAudioContext.current.resume();
                    }
                } catch (err) {}
            };

            analyze(1);
        };

        initializeAudio();
    }, []);

    useEffect(() => {
        if (audioPlayer.current && props.url) {
            audioPlayer.current.src = props.url;
            audioPlayer.current.load();
        }
      }, [props.url]);

    useEffect(() => {
        if (isDataGather.current || (volumeRef.current && volumeRef.current.length === 0)) {
            drawBars();
        }
    }, [isDataGather.current, volumeRef.current]);

    return (
        <div className="record-track-component-outermost">
            {hoverPosition && (
                <div
                    className="hover-position-time-display"
                    ref={hoverTimeDisplay}
                    style={{
                        left: hoverPosition.timex + "px",
                        top: hoverPosition.timey + "px",
                        display: "block",
                        height: (canvasRef.current ? canvasRef.current.height - hoverPosition.minus : 0) + "px",
                    }}
                >
                    {formatTime(hoverPosition.time)}
                </div>
            )}
            <div
                className="record-track-component"
                onMouseMove={(e) => {
                    handleCanvasHover(e);
                }}
                onMouseLeave={() => setHoverPosition(null)}
            >
                <audio
                    ref={audioPlayer}
                    src={props && props.url ? props.url : ""}
                    preload="metadata"
                    type="audio/wav"
                    crossOrigin="anonymous"
                ></audio>
                <canvas className="record-track-canvas" ref={canvasRef}></canvas>
                {hoverPosition && (
                    <div
                        className="bar-controller"
                        style={{
                            left: hoverPosition.x + "px",
                            top: hoverPosition.y + "px",
                            display: "block",
                            height: (canvasRef.current ? canvasRef.current.height - hoverPosition.minus : 0) + "px",
                        }}
                    ></div>
                )}
            </div>
        </div>
    );
}

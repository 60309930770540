import React from "react";
import "./NoFolder.Style.css";

const EmptyFolderComponent = () => {
    return (
        <div className="no-folder-component">
            <div className="no-folder-container">
                Add a folder
                {
                    //use chrome extension for easy recording and avaibility
                }
            </div>
        </div>
    );
};

export default EmptyFolderComponent;

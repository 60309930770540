import * as ActionTypes from "../../constants/ActionTypes";

export const createFeedback = (payload) => ({
    type: ActionTypes.CREATE_FEEDBACK,
    payload,
});

export const createFeedbackSuccess = (message) => ({
    type: ActionTypes.CREATE_FEEDBACK_SUCCESS,
    message,
});

export const createFeedbackFailure = (message) => ({
    type: ActionTypes.CREATE_FEEDBACK_FAILURE,
    message,
});

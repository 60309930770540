import * as ActionTypes from "../../constants/ActionTypes";

export const createSubscriber = (payload) => ({
    type: ActionTypes.CREATE_SUBSCRIBER,
    payload,
});

export const subscriberCreatedSuccess = (message) => ({
    type: ActionTypes.CREATE_SUBSCRIBER_SUCCESS,
    message,
});

export const createSubscriberFailure = (message) => ({
    type: ActionTypes.CREATE_SUBSCRIBER_FAILURE,
    message,
});

export const cancelSubscription = (payload) => ({
    type: ActionTypes.CANCEL_SUBSCRIPTION,
    payload,
});

export const subscriptionCancelSuccess = (message) => ({
    type: ActionTypes.CANCEL_SUBSCRIPTION_SUCCESS,
    message,
});

export const cancelSubscriptionFailure = (message) => ({
    type: ActionTypes.CANCEL_SUBSCRIPTION_FAILURE,
    message,
});

export const continueSubscription = (payload) => ({
    type: ActionTypes.CONTINUE_SUBSCRIPTION,
    payload,
});

export const subscriptionContinueSuccess = (message) => ({
    type: ActionTypes.CONTINUE_SUBSCRIPTION_SUCCESS,
    message,
});

export const continueSubscriptionFailure = (message) => ({
    type: ActionTypes.CONTINUE_SUBSCRIPTION_FAILURE,
    message,
});

export const customerPortal = (payload) => ({
    type: ActionTypes.CUSTOMER_PORTAL,
    payload,
});

export const customerPortalSuccess = (message) => ({
    type: ActionTypes.CUSTOMER_PORTAL_SUCCESS,
    message,
});

export const customerPortalFailure = (message) => ({
    type: ActionTypes.CUSTOMER_PORTAL_FAILURE,
    message,
});
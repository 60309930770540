import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { BsFillPlayCircleFill, BsFillPauseCircleFill } from "react-icons/bs";
import { requestInfo, requestLogout } from "../../redux/ActionCreator/Auth.ActionCreator";
import { deletePermission, sharedLoading, sharedlistLoading } from "../../redux/ActionCreator/Share.ActionCreator";
import "./RecordShare.Style.css";
import PageNotFoundComponent from "../Loading/PageNotFound.Component";
import ShareDisplayComponent from "./ShareForm.Component";
import CommonCanvasAudioTrackComponent from "./CommonCanvasAudioTrack.Component";
import LoadingComponent from "../Loading/LoadingUsed.Component";
import { clipsLoading } from "../../redux/ActionCreator/Clip.ActionCreator";
import ClipComponent from "./ClipedTo.Component";
import SeoComponent from "../seo/Seo.Component";

function RecordShareComponent() {
    const token = useSelector((state) => state.Auth.token);
    const { recordId } = useParams();
    const navigate = useNavigate();
    const Info = useSelector((state) => state.Auth.Info);
    const [owner, setOwner] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isListFetched, setIsListFetched] = useState(false);
    const [isClipFetched, setIsCliptFetched] = useState(false);
    const [isShareOpen, setIsShareOpen] = useState(false);
    const [isClipOpen, setIsClipOpen] = useState(false);
    const [hoveredCopyLink, setHoveredCopyLink] = useState(false);
    const [isCanvasCreated, setIsCanvasCreated] = useState(false);
    const [copyMessage, setCopyMessage] = useState("Copy Link");

    const called = useRef(false);
    const permitted = useRef(true);
    const dispatch = useDispatch();
    const record = useSelector((state) => state.Share.record);
    const isLoading = useSelector((state) => state.Share.isLoading);

    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const dropdownRef = useRef(null);

    const handleProfileClick = () => {
        return navigate("/profile");
    };

    const handleExtensionClick = () => {
        window.open("https://chromewebstore.google.com/detail/chflnpgoajpbkicbpbkddcfgoibejhmc", "_blank", "noreferrer");
    };

    const handleSignOut = () => {
        dispatch(requestLogout());
    };

    const formatteDate = () => {
        if (!record || !record.createdat) return;

        let startTime = new Date(record.createdat);
        const updatedAt = new Date(startTime.getTime() - startTime.getTimezoneOffset() * 60000);

        const formatted = updatedAt.toLocaleString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        });
        return formatted;
    };

    const loadShareList = () => {
        if (isListFetched) {
            return;
        }

        const data = {
            id: recordId,
        };
        called.current = true;
        dispatch(sharedlistLoading(data));
        setIsListFetched(true);
    };

    const handleUnshareClick = () => {
        const payload = {
            recordId: recordId,
        };
        dispatch(deletePermission(payload));
    };

    const loadClipList = () => {
        if (isClipFetched) {
            return;
        }

        const data = {
            id: recordId,
        };
        dispatch(clipsLoading(data));
        setIsCliptFetched(true);
    };

    const copyToClipboard = () => {
        const currentURL = window.location.href;

        const textarea = document.createElement("textarea");
        textarea.value = currentURL;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);

        setCopyMessage("Link copied!");
    };

    const headerSection = (
        <div className="record-share-header-section">
            <div
                className="record-share-left"
                onClick={() => {
                    navigate("/record/common");
                }}
            >
                EchoVault
            </div>
            <div className="record-share-right">
                {
                    <span
                        className="record-share-copy-link"
                        onMouseEnter={() => {
                            setHoveredCopyLink(true);
                            setCopyMessage("Copy Link");
                        }}
                        onMouseLeave={() => {
                            setHoveredCopyLink(false);
                        }}
                        onClick={() => {
                            copyToClipboard();
                        }}
                    >
                        {hoveredCopyLink && (
                            <div className={`copy-link-tooltip`}>
                                <div className={`copy-link-tooltip-text`}>{copyMessage}</div>
                            </div>
                        )}
                    </span>
                }
                <div
                    className="logo-container"
                    ref={dropdownRef}
                    onClick={(e) => {
                        e.stopPropagation();
                        setIsDropdownVisible(!isDropdownVisible);
                    }}
                >
                    <div className="logo-circle">
                        <span className="logo-text">{Info.name ? Info.name[0].toUpperCase() : "0"}</span>
                    </div>
                    {isDropdownVisible && (
                        <div className="dropdown-user-info dropdown-shared">
                            <ul>
                                <li
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleProfileClick();
                                    }}
                                >
                                    Profile
                                </li>
                                <li
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleExtensionClick();
                                    }}
                                >
                                    Get extension
                                </li>
                                <li
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleSignOut();
                                        setIsDropdownVisible(false);
                                    }}
                                >
                                    Sign Out
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );

    const bodySection = (
        <div className="record-share-body-section">
            <div className="record-share-record-title">{record ? record.recordname : ""}</div>
            <div className="record-share-controls">
                <button
                    className="record-share-play-pause-button"
                    disabled={!isCanvasCreated}
                    onClick={() => {
                        setIsPlaying(!isPlaying);
                    }}
                >
                    {isPlaying ? <BsFillPauseCircleFill /> : <BsFillPlayCircleFill className="play" />}
                </button>
                <CommonCanvasAudioTrackComponent
                    url={record ? record.url : ""}
                    seconds={record ? record.seconds : 0}
                    isPlaying={isPlaying}
                    firebaseRecordName={record ? record.firebaserecordname : ""}
                    togglePlayPause={() => {
                        setIsPlaying(false);
                    }}
                    toggleCanvasCreated={() => {
                        setIsCanvasCreated(true);
                    }}
                />
            </div>
            <div className="record-share-datetime">{formatteDate()}</div>
            {
                !owner &&
                <div className="record-share-datetime">Shared by {record && record.ownername}</div>
            }
            <div className="record-share-main-buttons">
            {owner 
            ?
                <div className="record-share-shared-dropdown">
                    <button
                        className="record-share-button"
                        onClick={() => {
                            loadShareList();
                            setIsShareOpen(true);
                        }}
                    >
                        Share +
                    </button>
                </div>
            :
                <div className="record-share-shared-dropdown">
                    <button
                        className="record-share-button"
                        onClick={() => {
                            handleUnshareClick()
                        }}
                    >
                        Unshare
                    </button>
                </div>
                
            }
            {
                   <div className="record-share-shared-dropdown share-record-clips-button">
                   <button
                       className="record-share-button"
                       onClick={() => {
                           loadClipList();
                           setIsClipOpen(true);
                       }}
                   >
                       Clips
                   </button>
               </div>
            }
            </div>
        </div>
    );

    const checkToken = () => {
        if (token === null) {
            return navigate("/auth");
        }
    };

    useEffect(() => {
        checkToken();
    }, [token]);

    useEffect(() => {
        if (!Info.name || Info.name === "0") {
            dispatch(requestInfo());
        }

        if (!token) return;

        checkToken();
    }, []);

    const checkPermissions = () => {
        if (!record || !Info) return;

        if (Info.id === record.userid && record.userid) {
            setOwner(true);
            permitted.current = true;
        } else if (record.shared) {
            setOwner(false);
            permitted.current = true;
        } else {
            setOwner(false);
            permitted.current = false;
        }
    };

    useEffect(() => {
        if (!Info) {
            return;
        }

        if (record && record.id === recordId) {
            checkPermissions();
            return;
        }
    }, [Info]);

    useEffect(() => {
        if (record && record.id === recordId) {
            checkPermissions();
            return;
        }

        if (called.current) return;

        const data = {
            id: recordId,
        };
        called.current = true;
        dispatch(sharedLoading(data));
    }, []);

    useEffect(() => {
        if (!record) return;
        checkPermissions();
    }, [record]);

    return (
        <>
            <SeoComponent
                title = 'Share'
                description = 'Share your audio records online with anyone, anytime with just a click'
                name = 'Echovault'
                type = 'website' />
            {isShareOpen && <ShareDisplayComponent setIsShareOpen={setIsShareOpen} recordId={recordId} />}
            {isClipOpen && <ClipComponent setIsClipOpen={setIsClipOpen} recordId={recordId} Owner = {owner}/>}
            {isLoading ? (
                <LoadingComponent />
            ) : !permitted.current ? (
                <PageNotFoundComponent />
            ) : (
                <div className="RecordShareComponentDiv">
                    {headerSection}
                    {bodySection}
                </div>
            )}
        </>
    );
}

export default RecordShareComponent;

import React, { useEffect, useState } from "react";
import "./Planes.Style.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import HeaderComponent from "../Header/HeaderRegistrationPage.Component";
import { requestInfo } from "../../redux/ActionCreator/Auth.ActionCreator";
import { createSubscriber } from "../../redux/ActionCreator/Subscription.ActionCreator";
import Select from 'react-select'
import SeoComponent from "../seo/Seo.Component";

function PricingPlans() {
    const token = useSelector((state) => state.Auth.token);
    const Info = useSelector((state) => state.Auth.Info);
    const isLoading = useSelector((state) => state.Auth.isLoading);
    const checkoutSession = useSelector((state) => state.Subscription.checkoutSession);
    const subscriptionType = useSelector((state) => state.Subscription.subscriptionType);
    const [selectedOption, setSelectedOption] = useState("USD");
    const [currency, setCurrency] = useState('$3');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const options = [
        { value: 'USD', label: 'USD' },
        { value: 'EUR', label: 'EUR' },
        { value: 'GBP', label: 'GBP' },
    ];

    const setSelectedPlane = (option) => {
        setSelectedOption(option.value);
        setCurrency(getPlanPrice(option.value));
    };

    const customStyles = {
        option: (defaultStyles, state) => ({
            ...defaultStyles,
            backgroundColor: state.isSelected ? "#a0a0a0" : "#fff",
            margin: "3px 3px 3px 8px",
            pading: "2px",
            "max-width": "80px",
            "border-radius": "12px",
            overflow: "hidden",
            cursor: "pointer",
            color: state.isSelected ? 'white' : 'black',
            '&:hover': {
                backgroundColor: '#a0a0a0',
                color: 'white'
            }
        }),

        menuList: (base) => ({
            ...base,

            "::-webkit-scrollbar": {
                width: "7px",
                height: "0px",
                "border-radius": "15px",
            },
            "::-webkit-scrollbar-track": {
                background: "#f1f1f1",
                "border-radius": "15px",
            },
            "::-webkit-scrollbar-thumb": {
                background: "#888",
                "border-radius": "15px",
            },
            "::-webkit-scrollbar-thumb:hover": {
                background: "#555",
                "border-radius": "15px",
            },
        }),

        menu: (provided) => ({
            ...provided,
            width: "95px",
            "border-radius": "8px",
            marginbottom: 0,
            boxshadow: "none",
            overflow: "hidden",
        }),

        control: (defaultStyles) => ({
            ...defaultStyles,
            width: "95px",
            backgroundColor: "transparent",
            border: '1px solid #ccc',
            boxShadow: "none",
            "font-size": "16px",
            "border-radius": "5px",
            overflow: "hidden",
            '&:hover': {
                border: '1px solid gray'
            }
        }),
        singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#141129" }),
    };

    const handleSubscribeClick = () => {
        if (token) {
            const payload = {
                currency: selectedOption
            };
            dispatch(createSubscriber(payload));
        } else {
            return navigate("/auth");
        }
    };

    useEffect(()=>{
        if(checkoutSession)
        {
            window.open(checkoutSession, "_self", "noreferrer");
        }
    },[checkoutSession]);

    useEffect(() => {
        if (!Info.name || Info.name === "0") {
            dispatch(requestInfo());
        }
    }, []);

    const getPlanPrice = (selected) => {
        switch (selected) {
            case 'USD':
                return '$3';
            case 'EUR':
                return '€3';
            case 'GBP':
                return '£2.49';
            default:
                return '₹249';
        }
    };

    return (
        <div className="PricingPlansComponent">
            <SeoComponent
                title = 'Pricing'
                description = 'Choose best plane which fits your requirement'
                name = 'Echovault'
                type = 'website' />
            <HeaderComponent redirectTo={"/auth"} noredirect={true} />
            <div className="pricing-heading-container">
                <h2 className="pricing-heading-title">Choose your plan & record now</h2>
            </div>

            <div className="pricing-plans-container">
                <div className="pricing-plan-card">
                    <h3 className="pricing-plan-title">Basic</h3>
                    <p className="pricing-plan-description">Basic plan for getting started</p>
                    <div className="pricing-plan-price-container">
                        <div className="pricing-plan-price">
                            <span className="pricing-price-amount">&nbsp;Free</span>
                        </div>
                    </div>
                    <ul className="pricing-plan-features">
                        <li className="pricing-plan-feature">
                            <svg viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="pricing-tick-li">
                                <polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                            <span>Up to 50 records/user</span>
                        </li>
                        <li className="pricing-plan-feature">
                            <svg viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="pricing-tick-li">
                                <polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                            <span>Up to 5 mins/record</span>
                        </li>
                        <li className="pricing-plan-feature">
                            <svg viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="pricing-tick-li">
                                <polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                            <span>1 share/record</span>
                        </li>
                    </ul>
                </div>

                <div className="pricing-plan-card">
                    <h3 className="pricing-plan-title">Plus</h3>
                    <p className="pricing-plan-description">Most popular plan</p>
                    <div className="pricing-plan-price-container">
                        <div className="pricing-plan-price">
                            <span>
                                <Select 
                                    options = {options}
                                    defaultValue = {options[0]}
                                    styles = {customStyles}
                                    onChange = {setSelectedPlane}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                />
                            </span>
                            <span className="pricing-price-amount">&nbsp;{currency}</span>
                            <span>Monthly</span>
                        </div>
                    </div>
                    <ul className="pricing-plan-features">
                        <li className="pricing-plan-feature">
                            <svg viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="pricing-tick-li">
                                <polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                            <span>Unlimited records</span>
                        </li>
                        <li className="pricing-plan-feature">
                            <svg viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="pricing-tick-li">
                                <polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                            <span>Unlimited record duration</span>
                        </li>
                        <li className="pricing-plan-feature">
                            <svg viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="pricing-tick-li">
                                <polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                            <span>Unlimited share/record</span>
                        </li>
                        <li className="pricing-plan-feature">
                            <svg viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="pricing-tick-li">
                                <polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                            <span>Download record file</span>
                        </li>
                    </ul>
                    {
                        isLoading
                        ?
                        <a        
                        className="pricing-plan-button grayed-text loading-icon-subscription"
                        >
                            
                        </a>
                        :
                        <>
                            {(subscriptionType !== process.env.REACT_APP_PLUS_SUBSCRIPTION)
                            ?
                                <a
                                onClick={() => {
                                    handleSubscribeClick();
                                }}
                                className="pricing-plan-button"
                                >
                                    Select Plan
                                </a>
                            :
                                <a
                                className="pricing-plan-button grayed-text"
                                >
                                    Subscribed
                                </a>}
                        </>
                    }
                </div>
            </div>
        </div>
    );
}

export default PricingPlans;

import * as ActionTypes from "../../constants/ActionTypes";
import { InitialState } from "../../constants/InitialStates";

export const ErrorMess = (state = InitialState.ErrorMess, action) => {
    switch (action.type) {
        case ActionTypes.ERROR_MESS:
            return { ...state, errMess: action.payload };

        default:
            return state;
    }
};

export const LOGIN_PATH = "user/login";
export const GOOGLE_LOGIN_PATH = "user/google-auth";
export const LOGOUT_PATH = "user/logout";
export const SIGNUP_PATH = "user/signup";
export const AUTHENTICATION_CHECK_PATH = "user/check-cookie";
export const INFO_PATH = "user/getInfo";
export const CHANGE_INFO_PATH = "user/change-info";
export const DELETE_PROFILE_PATH = "user/remove-user";
export const GET_OTP_PATH = "user/otp-auth";
export const VERIFY_OTP_PATH = "user/verify-otp";
export const CHANGE_PASSWORD_PATH = "user/change-password";

export const GET_ALL_FOLDERS_PATH = "folder";
export const CREATE_FOLDER_PATH = "folder/create-folder";
export const DELETE_FOLDER_PATH = "folder/remove-folder/"; // foldername path param
export const DEFAULT_FOLDER_PATH = "folder/default-folders";
export const RENAME_FOLDER_PATH = "folder/rename-folder";

export const GET_RECORDS_PATH = "/record/get-record/"; // folder_id path param
export const GET_SEARCH_PATH = "/record/search/"; // recordName path param
export const DELETE_RECORDS_PATH = "/record/remove-record/"; // record_id path param
export const RENAME_RECORD_PATH = "/record/change-record-name";
export const CREATE_RECORD_PATH = "/record/create-record";
export const CHANGE_FOLDER_PATH = "/record/change-folder";
export const DOWNLOAD_RECORD_PATH = "record/download-record/"; //record_id path param

export const SHARED_RECORDS_DETAIL_PATH = "/shared/"; // record_id path param
export const SHARED_PEOPLE_LIST_PATH = "/shared/permitted/"; // record_id path param
export const CREATE_SHARE_PERMISSION_PATH = "/shared/create-user-permission";
export const REMOVE_SHARE_PERMISSION_PATH = "/shared/remove-user-permission";

export const CREATE_SUBSCRIBER_PATH = "/subscription/create-subscription";
export const CANCEL_SUBSCRIPTION_PATH = "/subscription/cancel-subscription";
export const CONTINUE_SUBSCRIPTION_PATH = "/subscription/continue-subscription";
export const CUSTOMER_PORTAL_PATH = "/subscription/customer-portal";

export const GET_CLIP_PATH = "/clip/urls/"; //recordId path param
export const CREATE_CLIPS_PATH = "/clip/clip-record";
export const UNCLIP_PATH = "/clip/remove-clip";

export const CREATE_FEEDBACK_PATH = "/feedback/create-feedback";

export const CREATE_HELP_PATH = "/help/create-help";

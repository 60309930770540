import * as ActionTypes from "../../constants/ActionTypes";
import { InitialState } from "../../constants/InitialStates";

export const Auth = (state = InitialState.Auth, action) => {
    switch (action.type) {
        case ActionTypes.LOGIN_REQUEST:
            return { ...state, isLoading: true, isAuthenticated: false };
        case ActionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                token: 1,
                Info: action.data.Info,
            };
        case ActionTypes.LOGIN_FAILURE:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMess: action.message,
                token: null,
                Info: {},
            };
        case ActionTypes.GOOGLE_LOGIN_REQUEST:
            return { ...state, isLoading: true, isAuthenticated: false };
        case ActionTypes.GOOGLE_LOGIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                token: 1,
                Info: action.data.Info,
            };
        case ActionTypes.GOOGLE_LOGIN_FAILURE:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMess: action.message,
                token: null,
                Info: {},
            };
        case ActionTypes.SIGNUP_REQUEST:
            return { ...state, isLoading: true, isAuthenticated: true };
        case ActionTypes.SIGNUP_SUCCESS:
            return { ...state, isLoading: false, isAuthenticated: true };
        case ActionTypes.SIGNUP_FAILURE:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMess: action.message,
            };
        case ActionTypes.LOGOUT_REQUEST:
            return { ...state, isLoading: true, isAuthenticated: true };
        case ActionTypes.LOGOUT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                token: null,
                Info: {},
            };
        case ActionTypes.INFO_REQUEST:
            return { ...state, isLoading: true, errMess: null, Info: {} };
        case ActionTypes.INFO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                errMess: null,
                Info: action.Info,
            };
        case ActionTypes.INFO_FAILURE:
            return {
                ...state,
                isLoading: false,
                errMess: action.message,
                Info: {},
            };
        case ActionTypes.CHANGE_INFO_REQUEST:
            return { ...state, isLoading: true };
        case ActionTypes.CHANGE_INFO_SUCCESS: {
            const Info = state.Info;
            Info.name = action.Info.name;
            Info.username = action.Info.username;
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                token: 1,
                Info: Info,
            };
        }
        case ActionTypes.CHANGE_INFO_FAILURE: {
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMess: action.data.status,
            };
        }
        case ActionTypes.DELETE_PROFILE_REQUEST: {
            return { ...state, isLoading: true };
        }
        case ActionTypes.DELETE_PROFILE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                token: null,
                Info: {},
            };
        }
        case ActionTypes.DELETE_PROFILE_FAILURE: {
            return { ...state, isLoading: false, errMess: action.payload };
        }
        case ActionTypes.OTP_REQUEST:
            return { ...state, isLoading: true, isOtpRetrived: false };
        case ActionTypes.OTP_SUCCESS:
            return { ...state, isLoading: false, isOtpRetrived: true };
        case ActionTypes.OTP_FAILURE:
            return { ...state, isLoading: false, isOtpRetrived: false };
        case ActionTypes.VERIFIED_REQUEST:
            return { ...state, isLoading: true };
        case ActionTypes.VERIFIED_SUCCESS:
            return { ...state, isLoading: false };
        case ActionTypes.VERIFIED_FAILURE:
            return { ...state, isLoading: false };
        case ActionTypes.CHANGE_PASSWORD_REQUEST:
            return { ...state, isPasswordChanged: false };
        case ActionTypes.CHANGE_PASSWORD_SUCCESS:
            return { ...state, isPasswordChanged: true };
        case ActionTypes.CHANGE_INFO_FAILURE:
            return { ...state, isPasswordChanged: false };
        case ActionTypes.DELETE_RECORD_SUCCESS: {
            const Info = state.Info;
            if (Info.totalrecords) {
                Info.totalrecords = Info.totalrecords - 1;
                Info.totalsize = Info.totalsize - action.payload[0].size;
            }
            return { ...state, Info: Info };
        }
        case ActionTypes.CREATE_RECORD_SUCCESS: {
            const Info = state.Info;
            if (Info.totalrecords) {
                Info.totalrecords = Info.totalrecords + 1;
                Info.totalsize = Info.totalsize + action.payload.size;
            }
            return { ...state, Info: Info };
        }
        default:
            return state;
    }
};

import { useNavigate, useLocation, redirect } from "react-router-dom";
import "./Sidebar.Style.css";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineHome } from "react-icons/ai";
import { MdPersonOutline } from "react-icons/md";
import { BiExtension } from "react-icons/bi";
import { PiSignOutBold } from "react-icons/pi";
import { IoIosArrowBack } from "react-icons/io";
import { Subscription_Feature_Switch } from "../../constants/FeatureSwitch";
import { requestLogout } from "../../redux/ActionCreator/Auth.ActionCreator";
import { IoIosHelpCircleOutline } from "react-icons/io";

function SidebarComponent(props) {
    const Info = useSelector((state) => state.Auth.Info);
    const currentLocation = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleRedirectProfile = () => {
        return navigate('/profile');
    };

    const handleRedirectHome = () => {
        return navigate('/record/common');
    };

    const handlePrivacyClick = () => {
        return navigate('/privacy-policy');
    }

    const handleTermsClick = () => {
        return navigate('/terms-condition');
    }

    const handleSubscriptionClick = () => {
        if(!Subscription_Feature_Switch)
        return;
        return navigate("/pricing");
    };

    const handleRedirectHelp = () => {
        return navigate("/contact");
    };

    const handleExtensionClick = () => {
        window.open("https://chromewebstore.google.com/detail/chflnpgoajpbkicbpbkddcfgoibejhmc", "_blank", "noreferrer");
    };
    
    const handleSignOutClick = () => {
        dispatch(requestLogout());
    };

    return (
        <div className={`sidebar-wrapper`}>
            {props.showCloseIcon ? <IoIosArrowBack className="sidebar-close-icon" onClick = {props.toggleSidebar} /> : ''}
            <div className="sidebar-logo">
            <img src="/vector/logo1.png" className="logoImg sidebarlogo" alt="logo" />
            </div>
            <div className="sidebar-site-name">EchoVault</div>
            <div className="sidebar-text-links">
                <a
                    className={`sidebar-text-link ${(currentLocation.pathname === "/record/common" || 
                    currentLocation.pathname === "/record/favourite" || 
                    currentLocation.pathname === "/record/folder") ? "sidebar-item-selected" : ""}`}
                    onClick = {(e) => {
                        e.stopPropagation();
                        handleRedirectHome();
                    }}
                >
                    <AiOutlineHome className="sidebar-icon home-icon" /> <span className="sidebar-item-name">Home</span>
                </a>
                <a
                    className={`sidebar-text-link ${currentLocation.pathname === "/profile" ? "sidebar-item-selected" : ""}`}
                    onClick = {(e) => {
                        e.stopPropagation();
                        handleRedirectProfile();
                    }}
                >
                    <MdPersonOutline className="sidebar-icon profile-icon" /> <span className="sidebar-item-name">Profile</span>
                </a>
                <a
                    className="sidebar-text-link"
                    onClick = {(e) => {
                        e.stopPropagation();
                        handleExtensionClick();
                    }}
                >
                    <BiExtension className="sidebar-icon extension-icon" /> <span className="sidebar-item-name">Chrome Extension</span>
                </a>
                <a
                    className={`sidebar-text-link ${currentLocation.pathname === "/contact" ? "sidebar-item-selected" : ""}`}
                    onClick = {(e) => {
                        e.stopPropagation();
                        handleRedirectHelp();
                    }}
                >
                    <IoIosHelpCircleOutline className="sidebar-icon" /> <span className="sidebar-item-name">Help</span>
                </a>
            </div>
            <div className="sidebar-signout">
                <div className="logo-circle">
                    <span className="logo-text">{Info.name ? Info.name[0].toUpperCase() : ""}</span>
                </div>
                <div className="sidebar-signout-texts">
                    <span className="sidebar-item-name">{Info.name ? (Info.name.charAt(0).toUpperCase()+ Info.name.slice(1)) : ""}</span>
                    <a
                        className="sidebar-text-link signoutname"
                        onClick = {(e) => {
                            e.stopPropagation();
                            handleSignOutClick();
                        }}
                    >
                        <span>Sign Out</span>
                    </a>
                </div>
                <a
                    className="sidebar-text-link signoutname icon-signout"
                    onClick = {(e) => {
                        e.stopPropagation();
                        handleSignOutClick();
                    }}
                >
                    <PiSignOutBold className="sidebar-icon sign-out-icon" />
                </a>
            </div>
        </div>
    );
}

export default SidebarComponent;

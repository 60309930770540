import React from "react";
import './Policy.Style.css';
import HeaderComponent from "../Header/HeaderRegistrationPage.Component";
import FooterComponent from "../Footer/Footer.Component";
import SeoComponent from "../seo/Seo.Component";

function TermsOfServicesComponent(props) {
    
    return (
        <div className="policy-wrapper">
            <SeoComponent
                title = 'Terms & Condition'
                description = 'Terms & Condition'
                name = 'Echovault'
                type = 'website' />
            <HeaderComponent redirectTo={"/auth"} noredirect={true}/>
            <div className="term-of-services-componenet">
                <h1 className="policy-title">Terms and Conditions</h1>
                <p className="policy-subsection-information">Effective: March 31, 2024</p>
                <p className="policy-subsection-information">Welcome to Echovault!  Echovault offers a cloud-based platform for recording, and sharing remote audio and other uses, 
                    available online. Your access to and utilization of Echovault's Services are governed by these Terms. By engaging with our Services, you consent to abide by these 
                    Terms. Should you utilize our Services on behalf of an entity or organization, you are obligating that entity to adhere to these Terms, and you affirm that you possess 
                    the requisite authority to do so.
                </p>
                <div className="policy-subsection">
                    {/* section 1*/}
                    <h2 className="policy-subsection-heading">1. Echovault's Services and Regestration</h2>
                    <p className="policy-subsection-information">
                        <strong className="policy-subsection-subtopic"> 1.1 Account. </strong>
                        To access and utilize the Echovault Platform, you're required to create an account. When setting up your account you create your user profile, you agree to, 
                        furnish accurate and complete information, safeguard the confidentiality of your account and password, assume responsibility for all activities occurring 
                        under your account, and promptly notify Echovault in writing upon discovering any security breaches or unauthorized access or usage of your account. 
                        You're accountable for the actions of all users accessing or utilizing the Platform through your account and must ensure their compliance with the Terms 
                        outlined herein. Echovault may presume that any communication originating from your account was made by you. You bear sole responsibility and liability for 
                        any losses, damages, liabilities, and expenses incurred by us or a third party due to unauthorized account usage by you, another user, or a third party acting 
                        on your behalf. Echovault reserves the right to suspend your account if there's reasonable suspicion of you or anyone on your behalf violating these Terms.
                        <br/>
                        <br/>
                        You acknowledge and agree that we may request information to verify your identity and enhance account security. Should you lose access to an account or 
                        require account-related information, Echovault retains the right to request any necessary verification before restoring access or providing information.
                        <br/>
                        <br/>
                        You're exclusively liable for understanding and managing the settings, privileges, and controls for the Platform, including determining user access and 
                        privileges, such as inviting guests, accessing, modifying, or sharing recordings, etc. You're responsible for the actions of all users, including any
                        orders they may place. You recognize that any actions taken by a user of an account are deemed authorized by us, and you relinquish any claim in this regard.
                    </p>
                    <p className="policy-subsection-information">
                        <strong className="policy-subsection-subtopic"> 1.2 Modification or Discontinuation. </strong>
                        Echovault retains the authority to add, alter, or terminate any feature or functionality of the Platform at any time, with no prior notice.
                        <br/>
                        <br/>
                        The software may undergo automatic updates. In cases where a component of the software incorporates open-source software, the open-source license applicable 
                        to that component will govern its use.
                    </p>

                    <p className="policy-subsection-information">
                        <strong className="policy-subsection-subtopic"> 1.3 Restriction. </strong>
                        You affirm that you are legally of age in your jurisdiction to enter into a binding contract, and in any case, you are at least thirteen (13) years old. 
                        Individuals under the age of thirteen (13) are prohibited from using the Platform. If you are between thirteen (13) and eighteen (18) years old, you must 
                        review these Terms with a parent or guardian, have them accept it on your behalf, and approve your usage of the Platform.                    
                        <br/>
                        <br/>
                        Customers shall not, and shall ensure that Authorized Users do not: interfere with, disrupt, or circumvent any security measures or mechanisms designed to 
                        protect the Services; reverse engineer, disassemble to obtain or derive the source code, underlying ideas, algorithms, file formats, or non-public APIs 
                        of the Services; remove or obscure any proprietary or other notices contained in the Services; engaging in harassment, threats, spamming, or causing 
                        discomfort to any person or entity; misusing the Services in any manner, including in contravention of these Terms or applicable law. Any utilization of 
                        data mining, robots, or similar data gathering and extraction tools, or framing all or any portion of the Services without Echovault's permission, is strictly 
                        prohibited.  Customers shall take measures to prevent, terminate, and notify Echovault of any unauthorized or illegal use of or access to their or any 
                        Authorized User account or the Services.
                    </p>

                    <p className="policy-subsection-information">
                        <strong className="policy-subsection-subtopic"> 1.4 Third Party Features. </strong>
                        The Platform may utilize third-party cloud infrastructure providers, such as Firebase. You acknowledge that Echovault holds no responsibility for such 
                        third-party services, and each respective third-party service shall be governed by its own applicable terms and conditions. If you encounter any issues 
                        resulting from the use of any third-party services, or if you experience data loss or other setbacks due to problems with any other service providers or 
                        third-party services, Echovault bears no responsibility for Third-Party Apps and does not guarantee or provide support for them. By enabling a Third-Party 
                        App that accesses Customer Data, you authorize the transfer of that data to the Third-Party App and, if necessary, will ensure the presence of a legally 
                        valid data transfer mechanism for the transfer or onward transfer, in compliance with all relevant privacy and data protection laws.
                    </p>

                    <p className="policy-subsection-information">
                        <strong className="policy-subsection-subtopic"> 1.5 Software. </strong>
                        Echovault may provide Software as part of its Services. In accordance with these Terms, Echovault hereby grants Customer and its Authorized Users a limited, 
                        non-exclusive, non-transferable, and non-sublicensable license to download and utilize the Software to the extent required for accessing the Services. The 
                        Software may undergo automatic updates. If the Software includes any open source components, the governing open source license for that particular software 
                        will prevail for that component.
                    </p>

                    {/* section 2*/}
                    <h2 className="policy-subsection-heading">2. Payment Terms</h2>
                    <p className="policy-subsection-information">
                        <strong className="policy-subsection-subtopic"> 2.1 Fees. </strong>
                        The customer agrees to remit the Fees as outlined in the payment terms specified on the Order. Additionally, the customer is accountable for settling 
                        any applicable Taxes.
                    </p>
                    <p className="policy-subsection-information">
                        <strong className="policy-subsection-subtopic"> 2.2 Renewals. </strong>
                        Your subscription to the Platform will automatically renew for the same subscription period at the end of the then current subscription period. To 
                        cancel such automatic renewal, you must unsubscribe before the next payment date stated in user profile section.
                    </p>
                    <p className="policy-subsection-information">
                        <strong className="policy-subsection-subtopic"> 2.3 Price Changes. </strong>
                        If Echovault adjusts its pricing, a notice of at least 30 days will be provided, and the new pricing will be applicable upon renewal. In the absence 
                        of subscription termination by the Customer before renewal, Echovault is authorized to collect payment for the adjusted pricing.
                    </p>
                    <p className="policy-subsection-information">
                        <strong className="policy-subsection-subtopic"> 2.4 Downgrades. </strong>
                        If the Customer neglects to pay the Fees on time, Echovault reserves the right to downgrade the Customer to a free plan. In the event of a downgrade, 
                        the Customer may lose access to certain features or functionality, but these Terms will remain in effect.                
                    </p>

                    {/* section 3*/}
                    <h2 className="policy-subsection-heading">3. Intellectual Property Rights</h2>
                    <p className="policy-subsection-information">
                        <strong className="policy-subsection-subtopic">3.1 Ownership. </strong>
                        Except as explicitly mentioned, these Terms do not confer any rights, whether implied or otherwise, to any intellectual property. The Customer retains 
                        ownership, and will continue to do so, of all intellectual property rights associated with Customer Data, while Echovault maintains ownership, and will 
                        continue to do so, of all intellectual property rights related to the Services.
                    </p>
                    <p className="policy-subsection-information">
                        <strong className="policy-subsection-subtopic"> 3.2 License. </strong>
                        The Customer grants Echovault a global, non-exclusive license to access, utilize, process, copy, modify, distribute, perform, export, and display Customer 
                        Data solely for the purpose of providing the Services or as otherwise outlined in these Terms. The Customer asserts and guarantees that it has obtained all 
                        requisite licenses to provide the aforementioned license.
                    </p>
                    <p className="policy-subsection-information">
                        <strong className="policy-subsection-subtopic"> 3.3 Feedback. </strong>
                        As a user of the Platform, you have the option to offer suggestions, comments, feature requests, or other feedback to Echovault ("Feedback"). This Feedback 
                        is considered an essential component of Echovault and, therefore, becomes the exclusive property of Echovault without any restrictions or limitations on its use.
                    </p>
                    
                    {/* section 4*/}
                    <h2 className="policy-subsection-heading">4. Confidentiality. </h2>
                    <p className="policy-subsection-information">
                        Either party (referred to as the "Disclosing Party") may share certain confidential information regarding its technology and business (referred to as "Confidential 
                        Information") with the other party (referred to as the "Receiving Party"). Echovault's Confidential Information includes, but is not limited to, pricing details. 
                        Subject to the terms and conditions outlined herein, the Receiving Party agrees to maintain the confidentiality of and refrain from disclosing or utilizing any 
                        Confidential Information, except to support the use or provision of the Services. The Receiving Party must limit the disclosure of Confidential Information to its 
                        Affiliates, employees, agents, consultants, and subcontractors who have a legitimate need to access such information and who are bound by written confidentiality 
                        agreements that are no less stringent than those detailed herein.
                    </p>

                    {/* section 5*/}
                    <h2 className="policy-subsection-heading">5. Privacy. </h2>
                    <p className="policy-subsection-information">
                        Your use of Echovault is subject to Echovault Privacy Policy. Please review our <a href="/privacy-policy">Privacy Policy</a>.
                    </p>

                    {/* section 6*/}
                    <h2 className="policy-subsection-heading">6. Warranty and Disclaimer. </h2>
                    <p className="policy-subsection-information">
                        <strong className="policy-subsection-subtopic"> 6.1 Warranties. </strong>Each party represents and warrants that these Terms constitute a legal, valid and binding 
                        obligation, enforceable against it and in accordance with these Terms, and its execution and delivery of the Terms and its performance hereunder will not violate 
                        any applicable law, rule or regulation.
                    <br/>
                    <br/>
                        Echovault is not responsible for performance issues caused by low-performance of your computers, microphones or cameras and low-bandwidth internet connections. If Echovault 
                        identifies slow performance due to its system, Echovault shall use commercially reasonable efforts to restore the Platform to acceptable performance levels. Your sole remedy 
                        for any disruption or failure to provide support shall be termination of these Terms.
                    </p>
                    <p className="policy-subsection-information">
                        <strong className="policy-subsection-subtopic"> 6.2 Disclaimer. </strong> NOTWITHSTANDING ANYTHING IN THESE TERMS OR ELSEWHERE TO THE CONTRARY AND TO THE FULLEST EXTENT PERMITTED 
                        BY APPLICABLE LAW: ECHOVAULT PROVIDES THE USAGE OF THE PLATFORM, THE PROFFESIONAL SERVICES, ANDANY OTHER SERVICES  ON AN “AS IS” BASIS, WITHOUT WARRANTIES OF ANY KIND, 
                        WHETHER EXPRESS OR IMPLIED. ECHOVAULT AND OUR AFFILIATES, SUBCONTRACTORS, AGENTS, AND VENDORS (INCLUDING THIRD PARTY SERVICE PROVIDERS) HEREBY DISCLAIM THE WARRANTY OF 
                        MERCHANTABILITY, NON-INFRINGEMENT, FITNESS FOR PARTICULAR PURPOSE OR ACCURACY. NOTWITHSTANDING ANYTHING TO THE CONTRARY HEREIN, ECHOVAULT DOES NOT WARRANT THAT THE 
                        PLATFORM OR ANY SERVICES RELATED THERETO WILL BE DELIVERED OR PERFORMED ERROR-FREE OR WITHOUT INTERRUPTION OR THAT THE PLATFORM IS FREE FROM VIRUSES OR OTHER HARMFUL CODE. 
                        WE AND OUR VENDORS FURTHER DISCLAIM ANY AND ALL LIABILITY OR RESPONSIBILITY FOR ANY DELAYS, FAILURES, INTERCEPTION, ALTERATION, LOSS, OR OTHER DAMAGES THAT YOU AND/OR 
                        YOUR DATA (INCLUDING CONTENT) MAY SUFFER, THAT ARE BEYOND OUR CONTROL.
                    <br/>
                    <br/>
                        Echovault is not responsible for performance issues caused by low-performance of your computers, microphones or cameras and low-bandwidth internet connections. If Echovault 
                        identifies slow performance due to its system, Echovault shall use commercially reasonable efforts to restore the Platform to acceptable performance levels. Your sole remedy 
                        for any disruption or failure to provide support shall be termination of these Terms.
                    </p>

                    {/* section 7*/}
                    <h2 className="policy-subsection-heading">7. Limitation of Liability. </h2>
                    <p className="policy-subsection-information">
                        TO THE EXTENT PERMITTED BY LAW, IN NO EVENT WILL ECHOVAULT BE LIABLE FOR LOST PROFITS, LOSS OF USE, LOSS OF CONTENT, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR 
                        FOR SPECIAL, INCIDENTAL, INDIRECT, OR CONSEQUENTIAL DAMAGES, HOWEVER CAUSED, AND ON ANY THEORY OF LIABILITY, WHETHER FOR BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE AND 
                        STRICT LIABILITY), OR OTHERWISE, WHETHER OR NOT ECHOVAULT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ECHOVAULT'S MAXIMUM AGGREGATE LIABILITY UNDER, ARISING OUT OF 
                        OR RELATING TO THESE TERMS OR THE SERVICE SHALL NOT EXCEED THE FEES PAID BY YOU FOR SUCH SERVICE DURING THE 6 MONHTS PRECEDING THE EVENT GIVING RISE TO THE CLAIM.
                    </p>

                    {/* section 8*/}
                    <h2 className="policy-subsection-heading">8. Term Termination. </h2>
                    <p className="policy-subsection-information">
                        Terms will be continue in effect until account is not terminated. You may stop using the Platform at any time and delete your account and Echovault may suspend or terminate 
                        your access to the Platform immediately if you do not comply with these Terms. Upon termination, your account will be terminated, and you will no longer be able to access 
                        your account, data and it cannot be recovered.
                    </p>

                    {/* section 9*/}
                    <h2 className="policy-subsection-heading">9. Force Majeure. </h2>
                    <p className="policy-subsection-information">
                        Neither us nor you will be liable by reason of any failure or delay in the performance of its obligations on account of events beyond the reasonable control of a party, which 
                        may include denial-of-service attacks, interruption or failure of the Internet or any utility service, failures in third-party hosting services, strikes, shortages, riots, fires, 
                        acts of God, war, terrorism, pandemic, and governmental action.
                    </p>

                    {/* section 10*/}
                    <h2 className="policy-subsection-heading">10. Indemnification. </h2>
                    <p className="policy-subsection-information">
                        You agree to indemnify and hold harmless Echovault, its officers, directors, employees, suppliers, and affiliates, from and against any losses, damages, fines and expenses (including 
                        attorney's fees and costs) arising out of or relating to any claims that you have used the Services in violation of another party's rights, in violation of any law, in violations 
                        of any provisions of the Agreement , or any other claim related to your use of the Services, except where such use is authorized by Echovault.
                    </p>

                    {/* section 11*/}
                    <h2 className="policy-subsection-heading">11. Governing Law and Jurisdiction. </h2>
                    <p className="policy-subsection-information">
                        These Terms shall be governed by and construed and enforced in accordance with the laws of India. Any controversies, conflicts, disputes, or differences arising out of these Terms shall 
                        be resolved by arbitration in Ahmedabad in accordance with the Arbitration and Conciliation Act, 1996 for the time being in force, which is deemed to be incorporated by reference in this 
                        Clause. The tribunal shall consist of 1 (one) arbitrator appointed by us. The language of the arbitration shall be English. The parties to the arbitration shall keep the arbitration 
                        confidential and not disclose to any person, other than on a need to basis or to legal advisors, unless required to do so by law. The decision of the arbitrator shall be final and binding 
                        on all the Parties hereto. Subject to other provisions in this Clause, courts in Ahmedabad shall have exclusive jurisdiction over all issues arising out of these Terms or the use of the Services.
                    </p>

                    {/* section 12*/}
                    <h2 className="policy-subsection-heading">12. Miscellaneous. </h2>
                    <p className="policy-subsection-information">
                        <strong className="policy-subsection-subtopic">12.1 Entire Agreement.</strong> these Terms sets forth the entire understanding between the parties with respect to the subject matter herein and 
                        supersede all prior and contemporaneous written agreements and discussions concerning the subject matter hereof.
                    </p>
                    <p className="policy-subsection-information">
                        <strong className="policy-subsection-subtopic">12.2 Term Modifications. </strong> Echovault reserves the right, from time to time and in its discretion, to make changes to these 
                        Terms. The modified Terms will become effective within seven (7) days of being posted to the Echovault website. By continuing to access or use the Platform, you agree to be bound by the revised 
                        Terms. If you do not agree to the modifications, your sole remedy, is to terminate use of the Platform.
                    </p>
                    <p className="policy-subsection-information">
                        <strong className="policy-subsection-subtopic">12.3 Waiver. </strong> The failure of either party to enforce at any time a provision(s) of these Terms shall not be interpreted as a waiver of such 
                        provision(s) or of the right of such party to enforce each and every such provision(s).
                    </p>
                    <p className="policy-subsection-information">
                        <strong className="policy-subsection-subtopic">12.4 Publicity. </strong>Upon prior written approval from Customer, Echovault may include Customer's name and logo on its website or in other marketing 
                        materials or channels solely to reference Customer as Echovault customer, and subject to any trademark usage guidelines provided to Echovault. Customer may revoke its approval at any time.
                    </p>
                </div>
            </div>
            <FooterComponent/>
        </div>
    );
}

export default TermsOfServicesComponent;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { requestInfo, changeInfoRequest } from "../../redux/ActionCreator/Auth.ActionCreator";
import "./Profile.Style.css";
import LoadingComponent from "../Loading/LoadingGeneral.Component";
import ConfirmationComponent from "./Confirmation.Component";
import { continueSubscription, customerPortal } from "../../redux/ActionCreator/Subscription.ActionCreator";
import { Subscription_Feature_Switch } from "../../constants/FeatureSwitch";
import { FaBars } from "react-icons/fa";
import SeoComponent from "../seo/Seo.Component";

function ProfileComponent(props) {
    const navigate = useNavigate();
    
    const token = useSelector((state) => state.Auth.token);
    const Info = useSelector((state) => state.Auth.Info);
    const isLoading = useSelector((state) => state.Auth.isLoading);
    const canceled = useSelector((state) => state.Subscription.canceled);
    const nextPaymentDate = useSelector((state) => state.Subscription.nextPaymentDate);
    const isCancelling = useSelector((state) => state.Subscription.isCancelling);
    const customerPortalSession = useSelector((state) => state.Subscription.customerPortalSession);
    
    const dispatch = useDispatch();

    const [isEditing, setIsEditing] = useState(false);
    const [editedName, setEditedName] = useState(Info.name || "");
    const [editedUsername, setEditedUsername] = useState(Info.username || "");
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [confirmationButton, setConfirmationButton] = useState("");
    const [confirming, setConfirming] = useState("");
    const [confirmationPayload, setConfirmationPayload] = useState({});
    const [isConfirmationPopOpen, setIsConfirmationPopOpen] = useState(false);
    const [loadingMessage, setloadingMessage] = useState("Cancelling Membership");
    const [isMobile, setIsMobile] = useState(false);
    const [visibleOption, setVisibleOption] = useState("edit");

    const checkToken = () => {
        if (token === null) {
            return navigate("/auth");
        }
    };

    const formatteDate = () => {
        if(!nextPaymentDate)
        {
            return;
        }
        let startTime = new Date(nextPaymentDate);
        const updatedAt = new Date(startTime.getTime() - startTime.getTimezoneOffset() * 60000);

        const formatted = updatedAt.toLocaleString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric"
        });
        return formatted;
    };

    const handleEditClick = () => {
        setIsEditing(true);
        setEditedName(Info.name || "");
        setEditedUsername(Info.username || "");
    };

    const handleSaveClick = () => {
        setIsEditing(false);
        const payload = {
            name: editedName,
            username: editedUsername,
        };
        dispatch(changeInfoRequest(payload));
    };

    const handleCancelClick = () => {
        setIsEditing(false);
    };

    const handleConfirmationPopCallback = () => {
        setIsConfirmationPopOpen(false);
    }

    const handleCancelMenbershipClick = () => {
        if(!Subscription_Feature_Switch)
        return;
        setloadingMessage("Cancelling Membership");
        setIsConfirmationPopOpen(true);
        setTitle("Unsubscribe");
        setMessage("This action cannot be undone. subscription will immediatly termited.");
        setConfirmationButton("Unsubscribe");
        setConfirming("Unsubscribing");
        setConfirmationPayload({});
    }

    const handleSubscribeContinue = () => {
        if(!Subscription_Feature_Switch)
        return;
        setloadingMessage("Continue Membership");
        dispatch(continueSubscription({}));
    }

    const handleDeleteAccountClick = () => {
        setIsConfirmationPopOpen(true);
        setTitle("Confirm deletion");
    
        if(Subscription_Feature_Switch)
        setMessage("Are you sure you would like to delete this profile? This action cannot be undone. subscription will immediatly termited.");
        else
        setMessage("Are you sure you would like to delete this profile? This action cannot be undone.");

        setConfirmationButton("Delete");
        setConfirming("Deleting");
        setConfirmationPayload({});
    };

    const handleSubscribeClick = () => {
        if(!Subscription_Feature_Switch)
        return;
        navigate("/pricing");
    }

    const handleManageMemberShipClick = () => {
        setloadingMessage('Redirecting');
        dispatch(customerPortal({}));
    }

    useEffect(() => {
        checkToken();
    }, [token]);

    useEffect(() => {
        if(!isLoading && editedName !== "")
        {
            Info.name = editedName;
        }

        if(!isLoading && editedUsername !== "")
        {
            Info.username = editedUsername;
        }
    }, [isLoading]);

    useEffect(()=>{
        if(customerPortalSession)
        {
            window.open(customerPortalSession, "noreferrer");
        }
    },[customerPortalSession]);

    const toPascalCase = (str) => {
        if (!str) {
            return str;
        }
        return str.replace(/\w+/g, function (word) {
            return word.charAt(0).toUpperCase() + word.slice(1);
        });
    };

    const calculateFormat = (size) => {
        if (size < 1024) {
            return size.toFixed(0) + " KB";
        } else if (size < 1024 * 1024) {
            return (size / 1024).toFixed(0) + " MB";
        } else if (size < 1024 * 1024 * 1024) {
            return (size / (1024 * 1024)).toFixed(0) + " GB";
        } else {
            return (size / (1024 * 1024 * 1024)).toFixed(0) + " TB";
        }
    };

    useEffect(() => {
        if (!Info) {
            return;
        }
    }, [Info]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1000);
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (!Info.name || Info.name === "0") {
            dispatch(requestInfo());
        }

        if (!token) return;

        checkToken();
    }, []);

    return (
        <div className="ProfileComponentDiv">
            <SeoComponent
                title = 'Profile'
                description = 'Manage your account'
                name = 'Echovault'
                type = 'website' />
            {
                isConfirmationPopOpen &&
                <ConfirmationComponent 
                    title = {title} 
                    message = {message} 
                    confirmationButton = {confirmationButton} 
                    confirming = {confirming} 
                    callback = {handleConfirmationPopCallback}
                    confirmationPayload = {confirmationPayload}
                />
            }

            {isCancelling && <LoadingComponent loading = {loadingMessage} />}
            <div className="center-align-profile-info">
                {isMobile && (
                    <button className="sidebar-toggle-button back-to-main" onClick = {props.toggleSidebar}>
                        <FaBars />
                    </button>
                )}
                <div className="left-align-profile-info">
                    <div className="circle-info">
                        <div className="circle">{Info.name && <span>{Info.name && Info.name.charAt(0).toUpperCase()}</span>}</div>
                    </div>
                    <div className="total-records-size">
                        <div className="Info-display">
                            Records <span className="Info-details">{Info.totalrecords}</span>
                        </div>
                        <div className="Info-display">
                            Storage <span className="Info-details">{calculateFormat(Info.totalsize)}</span>
                        </div>
                    </div>
                </div>
                <div className="right-align-profile-info">
                    <div className="profile-Intro">
                        <div className="person-name">{toPascalCase(Info.name)}</div>
                        <div className="personal-settings">
                            <div className="personal-setting-title">Personal Settings</div>
                        </div>
                    </div>

                    {/* {<div className="profile-Intro-options">
                        <div className = "profile-section-select-option visible-edit" onClick = {() => {setVisibleOption("edit")}}>Edit Details</div>
                        <div className = "profile-section-select-option visible-info" onClick = {() => {setVisibleOption("info")}}>Contact Info</div>
                        {Subscription_Feature_Switch && <div className = "profile-section-select-option visible-subscription" onClick = {() => {setVisibleOption("subscription")}}>Subscription</div>}
                        <div className = "profile-section-select-option visible-terminate" onClick = {() => {setVisibleOption("terminate")}}>Terminate Account</div>
                    </div>} */}

                    <div className = "profile-horizontal-row"></div>

                    <div className="edit-personal-info">
                        <div className="edit-info-title">Edit Details</div>
                        <div className="profile-fields">
                            <div className="field-name">
                                <label className="info-titles">Name</label>
                                <input type="text" value={isEditing ? editedName : Info.name || " "} onChange={(e) => setEditedName(e.target.value)} disabled={!isEditing} />
                            </div>
                            <div className="field-name">
                                <label className="info-titles">Username</label>
                                <input type="text" value={isEditing ? editedUsername : Info.username || " "} onChange={(e) => setEditedUsername(e.target.value)} disabled={!isEditing} />
                            </div>
                        </div>
                        <div className="profile-edit-button">
                            {isEditing ? (
                                <>
                                    <button className="save-button" onClick={handleSaveClick}>
                                        Save
                                    </button>
                                    <button className="cancel-button" onClick={handleCancelClick}>
                                        Cancel
                                    </button>
                                </>
                            ) : (
                                <button className="edit-profile-button" onClick={handleEditClick}>
                                    Edit Profile
                                </button>
                            )}
                        </div>
                    </div>
                    <div className = "profile-horizontal-row"></div>
                    <div className="contact-Info">
                        <div className="contact-section-title">Contact Info</div>
                        <div className="profile-fields">
                            <label className="info-titles">Email</label>
                            <input type="text" value={Info.email || " "} disabled />
                        </div>
                    </div>

                    {
                        Subscription_Feature_Switch &&
                        <div className="Subscription">
                            <h3 className="contact-section-title">Subscription</h3>
                            {
                                canceled === process.env.REACT_APP_BASIC_SUBSCRIPTIONSTATUS_ACTIVE
                                ?
                                <>
                                    <h6 id="user-plane-title">Active: Plus Plan</h6>
                                    <p id="next-payment-date">Next payment: {formatteDate()}</p>
                                    <button className="cancel-subscription-button" onClick = {handleCancelMenbershipClick}>
                                        Cancel Membership
                                    </button>
                                    <button className="cancel-subscription-button customer-portal-button" onClick = {handleManageMemberShipClick}>
                                        Manage Membership
                                    </button>
                                </>
                                :
                                (
                                    canceled === process.env.REACT_APP_BASIC_SUBSCRIPTIONSTATUS_ENDING
                                    ?
                                    <div>
                                        Subscription ends on {formatteDate()}
                                        <button className="subscribe-button" onClick = {handleSubscribeContinue}>
                                            continue
                                        </button>
                                        <button className="cancel-subscription-button customer-portal-button" onClick = {handleManageMemberShipClick}>
                                            Manage Membership
                                        </button>
                                    </div>
                                    :
                                    <>
                                        <button className="subscribe-button" onClick = {handleSubscribeClick}>
                                            subscribe +
                                        </button>
                                        <button className="cancel-subscription-button customer-portal-button" onClick = {handleManageMemberShipClick}>
                                            Payment History
                                        </button>
                                    </>
                                )
                            }
                        </div>
                    }
                    <div className = "profile-horizontal-row"></div>
                    <div className="delete-profile">
                        <div className="delete-section-title">Delete My Account</div>
                        <div className="delete-msg">All the history{Subscription_Feature_Switch && <>, active subscription</>} and records will be permanently deleted.</div>
                        <button className="delete-button" onClick = {handleDeleteAccountClick}>
                            Delete Account
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProfileComponent;

import * as ActionTypes from "../../constants/ActionTypes";

export const requestLogin = (creds) => {
    return {
        type: ActionTypes.LOGIN_REQUEST,
        creds,
    };
};

export const loginSuccess = (response) => {
    return {
        type: ActionTypes.LOGIN_SUCCESS,
        token: response.token,
    };
};

export const loginError = (message) => {
    return {
        type: ActionTypes.LOGIN_FAILURE,
        message,
    };
};

export const requestGoogleLogin = (creds) => {
    return {
        type: ActionTypes.GOOGLE_LOGIN_REQUEST,
        creds,
    };
};

export const googleLoginSuccess = (response) => {
    return {
        type: ActionTypes.GOOGLE_LOGIN_SUCCESS,
        token: response.token,
    };
};

export const googleLoginError = (message) => {
    return {
        type: ActionTypes.GOOGLE_LOGIN_FAILURE,
        message,
    };
};

export const requestInfo = () => {
    return {
        type: ActionTypes.INFO_REQUEST,
    };
};

export const infoSuccess = (Info) => {
    return {
        type: ActionTypes.INFO_SUCCESS,
        Info,
    };
};

export const infoError = (message) => {
    return {
        type: ActionTypes.INFO_FAILURE,
        message,
    };
};

export const requestSignup = (creds) => {
    return {
        type: ActionTypes.SIGNUP_REQUEST,
        creds,
    };
};

export const signupSuccess = (response) => {
    return {
        type: ActionTypes.SIGNUP_SUCCESS,
        token: response.token,
    };
};

export const signupError = (message) => {
    return {
        type: ActionTypes.SIGNUP_FAILURE,
        message,
    };
};

export const requestLogout = () => {
    return {
        type: ActionTypes.LOGOUT_REQUEST,
    };
};

export const logoutSuccess = () => {
    return {
        type: ActionTypes.LOGOUT_SUCCESS,
    };
};

export const logoutError = (message) => {
    return {
        type: ActionTypes.LOGOUT_FAILURE,
    };
};

export const changeInfoRequest = (data) => ({
    type: ActionTypes.CHANGE_INFO_REQUEST,
    data,
});

export const changeInfoSuccess = (info) => ({
    type: ActionTypes.CHANGE_INFO_SUCCESS,
    info,
});

export const changeInfoFailed = (message) => ({
    type: ActionTypes.CHANGE_INFO_FAILURE,
    message,
});

export const deleteProfile = (payload) => ({
    type: ActionTypes.DELETE_PROFILE_REQUEST,
    payload,
});

export const deleteProfileSuccess = (message) => ({
    type: ActionTypes.DELETE_PROFILE_SUCCESS,
    message,
});

export const deleteProfileFailure = (message) => ({
    type: ActionTypes.DELETE_PROFILE_FAILURE,
    message,
});

export const getOtpRequest = (data) => ({
    type: ActionTypes.OTP_REQUEST,
    data,
});

export const getOtpSuccess = (info) => ({
    type: ActionTypes.OTP_SUCCESS,
    info,
});

export const getOtpFailed = (message) => ({
    type: ActionTypes.OTP_FAILURE,
    message,
});

export const otpVerifyRequest = (data) => ({
    type: ActionTypes.VERIFIED_REQUEST,
    data,
});

export const otpVerifySuccess = (info) => ({
    type: ActionTypes.VERIFIED_SUCCESS,
    info,
});

export const otpFailed = (message) => ({
    type: ActionTypes.VERIFIED_FAILURE,
    message,
});

export const changePasswordRequest = (data) => ({
    type: ActionTypes.CHANGE_PASSWORD_REQUEST,
    data,
});

export const changePasswordSuccess = (info) => ({
    type: ActionTypes.CHANGE_PASSWORD_SUCCESS,
    info,
});

export const changePasswordFailed = (message) => ({
    type: ActionTypes.CHANGE_INFO_FAILURE,
    message,
});

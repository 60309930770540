import * as ActionTypes from "../../constants/ActionTypes";
import { InitialState } from "../../constants/InitialStates";

export const Share = (state = InitialState.Share, action) => {
    switch (action.type) {
        case ActionTypes.SHARE_PERMISSION_DETAIL_LOADING: {
            return { ...state, isLoading: true, errMess: null };
        }
        case ActionTypes.SHARE_PERMISSION_DETAIL_SUCCESS: {
            return { ...state, isLoading: false, errMess: null, record: action.payload };
        }
        case ActionTypes.SHARE_PERMISSION_DETAIL_FAILURE: {
            return { ...state, isLoading: false, errMess: action.payload };
        }
        case ActionTypes.SHARE_PEOPLE_LIST_LOADING: {
            return { ...state };
        }
        case ActionTypes.SHARE_PEOPLE_LIST_SUCCESS: {
            return { ...state, list: action.payload };
        }
        case ActionTypes.SHARE_PEOPLE_LIST_FAILURE: {
            return { ...state, errMess: action.payload };
        }
        case ActionTypes.SHARE_PERMISSION_LOADING: {
            return { ...state, isSharing: true };
        }
        case ActionTypes.SHARE_PERMISSION_SUCCESS: {
            return { ...state, isSharing: false, list: [...state.list, action.payload] };
        }
        case ActionTypes.SHARE_PERMISSION_FAILURE: {
            return { ...state, isSharing: false };
        }
        case ActionTypes.REMOVE_PERMISSION_LOADING: {
            return { ...state, isUnSharing: true };
        }
        case ActionTypes.REMOVE_PERMISSION_SUCCESS: {
            let newList = state.list.filter((user) => !(user.email === action.payload.user));
            return { ...state, isUnSharing: false, list: [...newList] };
        }
        case ActionTypes.REMOVE_PERMISSION_FAILURE: {
            return { ...state, isUnSharing: false };
        }
        case ActionTypes.LOGOUT_SUCCESS:
            return { isLoading: true, isSharing: false, isUnSharing: false, errMess: null, record: null, list: [] };
        case ActionTypes.DELETE_PROFILE_SUCCESS:
            return { isLoading: true, isSharing: false, isUnSharing: false, errMess: null, record: null, list: [] };
        default:
            return state;
    }
};

import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import BaseRecordComponent from "../MainPage/BaseRecord/BaseRecord.Component";
import { searchLoading } from "../../redux/ActionCreator/Records.ActionCreator";

function SearchComponent(props) {
    const { recordName } = useParams();

    useEffect(() => {
        // need this to render on recordName in parm change.
    }, [recordName]);

    return <BaseRecordComponent folderName={"Search"} displayName={"Favourite"} recordName={recordName} moveTo={"favourite"} method={searchLoading} />;
}

export default SearchComponent;

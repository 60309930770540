import { call, put, takeEvery } from "redux-saga/effects";
import * as ActionTypes from "../../constants/ActionTypes";
import { get, putApi, postFormData, deleteApi } from "../../api/AxiosConfig";
import { GET_RECORDS_PATH, 
    RENAME_RECORD_PATH, 
    CREATE_RECORD_PATH, 
    DELETE_RECORDS_PATH, 
    CHANGE_FOLDER_PATH, 
    GET_SEARCH_PATH 
} from "../../constants/ApiPaths";

const msg = "Your session timed out";

function* getRecords(action) {
    try {
        const response = yield call(get, GET_RECORDS_PATH + action.data.id);
        const data = response.data;
        if (response.status === 200) {
            const records = data.Info;
            const name = action.data.folderName;
            yield put({ type: ActionTypes.RECORDS_SUCCESS, payload: { foldername: name, records: records } });
        } else if (response.status === 401) {
            yield put({ type: ActionTypes.LOGOUT_SUCCESS });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: msg } });
        } else {
            yield put({ type: ActionTypes.RECORDS_FAILURE, data });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: data.message } });
        }
    } catch (error) {
        yield put({ type: ActionTypes.RECORDS_FAILURE, payload: { error } });
        yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: "Unable to fetch records" } });
    }
}

export function* recordsRequest() {
    yield takeEvery(ActionTypes.RECORDS_LOADING, getRecords);
}

function* getSearch(action) {
    try {
        const response = yield call(get, GET_SEARCH_PATH + action.data.recordName);
        const data = response.data;
        if (response.status === 200) {
            const records = data.Info;
            const name = action.data.folderName;
            yield put({ type: ActionTypes.SEARCH_SUCCESS, payload: { foldername: name, records: records } });
        } else if (response.status === 401) {
            yield put({ type: ActionTypes.LOGOUT_SUCCESS });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: msg } });
        } else {
            yield put({ type: ActionTypes.SEARCH_FAILURE, data });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: data.message } });
        }
    } catch (error) {
        yield put({ type: ActionTypes.SEARCH_FAILURE, payload: { error } });
        yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: "Can't perform search" } });
    }
}

export function* searchRequest() {
    yield takeEvery(ActionTypes.SEARCH_LOADING, getSearch);
}

function* renameRecord(action) {
    try {
        const response = yield call(putApi, RENAME_RECORD_PATH, null, action.payload);
        const data = response.data;
        if (response.status === 200) {
            const payload = action.payload;
            yield put({ type: ActionTypes.RENAME_RECORD_SUCCESS, payload });
            yield put({ type: ActionTypes.TOAST_SUCCESS, payload: { type: "Success", message: data.status } });
        } else if (response.status === 401) {
            yield put({ type: ActionTypes.LOGOUT_SUCCESS });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: msg } });
        } else {
            yield put({ type: ActionTypes.RENAME_RECORD_FAILURE, data });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: data.status } });
        }
    } catch (error) {
        yield put({ type: ActionTypes.RENAME_RECORD_FAILURE, payload: { error } });
        yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: "Rename unsuccessfull" } });
    }
}

export function* renameRecordRequest() {
    yield takeEvery(ActionTypes.RENAME_RECORD, renameRecord);
}

function* createRecord(action) {
    try {
        const response = yield call(postFormData, CREATE_RECORD_PATH, null, action.payload.formData);
        const data = response.data;
        if (response.status === 200) {
            const payload = data.Info;
            yield put({ type: ActionTypes.CREATE_RECORD_SUCCESS, payload });
            yield put({ type: ActionTypes.TOAST_SUCCESS, payload: { type: "Success", message: data.status } });
            return { success: true };
        } else if (response.status === 401) {
            yield put({ type: ActionTypes.LOGOUT_SUCCESS });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: msg } });
        } else {
            yield put({ type: ActionTypes.CREATE_RECORD_FAILURE, data });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: data.status } });
            return { success: false };
        }
    } catch (error) {
        yield put({ type: ActionTypes.CREATE_RECORD_FAILURE, payload: { error } });
        yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: "Can't create new record" } });
        return { success: false };
    }
}

export function* createRecordRequest() {
    yield takeEvery(ActionTypes.CREATE_RECORD, createRecord);
}

function* deleteRecord(action) {
    try {
        const response = yield call(deleteApi, DELETE_RECORDS_PATH + action.payload.recordId, null);
        const data = response.data;
        if (response.status === 200) {
            const payload = data.Info;
            yield put({ type: ActionTypes.DELETE_RECORD_SUCCESS, payload });
            yield put({ type: ActionTypes.TOAST_SUCCESS, payload: { type: "Success", message: data.status } });
        } else if (response.status === 401) {
            yield put({ type: ActionTypes.LOGOUT_SUCCESS });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: msg } });
        } else {
            yield put({ type: ActionTypes.DELETE_RECORD_FAILURE, data });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: data.status } });
        }
    } catch (error) {
        yield put({ type: ActionTypes.DELETE_RECORD_FAILURE, payload: { error } });
        yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: "Unable to delete record" } });
        return { success: false };
    }
}

export function* deleteRecordRequest() {
    yield takeEvery(ActionTypes.DELETE_RECORD, deleteRecord);
}

function* changeRecordFolder(action) {
    try {
        const response = yield call(putApi, CHANGE_FOLDER_PATH, null, action.payload);
        const data = response.data;
        if (response.status === 200) {
            const payload = data.Info;
            yield put({ type: ActionTypes.CHANGE_RECORD_FOLDER_SUCCESS, payload });
            yield put({ type: ActionTypes.TOAST_SUCCESS, payload: { type: "Success", message: data.status } });
        } else if (response.status === 401) {
            yield put({ type: ActionTypes.LOGOUT_SUCCESS });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: msg } });
        } else {
            yield put({ type: ActionTypes.CHANGE_RECORD_FOLDER_FAILURE, data });
            yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: data.status } });
        }
    } catch (error) {
        yield put({ type: ActionTypes.CHANGE_RECORD_FOLDER_FAILURE, payload: { error } });
        yield put({ type: ActionTypes.TOAST_ERROR, payload: { type: "Error", message: "Unable to change the folder" } });
    }
}

export function* changeRecordFolderRequest() {
    yield takeEvery(ActionTypes.CHANGE_RECORD_FOLDER, changeRecordFolder);
}
